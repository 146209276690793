%fieldSetBase {
  position: relative;

  &:after {
    content: '';
    pointer-events: none;
    border: 1px solid var(--borderColor);
    position: absolute;
    z-index: 0;
    top: 11px;
    left: 0;
    bottom: 0;
    right: 0;
  }

  > legend {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    border: 0;
    width: auto;
    z-index: 1;
    background: var(--Fieldset-legend-bgColor);
    border-left: 0 !important;
  }

  .collapse {
    position: relative;
  }
}

fieldset.#{$ns}Collapse {
  margin-bottom: var(--Form-item-gap);
  > legend {
    font-weight: var(--fontWeightNormal);
    padding: var(--gap-xs) 0;
    font-size: var(--fontSizeMd);
    color: var(--text--loud-color);
    border-left: var(--primary) px2rem(4px) solid;
    line-height: 1.2;
    margin: var(--gap-base) 0;
    padding: 0 0 0 14px;
    cursor: pointer;
    border-bottom: none !important;
    background: transparent;
    display: flex;
    flex-direction: row;
    // width: 100%;
    &:hover {
      background: transparent;
    }
  }

  &--xs {
    @extend %fieldSetBase;
    padding: 20px 5px 5px 5px;

    > legend {
      left: 5px;
      font-size: var(--fontSizeXs);
      padding: 0 3px;
      margin: 0 0 0 -3px;
    }

    &:after {
      top: 6px;
    }
  }

  &--sm {
    @extend %fieldSetBase;

    padding: 25px 10px 10px 10px;

    > legend {
      left: 10px;
      font-size: var(--fontSizeSm);
      padding: 0 5px;
      margin: 0 0 0 -5px;
    }

    &:after {
      top: 6px;
    }
  }

  &--base {
    @extend %fieldSetBase;

    padding: 30px var(--gap-md) var(--gap-md) var(--gap-md);

    > legend {
      left: var(--gap-md);
      font-size: var(--fontSizeBase);
      padding: 0 8px;
      margin: 0 0 0 -8px;
    }

    &:after {
      top: 7px;
    }
  }

  &--md {
    @extend %fieldSetBase;
    padding: 30px 20px 20px 20px;

    > legend {
      left: 20px;
      font-size: var(--fontSizeMd);
      padding: 0 10px;
      margin: 0 0 0 -10px;
    }

    &:after {
      top: 7px;
    }
  }

  &--lg {
    @extend %fieldSetBase;
    padding: 40px 30px 30px 30px;

    > legend {
      left: 30px;
      font-size: var(--fontSizeLg);
      padding: 0 var(--gap-md);
      margin: 0 0 0 calc(var(--gap-md) * -1);
    }

    &:after {
      top: 9px;
    }
  }
}
