.#{$ns}Wizard {
  @include clearfix();

  position: relative;

  &,
  &-tabs {
    padding: 0;

    .#{$ns}Badge {
      display: inline-block;
      width: var(--Wizard-badge-size);
      height: var(--Wizard-badge-size);
      font-size: var(--Wizard-badge-fontSize);
      line-height: var(--Wizard-badge-size);
      color: var(--Wizard-badge-color);
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      background: var(--Wizard-badge-bg);
      border-radius: var(--Wizard-badge-borderRadius); // font-weight: 700;
      text-shadow: 0 px2rem(1px) 0 rgba(0, 0, 0, 0.2);
      margin-right: var(--Wizard-badge-marginRight);
      border: var(--Wizard-badge-border);
      box-sizing: content-box;

      // &--primary {
      //     background: var(--primary);
      // }

      // &--secondary {
      //     background: var(--secondary);
      // }

      // &--success {
      //     background: var(--success);
      // }

      // &--info {
      //     background: var(--info);
      // }

      // &--warning {
      //     background: var(--warning);
      // }

      // &--danger {
      //     background: var(--danger);
      // }

      // &--light {
      //     background: var(--light);
      // }

      // &--dark {
      //     background: var(--dark);
      // }
      &.is-active {
        color: var(--white);
        background: var(--Wizard-badge-onActive-backgroundColor);
      }
      &.is-complete {
        color: var(--white);
        background: var(--Wizard-badge-onComplete-backgroundColor);
      }
    }

    ul li.active {
      color: var(--info);
    }

    .#{$ns}Panel-footer > .#{$ns}Form-group,
    .#{$ns}Panel-footer > .btn {
      margin-left: var(--gap-xs);
    }

    > ul.nav {
      padding: 0;
      margin: 0;
      border: var(--borderWidth) solid var(--borderColor);

      li {
        position: relative;
        float: left;
        padding: 0 var(--gap-md) 0 px2rem(25px);
        margin: 0;
        color: #999999;
        cursor: pointer;
        height: var(--Wizard-steps-height);
        line-height: var(--Wizard-steps-height);

        a {
          border: 0 none !important;
          background: transparent !important;
          color: inherit;
          display: inline;
          padding: 0;

          div {
            display: inline;
          }
        }

        &:first-child {
          padding-left: var(--gap-md);
          border-radius: px2rem(4px) 0 0 0;
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          right: px2rem(-10px);
          border: var(--gap-md) solid transparent;
          border-right: 0;
          border-left: px2rem(10px) solid var(--borderColor);
          border-left-color: rgba(0, 0, 0, 0.05);
          z-index: 1;
          bottom: 0;
        }

        &:after {
          right: px2rem(-9px);
          border-left-color: var(--Wizard-steps-bg);
          z-index: 2;
        }

        &.is-active {
          color: var(--Wizard-steps-li-onActive-color);
          background: #fff;
        }

        &.is-active:after {
          border-left-color: #fff;
        }
      }

      // .Badge {
      //     margin-right: px2rem(4px);
      // }
    }
  }

  &-steps {
    font-size: var(--fontSizeBase);
    padding: var(--Wizard-steps-padding);
    background: var(--Wizard-steps-bg);
    border-bottom: var(--Wizard-steps-borderWidth) solid var(--borderColor);
    text-align: var(--Wizard-steps-textAlign);
    @include clearfix();

    ul {
      display: var(--Wizard-steps-ulDisplay);
      padding: 0;
      margin: 0;
      list-style: none outside none;

      li {
        position: relative;
        float: left;
        padding: 0 var(--gap-md) 0 px2rem(25px);
        margin: 0;
        color: #999999;
        cursor: default;
        height: var(--Wizard-steps-height);
        line-height: var(--Wizard-steps-height);

        &:first-child {
          padding-left: var(--gap-md);
          border-radius: px2rem(4px) 0 0 0;
        }

        &:before,
        &:after {
          font-family: var(--Wizard-steps-liVender);
          content: var(--Wizard-steps-liAfterContent);
          position: absolute;
          bottom: 0;
          right: px2rem(-10px);
          border: $Wizard-steps-liAfterBorder;
          border-right: 0;
          border-left: px2rem(10px) solid var(--borderColor);
          border-left-color: rgba(0, 0, 0, 0.05);
          z-index: 2;
        }

        &:after {
          right: px2rem(-9px);
          border-left-color: var(--Wizard-steps-bg);
          z-index: 2;
        }

        &.is-active {
          color: var(--Wizard-steps-li-onActive-color);
          background: var(--Wizard-steps-li-onActive-bg);
        }

        &.is-active:after {
          border-left-color: var(--Wizard-steps-li-onActive-arrow-bg);
        }

        &.is-complete,
        &.is-complete:hover {
          color: var(--Wizard-steps-li-onActive-color);
          cursor: pointer;
        }
      }

      // .Badge {
      //     margin-right: px2rem(4px);
      // }
    }
  }

  &-stepContent {
    padding: var(--Wizard-stepsContent-padding);

    & .Step-pane {
      display: none;

      &.is-active {
        display: inherit;
      }
    }
  }

  &--vertical {
    > .#{$ns}Wizard-step {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-bottom: none;

      > .#{$ns}Wizard-steps {
        height: auto;
        min-width: 40px;
        margin-right: 30px;
        margin-bottom: px2rem(30px); // padding: 0;
        background: none;
        border-bottom: none;

        li {
          background: var(--Wizard-steps-bg);
        }

        ul li {
          height: px2rem(40px);
          line-height: px2rem(40px);
          position: relative;
          float: none;
          border-bottom: var(--borderWidth) solid var(--borderColor);
          padding-left: var(--gap-md);

          &.is-active {
            background: #fff;
          }
        }
      }

      > .#{$ns}Wizard-stepContent {
        flex-grow: 1;
        width: 0;
      }

      > .#{$ns}Wizard-footer {
        width: 100%;
      }
    }
  }
}
