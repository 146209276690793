.#{$ns}Page {
  width: 100%;
  height: 100%;
  background: var(--Page-main-bg);

  &-header {
    padding: var(--Page-header-paddingY) var(--Page-header-paddingX);
  }

  &-main {
    // background: var(--Page-main-bg);
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &-content {
    padding: var(--Page-content-paddingY) var(--Page-content-paddingX);
    position: relative;
    height: 100%;
    width: 100%;
  }

  &-main > &-header {
    border-bottom: var(--borderWidth) solid var(--borderColor);
    // background: var(--Page-header-bg);
  }

  &-headerRow {
    // background: var(--Page-header-bg);
    border-bottom: var(--borderWidth) solid var(--borderColor);

    display: flex;
    flex-direction: row;
    align-items: center;

    .#{$ns}Page-header,
    .#{$ns}Page-toolbar {
      flex-grow: 1;
      min-height: 100%;
    }

    .#{$ns}Page-toolbar {
      text-align: right;
      padding-right: var(--gap-base);
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  &-title {
    margin: 0;
    padding: 0;
    color: var(--Page-title-color);
    line-height: var(--Page-title-lineHeight);
    font-size: var(--Page-title-fontSize);
    font-weight: var(--Page-title-fontWeight);
  }

  &-body {
    padding: var(--Page-body-padding);
    flex: 1 auto;
  }

  &-asideTplWrapper {
    padding: var(--gap-xs);
  }

  &-asideResizor {
    position: absolute;
    right: -0.375rem;
    top: 50%;
    cursor: ew-resize;
    writing-mode: vertical-lr;
    width: 0.75rem;
    height: 1.5rem;
    margin-top: -0.75rem;
    border: 0.0625rem solid #dee2e6;
    background-color: #fff;
    border-radius: 0.142rem;
    font-size: 12px;
    line-height: 0.625rem;
    text-align: center;
    user-select: none;
    color: #666;

    &:hover {
      color: #000;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px 0 rgba(0, 0, 0, 0.08);
    }

    &:after {
      content: '···';
    }
  }
}

.#{$ns}Page-toolbar {
  > * + * {
    margin-left: var(--gap-xs);
  }
}

.#{$ns}Page-aside {
  // background: var(--Page-aside-bg);
}

@include media-breakpoint-up(md) {
  .#{$ns}Page-aside {
    &::before {
      content: '';
      position: absolute;
      width: inherit;
      top: 0;
      bottom: 0;
      z-index: -1;
      background: inherit;
      border: inherit;
    }

    width: var(--Page-aside-width);
    border-right: var(--borderWidth) solid var(--borderColor);

    &--withWidth {
      min-width: var(--Page-aside-width);
      max-width: var(--Page-aside-maxWidth);
    }
  }

  .#{$ns}Page--withSidebar {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    > .#{$ns}Page-content {
      width: 0;
      flex-grow: 1;
    }
  }
}
