.#{$ns}Selections {
  height: 100%;
  min-width: px2rem(200px);
  position: relative;
  display: flex;
  flex-direction: column;

  &-title {
    height: var(--Form-input-height);
    background: var(--Table-thead-bg);
    font-size: var(--Form-input-fontSize);
    padding: calc(
        (
            var(--Form-input-height) - var(--Form-input-lineHeight) *
              var(--Form-input-fontSize)
          ) / 2
      )
      var(--gap-sm);
  }

  &-placeholder {
    color: var(--Form-input-placeholderColor);
    text-align: center;
    width: 100%;
    height: 100%;
    flex-basis: var(--Form-input-height);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: var(--Form-input-fontSize);
  }

  &-items {
    flex-grow: 1;
  }

  &-item {
    display: flex;
    flex-direction: row;
    height: var(--Form-input-height);
    line-height: var(--Form-input-lineHeight);
    font-size: var(--Form-input-fontSize);
    align-items: center;
    padding: calc(
        (
            var(--Form-input-height) - var(--Form-input-lineHeight) *
              var(--Form-input-fontSize)
          ) / 2
      )
      var(--gap-sm);

    > label {
      flex-basis: px2rem(50px);
      flex-grow: 1;
    }

    > .#{$ns}Selections-dragbar {
      top: 0;
      width: var(--gap-lg);
      position: relative;
      left: calc(var(--gap-xs) * -1);
      color: var(--icon-color);
      cursor: move;
    }

    &--dragging {
      > * {
        opacity: 0.2;
      }
    }
  }

  &-delBtn {
    color: var(--icon-color);
    cursor: pointer;

    &:hover {
      color: var(--icon-onHover-color);
    }

    > svg {
      width: 12px;
      height: 12px;
    }
  }
}
