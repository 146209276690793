.fr-popup {
  z-index: $zindex-top !important;
}

.#{$ns}RichTextControl {
  > .tox-tinymce {
    border: 0;
  }

  > textarea {
    width: 100%;
  }

  min-height: px2rem(200px);
  height: auto;
  border: var(--Form-input-borderWidth) solid var(--Form-input-borderColor);
  width: 100%;

  @include input-border();

  .fr-toolbar.fr-top {
    box-shadow: none;
    border: 0;
    position: relative;
  }

  .fr-box.fr-basic.fr-top:not(.fr-fullscreen) .fr-wrapper {
    min-height: 150px;
    max-height: 400px;
    box-shadow: none;
    overflow: auto;
    border: none;
  }

  .second-toolbar {
    border: none;
  }

  .fr-toolbar .fr-command.fr-btn,
  .fr-popup .fr-command.fr-btn {
    color: var(--Button--default-color);
  }

  .fr-toolbar .fr-command.fr-btn.fr-active,
  .fr-popup .fr-command.fr-btn.fr-active {
    color: var(--info);
    background: var(--Form-select-onHover-bg);
  }

  .fr-desktop .fr-command:hover,
  .fr-desktop .fr-command:focus {
    background: var(--Form-select-onHover-bg);
  }

  .fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
  .fr-popup .fr-command.fr-btn.fr-dropdown.fr-active {
    background: var(--Form-select-onHover-bg);
  }

  .fr-command.fr-btn
    + .fr-dropdown-menu
    .fr-dropdown-wrapper
    .fr-dropdown-content
    ul.fr-dropdown-list
    li
    a.fr-active {
    background: var(--info);
    color: #fff;
  }

  &.is-focused {
    border: var(--Form-input-borderWidth) solid
      var(--Form-input-onFocused-borderColor);
  }

  .fr-box.fr-basic .fr-element {
    min-height: 150px;
  }

  .fr-sticky-dummy {
    position: absolute;
  }

  &.is-disabled {
    border-color: var(--Form-input-onDisabled-borderColor);
    pointer-events: none;
    opacity: 0.6;

    .fr-box.fr-basic.fr-top .fr-wrapper {
      border-color: var(--Form-input-onDisabled-borderColor);
    }
  }

  //   &.amis-rich-text-control-md {
  //     .fr-box.fr-basic .fr-element,
  //     .fr-box.fr-basic.fr-top .fr-wrapper {
  //       min-height: 250px;
  //     }
  //   }

  //   &.amis-rich-text-control-lg {
  //     .fr-box.fr-basic .fr-element,
  //     .fr-box.fr-basic.fr-top .fr-wrapper {
  //       min-height: 300px;
  //     }
  //   }
}

// @media (min-width: 768px) {

//   .amis-rich-text-control.form-contorl-inline,
//   .form-group-inline .amis-rich-text-control {
//     display: inline-block;
//     width: 280px;
//   }
// }
