.#{$ns}AsideNav {
  @include clearfix();

  &-label {
    color: var(--Layout-asideLabel-color);
    margin-top: var(--gap-base);
    margin-bottom: var(--gap-sm);
    font-size: var(--fontSizeXs);
    padding-left: var(--gap-base);
    padding-right: var(--gap-base);
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &-itemArrow {
    float: right;
    display: block;
    width: var(--gap-md);
    text-align: center;
    line-height: px2rem(17px);
    margin-top: px2rem(2px);

    &:empty:before {
      content: '';
      display: inline-block;
      transform-origin: center;
      transition: transform var(--animation-duration) ease;
      background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB2aWV3Qm94PSIwIDAgNTEzIDEwMjEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+Cgk8ZyBpZD0icmlnaHQtYXJyb3ciIGZpbGw9IiNmZmZmZmYiIGZpbGwtcnVsZT0ibm9uemVybyI+CgkJPHBhdGggZD0iTTU2LjU1OTA1NCwxMDEzLjc3MzY5IEw1MTIuOTA4MTE2LDUxMi42ODQ1MjQgTDU2LjU1OTA1NCwxMi4yMzQ1MDEgQzQ5LjQxMTQ2NzgsMi45MzQ1NTkxMiAzNy42NjY0MjM4LC0xLjU5MTg4MTc2IDI2LjEyNjIzMjQsMC41MDU5NDgyNDYgQzE0LjU4NjA0MSwyLjYwMzc3ODI1IDUuMTg1NDQ0MDksMTAuOTc0MTcyNyAxLjc2ODE1NTE2LDIyLjE5NDY0NzEgQy0xLjY0OTEzMzc3LDMzLjQxNTEyMTQgMS40ODk4MDIyOCw0NS42MDQ1MzUxIDkuOTAxNTE2LDUzLjc3ODg4NCBMNDI0LjcwNjE5Nyw1MTIuNjg0NTI0IEwxMi40NTgwOTQsOTY5LjY3MjczMSBDMi40NTgyMDU5Niw5ODIuNTUxNDk4IDQuMDEyOTc3MzcsMTAwMC45NDgzIDE2LjAzMjQ0MjIsMTAxMS45NjYxNSBDMjguMDUxOTA3MSwxMDIyLjk4Mzk5IDQ2LjUxNDIzNDYsMTAyMi45MzYxOSA1OC40NzY0ODcsMTAxMS44NTYyNiBMNTYuNTU5MDU0LDEwMTMuNzczNjkgWiIgaWQ9Iui3r+W+hCI+CgkJPC9wYXRoPgoJPC9nPgo8L3N2Zz4K);
      width: px2rem(10px);
      height: px2rem(10px);
      background-position: center center;
      background-repeat: no-repeat;
    }

    .#{$ns}AsideNav-item.is-open > a > &:empty:before {
      transform: rotate(90deg);
      color: var(--Layout-asideLink-onActive-arrowColor);
    }

    > svg {
      color: var(--Layout-asideLink-color);
      width: px2rem(10px);
      height: px2rem(10px);
      transform-origin: center;
      transition: transform var(--animation-duration) ease;
    }

    .#{$ns}AsideNav-item.is-open > a > & > svg {
      transform: rotate(90deg);
    }
  }

  &-itemBadge {
    padding: px2rem(2px) var(--gap-xs);
    margin-top: px2rem(2px);
    font-size: var(--fontSizeXs);
    text-shadow: 0 px2rem(1px) 0 rgba(0, 0, 0, 0.2);
    float: right;
    min-width: px2rem(1px);
    line-height: 1;
    font-weight: var(--fontWeightBold);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: px2rem(10px);
  }

  &-itemIcon {
    // margin: ($Layout-nav-height - $lineHeightBase * $Layout-asideLink-fontSize)/-2
    //   px2rem(-10px);
    margin: calc(
        (
            var(--Layout-nav-height) - var(--lineHeightBase) *
              var(--Layout-asideLink-fontSize)
          ) / -2
      )
      px2rem(-10px);
    line-height: var(--Layout-nav-height);
    width: var(--Layout-nav-height);
    vertical-align: middle;
    float: left;
    margin-right: 0;
    text-align: center;
    position: relative;
    overflow: hidden;
    color: var(--Layout-asideLink-iconColor);
    font-size: var(--Layout-asideLink-onHover-iconSize);

    &:before {
      position: relative;
      z-index: 2;
    }
  }

  &-subList {
    margin: 0;
    padding: 0;
    opacity: 0;
    height: 0;
    overflow: hidden;
    margin-left: calc(var(--gap-md) * -1);
    transition: all var(--animation-duration) ease-in-out 0s;
    background: var(--Layout-aside-subList-bg);

    .#{$ns}Layout--folded .#{$ns}AsideNav-item > & {
      display: block;
      overflow: hidden;
    }

    .is-open > &,
    .#{$ns}Layout--folded .#{$ns}AsideNav-item .#{$ns}AsideNav-item.is-open > &,
    .#{$ns}Layout--folded .#{$ns}AsideNav-item:hover > &,
    .#{$ns}Layout--folded .#{$ns}AsideNav-item:focus > &,
    .#{$ns}Layout--folded .#{$ns}AsideNav-item:active > & {
      opacity: 1;
      margin-left: 0;
      height: auto !important;
      overflow: visible;
    }
  }

  &-item {
    position: relative;
    display: block;

    a {
      font-weight: normal;
      cursor: pointer;
      text-transform: none;
      display: block;
      font-size: var(--Layout-asideLink-fontSize);

      padding: calc(
          (
              var(--Layout-nav-height) - var(--lineHeightBase) *
                var(--Layout-asideLink-fontSize)
            ) / 2
        )
        var(--gap-base);
      position: relative;
      transition: background-color var(--animation-duration) ease-in-out 0s;
      color: var(--Layout-asideLink-color);
      text-decoration: none;
      user-select: none;

      &:hover {
        color: var(--Layout-asideLink-onHover-color);
        text-decoration: none;
        background: var(--Layout-aside-onHover-bg);

        .#{$ns}AsideNav-itemIcon {
          color: var(--Layout-asideLink-onHover-iconColor);
        }
      }
    }

    &.is-active {
      > a {
        background: var(--Layout-aside-onAcitve-bg);
        color: var(--Layout-asideLink-onActive-color);

        &:hover {
          background: var(--Layout-aside-onAcitve-onHover-bg);
        }
      }
    }

    &.is-lg {
      > a {
        padding: calc(
            (
                var(--Layout-nav-lgHeight) - var(--lineHeightBase) *
                  var(--Layout-asideLink-fontSize)
              ) / 2
          )
          var(--gap-md);
      }
    }

    .#{$ns}AsideNav-item {
      a {
        padding-left: calc(var(--Layout-nav-height) + #{px2rem(5px)});
      }

      .#{$ns}AsideNav-item a {
        padding-left: calc(var(--Layout-nav-height) + #{px2rem(15px)});
      }

      .#{$ns}AsideNav-item .#{$ns}AsideNav-item a {
        padding-left: calc(var(--Layout-nav-height) + #{px2rem(30px)});
      }

      .#{$ns}AsideNav-item .#{$ns}AsideNav-item .#{$ns}AsideNav-item a {
        padding-left: calc(var(--Layout-nav-height) + #{px2rem(45px)});
      }

      .#{$ns}AsideNav-subList {
        display: none;
      }

      &.is-open .#{$ns}AsideNav-subList {
        display: block;
      }
    }
  }

  &-subHeader {
    display: none;

    a {
      cursor: default;
      background: transparent;
      color: var(--Layout-asideLink-color);

      padding: calc(
          (
              var(--Layout-nav--folded-height) - var(--lineHeightBase) *
                var(--Layout-asideLink-fontSize)
            ) / 2
        )
        var(--gap-md);

      &:hover {
        color: var(--Layout-asideLink-onHover-color);
        background: transparent;
      }
    }
  }

  &-tooltip {
    pointer-events: none;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    font-size: var(--Tooltip--attr-fontSize);
    line-height: var(--Tooltip--attr-lineHeigt);
    text-align: left;
    white-space: nowrap;
    background: var(--Tooltip--attr-bg);
    border: var(--Tooltip--attr-borderWidth) solid
      var(--Tooltip--attr-borderColor);
    border-radius: var(--Tooltip--attr-borderRadius);
    box-shadow: var(--Tooltip--attr-boxShadow);
    left: 100%;
    color: var(--Tooltip--attr-color);
    padding: var(--Tooltip--attr-paddingY) var(--Tooltip--attr-paddingX);
    opacity: 0;
    transition: var(--Tooltip--attr-transition);
  }

  &-divider {
    width: auto;
    height: px2rem(1px);
    margin: var(--Layout-asideDivider-margin);
    overflow: hidden;
    font-size: 0;
    background: var(--Layout-asideDivider-bg);
  }
}

// 图片 icon
img.#{$ns}AsideNav-itemIcon {
  float: none;
  height: var(--Layout-asideLink-fontSize);
  width: auto;
  margin-right: var(--gap-xs);
  vertical-align: middle;
}

// @include media-device('mobile') {

// }

@include media-breakpoint-up(md) {
  .#{$ns}Layout--folded {
    .#{$ns}AsideNav-label {
      display: none;
    }

    .#{$ns}AsideNav-subHeader {
      display: block;
    }

    .#{$ns}AsideNav-item:hover > .#{$ns}AsideNav-tooltip {
      opacity: 1;
      z-index: $zindex-tooltip;
      margin: 0 0 0 var(--Tooltip--attr-gap);
      opacity: 1;
    }

    .#{$ns}AsideNav-list > .#{$ns}AsideNav-item > a {
      text-align: center;
      position: relative;
      padding: 0;
      text-align: center;
      height: var(--Layout-nav--folded-height);
      border: none;

      .#{$ns}AsideNav-itemArrow,
      .#{$ns}AsideNav-itemLabel {
        display: none;
      }

      .#{$ns}AsideNav-itemIcon {
        width: auto;
        float: none !important;
        display: block;
        font-size: px2rem(16px);
        margin: 0;
        line-height: var(--Layout-nav--folded-height);
        border: none !important;
      }

      .#{$ns}AsideNav-itemBadge {
        position: absolute;
        right: px2rem(12px);
        top: px2rem(8px);
        z-index: 3;
      }
    }

    .#{$ns}AsideNav-list > .#{$ns}AsideNav-item.is-lg > a {
      height: var(--Layout-nav-lgHeight);

      .#{$ns}AsideNav-itemIcon {
        line-height: var(--Layout-nav-lgHeight);
      }
    }

    .#{$ns}AsideNav-item .#{$ns}AsideNav-item a {
      padding-left: var(--gap-md) !important;
    }

    .#{$ns}AsideNav-subList {
      height: 0 !important;
      position: absolute;
      left: 100%;
      top: 0 !important;
      z-index: 1050;
      width: var(--Layout-aside-width);
      box-shadow: 0 px2rem(2px) px2rem(6px) rgba(0, 0, 0, 0.1);
    }

    &.#{$ns}Layout--sm .#{$ns}AsideNav-subList {
      width: var(--Layout-aside--sm-width);
    }

    &.#{$ns}Layout--md .#{$ns}AsideNav-subList {
      width: var(--Layout-aside--md-width);
    }

    &.#{$ns}Layout--lg .#{$ns}AsideNav-subList {
      width: var(--Layout-aside--lg-width);
    }
  }
}
