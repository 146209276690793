.#{$ns}Cards {
  &-toolbar {
    @include clearfix();
    margin: 0 var(--Cards-toolbar-marginX) var(--Cards-toolbar-marginY);
  }

  &-actions {
    display: inline-block;

    > * {
      margin-right: var(--Crud-toolbar-gap);
    }
  }

  &-heading {
    padding: var(--gap-xs) 0;
  }

  &-header {
    padding: var(--Cards-toolbar-marginY) var(--Cards-toolbar-marginX);

    > * + .#{$ns}Button,
    > * + .#{$ns}ButtonGroup,
    > * + .#{$ns}ButtonToolbar {
      margin-left: var(--Crud-toolbar-gap);
    }
  }

  &-header + &-toolbar {
    padding-top: 0;
  }

  &-fixedTop {
    position: absolute;
    background: var(--white);
    z-index: -1;
    opacity: 0;
    box-shadow: var(--Cards-fixedTop-boxShadow);
    padding: var(--gap-sm);

    &.in {
      position: fixed;
      opacity: 1;
      z-index: $zindex-affix;
    }

    .#{$ns}Cards-toolbar {
      margin-bottom: 0;
    }

    &:empty {
      display: none;
    }
  }

  &--unsaved &-heading {
    background: var(--Cards--unsaved-heading-bg);
    color: var(--Cards--unsaved-heading-color);
    padding: var(--gap-xs) var(--gap-sm);
    margin-bottom: var(--gap-sm);
  }

  &-dragTip {
    width: 100%;
    color: var(--info);
    clear: both;
    margin-top: var(--gap-xs);
  }

  &-placeholder {
    background: var(--white);
    color: var(--text--muted-color);
    text-align: center;
    height: var(--Cards-placeholder-height);
    line-height: var(--Cards-placeholder-height);

    border: var(--Card-borderWidth) solid var(--Card-borderColor);
    border-radius: var(--Card-borderRadius);
  }

  &-body > div {
    display: flex;
  }

  &--masonry {
    display: block;

    &:after {
      content: none;
    }

    column-gap: 0;
    column-fill: initial;

    > div {
      width: 100%;
      float: none;
      break-inside: avoid;
      max-width: unset;
      display: inline-block;
      flex: unset;
    }
  }
}

@include media-breakpoint-up(sm) {
  .#{$ns}Cards--masonrySm1 {
    column-count: 12;
  }

  .#{$ns}Cards--masonrySm2 {
    column-count: 6;
  }

  .#{$ns}Cards--masonrySm3 {
    column-count: 4;
  }

  .#{$ns}Cards--masonrySm4 {
    column-count: 3;
  }

  .#{$ns}Cards--masonrySm6 {
    column-count: 2;
  }

  .#{$ns}Cards--masonrySm12 {
    column-count: 1;
  }

  .#{$ns}Cards-toolbar {
    display: flex;
    flex-wrap: wrap;
  }
}

@include media-breakpoint-up(md) {
  .#{$ns}Cards--masonryMd1 {
    column-count: 12;
  }

  .#{$ns}Cards--masonryMd2 {
    column-count: 6;
  }

  .#{$ns}Cards--masonryMd3 {
    column-count: 4;
  }

  .#{$ns}Cards--masonryMd4 {
    column-count: 3;
  }

  .#{$ns}Cards--masonryMd6 {
    column-count: 2;
  }

  .#{$ns}Cards--masonryMd12 {
    column-count: 1;
  }
}

@include media-breakpoint-up(lg) {
  .#{$ns}Cards--masonryLg1 {
    column-count: 12;
  }

  .#{$ns}Cards--masonryLg2 {
    column-count: 6;
  }

  .#{$ns}Cards--masonryLg3 {
    column-count: 4;
  }

  .#{$ns}Cards--masonryLg4 {
    column-count: 3;
  }

  .#{$ns}Cards--masonryLg6 {
    column-count: 2;
  }

  .#{$ns}Cards--masonryLg12 {
    column-count: 1;
  }
}
