@mixin input-range {
  -webkit-appearance: none;
  outline: none;
  border: none;
  padding: 0;
  background: none;

  &::-webkit-slider-runnable-track {
    background: var(--Audio-track-bg);
    height: var(--Audio-track-height);
    border-radius: var(--Audio-track-borderRadius);
    border: var(--Audio-track-border);
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    border-radius: 100%;
    cursor: pointer;
    background: var(--Audio-thumb-bg);
    width: var(--Audio-thumb-width);
    height: var(--Audio-thumb-height);
    margin-top: var(--Audio-thumb-marginTop);
  }
}

@mixin svg {
  width: var(--Audio-svg-width);
  height: var(--Audio-svg-height);
  position: relative;
  top: var(--Audio-svg-top);
}

.#{$ns}Audio-original {
  display: none;
}

.#{$ns}Audio--inline {
  display: inline-block;
}

.#{$ns}Audio {
  box-sizing: border-box;
  height: var(--Audio-height);
  line-height: var(--Audio-lineHeight);
  border: var(--Audio-border);
  padding-left: var(--Audio-item-margin);
  overflow: hidden;

  &-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-rates {
    width: var(--Audio-rate-width);
    height: var(--Audio-rate-height);
    text-align: center;
    background: var(--Audio-rate-bg);
    cursor: pointer;
    margin-right: var(--Audio-item-margin);
  }

  &-rateControl {
    margin-right: var(--Audio-item-margin);

    &::after {
      clear: both;
      content: '';
    }

    .#{$ns}Audio-rateControlItem {
      width: var(--Audio-rate-width);
      height: var(--Audio-rate-height);
      background: var(--Audio-rate-bg);
      cursor: pointer;
      text-align: center;
      float: left;
      box-sizing: border-box;
      border-right: var(--Audio-rateControlItem-borderRight);
    }
  }

  &-play {
    width: var(--Audio-play-width);
    cursor: pointer;
    margin-right: var(--Audio-item-margin);

    svg {
      @include svg();
    }
  }

  &-times {
    min-width: var(--Audio-times-width);
    margin-right: var(--Audio-item-margin);
    cursor: default;
    flex-shrink: 0;
  }

  &-process {
    flex: auto;
    cursor: pointer;
    min-width: var(--Audio-process-minWidth);
    margin-right: var(--Audio-item-margin);

    input[type='range'] {
      @include input-range();
    }
  }

  &-volume {
    width: var(--Audio-volume-width);
    height: var(--Audio-volume-height);
    line-height: var(--Audio-volume-lineHeight);
    cursor: pointer;
    margin-right: var(--Audio-item-margin);

    svg {
      @include svg();
    }
  }

  &-volumeControl {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: var(--Audio-volumeControl-width);
    height: var(--Audio-volume-height);
    line-height: var(--Audio-volume-lineHeight);
    margin-right: var(--Audio-item-margin);

    input[type='range'] {
      @include input-range();
    }

    .#{$ns}Audio-volumeControlIcon {
      margin-right: var(--Audio-item-margin);
      cursor: pointer;
    }

    svg {
      @include svg();
    }
  }
}
