.#{$ns}Checkbox {
  margin: 0 var(--gap-sm) 0 0;
  font-weight: var(--fontWeightNormal);
  user-select: none;
  pointer-events: none;

  input {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    pointer-events: none;
  }

  &:hover input:not(:disabled) + i {
    border-color: var(--Checkbox-onHover-color);
    // box-shadow: 0 0 px2rem(1px) var(--Checkbox-onHover-color) inset;
  }

  &.#{$ns}Checkbox--checkbox.#{$ns}Checkbox--button {
    > i + span {
      margin-left: 0;
    }
  }

  > i {
    cursor: pointer;
    line-height: 1;
    background: var(--Checkbox-gb);
    display: inline-block;
    vertical-align: middle;
    position: relative;
    pointer-events: all;

    + span {
      pointer-events: all;
      margin-left: var(--gap-sm);
      cursor: pointer;
      display: inline-block;
      vertical-align: baseline;

      > a {
        // float: right;
        margin-left: var(--gap-xs);
        display: none;
      }

      &:empty {
        display: none;
      }
    }

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0px;
      height: 0px;
      background: transparent;
      transition: all var(--animation-duration);
      transform-origin: 50% 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover > i + span > a {
    display: inline-block;
  }

  &--checkbox {
    padding-left: var(--Checkbox-size);

    input {
      margin-left: calc(var(--Checkbox-size) * -1);

      &:checked + i {
        border-color: var(--Checkbox-onHover-color);

        &:before {
          width: var(--Checkbox-inner-size);
          height: var(--Checkbox-inner-size);
          background: var(--Checkbox-onHover-color);
        }
      }

      &[disabled] + i {
        border-color: var(--Checkbox-onDisabled-color);
        cursor: not-allowed;

        &:before {
          width: var(--Checkbox-inner-size);
          height: calc(var(--Checkbox-inner-size) / 2);
        }
      }

      &[disabled] + i + span {
        cursor: not-allowed;
        color: var(--text--muted-color);
      }
    }

    > i {
      width: var(--Checkbox-size);
      height: var(--Checkbox-size);
      border: px2rem(1px) solid var(--Checkbox-color);
      border-radius: var(--Checkbox-borderRadius);
      margin-left: calc(var(--Checkbox-size) * -1);
    }

    &.#{$ns}Checkbox {
      &:hover {
        & > i:before {
          background: var(--Button--primary-onHover-bg);
        }
      }

      &:active {
        & > i:before {
          background: var(--Switch-checked-onHover-bgColor);
        }
      }
    }
  }

  &--full.#{$ns}Checkbox--checkbox {
    &:not(:disabled) + i:hover {
      border-color: var(--Checkbox-color);
    }

    &:hover {
      input:checked + i {
        background: var(--Button--primary-onHover-bg);

        &:before {
          background: var(--Button--primary-onHover-bg);
        }
      }

      & > i:before {
        background: transparent;
      }
    }

    &:active {
      input:checked + i {
        background: var(--Switch-checked-onHover-bgColor);

        &:before {
          background: var(--Switch-checked-onHover-bgColor);
        }
      }
    }

    input {
      &:checked + i {
        border-color: var(--Checkbox-onHover-color);
        background: var(--Checkbox-onHover-color);

        &:before {
          width: var(--Checkbox--full-inner-size);
          height: calc(var(--Checkbox--full-inner-size) / 2);
          border-color: var(--Checkbox-gb);
        }
      }

      &[disabled] + i {
        border-color: var(--Checkbox-color);
        background: var(--Checkbox-onDisabled-bg);
      }

      &[disabled]:checked + i {
        &:before {
          border-color: var(--Checkbox-onDisabled-color);
          background: var(--Checkbox-onDisabled-bg);
        }
      }

      &:checked[disabled] + i {
        border-color: var(--Checkbox-color);
        background: var(--Checkbox-onDisabled-bg);
      }
    }

    > i {
      position: relative;
      cursor: pointer;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 0;
        height: 0;
        border-color: transparent;
        transition: width var(--animation-duration),
          height var(--animation-duration), transform var(--animation-duration);
        border-width: 0 0 px2rem(1px) px2rem(1px);
        transform: translate(-50%, -90%) rotate(-40deg);
        border-style: solid;
      }
    }
  }

  &--partial.#{$ns}Checkbox--checkbox {
    input {
      margin-left: calc(var(--Checkbox-size) * -1);

      & + i {
        border-color: var(--Checkbox-onHover-color);

        &:before {
          width: var(--Checkbox-inner-size);
          height: var(--Checkbox-inner-size);
          background: var(--Checkbox-onHover-color);
        }
      }

      &[disabled] + i {
        border-color: var(--Checkbox-onDisabled-color);

        &:before {
          width: var(--Checkbox-inner-size);
          height: var(--Checkbox-inner-size);
          background: var(--Checkbox-inner-onDisabled-bg);
        }
      }

      &:checked[disabled] + i {
        width: var(--Checkbox-inner-size);
        height: var(--Checkbox-inner-size);
        background: var(--Checkbox-onDisabled-bg);
      }
    }
  }
  &--button.#{$ns}Checkbox--checkbox {
    text-align: center;
    height: var(--Checkbox-button-height);
    line-height: var(--Checkbox-button-line-height);
    padding-left: var(--Checkbox-paddingX);
    padding-right: var(--Checkbox-paddingX);
    font-size: var(--fontSizeSm);
    border: var(--Checkbox-border-width) solid;
    cursor: pointer;
    position: relative;
    display: inline-block;
    background-color: var(--Checkbox-gb);
    border-color: var(--Checkbox-color);
    margin-right: 0;
    border-radius: calc(var(--Checkbox-borderRadius) * 2);
    min-width: var(--Checkbox-button-min-width);

    &:hover:not(:disabled) {
      color: var(--Checkbox-onHover-color);
      border-color: var(--Checkbox-onHover-color);
    }

    &:hover {
      input:checked + i {
        background: transparent;
        border-color: var(--Button--primary-onHover-bg) transparent transparent
          var(--Button--primary-onHover-bg);
      }
    }

    &:active {
      input:checked + i {
        background: transparent;
        border-color: var(--Switch-checked-onHover-bgColor) transparent
          transparent var(--Switch-checked-onHover-bgColor);
      }
    }

    input {
      &:checked + i {
        background: transparent;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-width: px2rem(8px);
        border-color: var(--Checkbox-onHover-color) transparent transparent
          var(--Checkbox-onHover-color);
        border-radius: 0;

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: var(--Checkbox--full-inner-size);
          height: calc(var(--Checkbox--full-inner-size) / 2);
          transform: translate(-80%, -160%) rotate(-50deg) scale(0.8);
          background: transparent;
        }
      }

      &[disabled] + i {
        border-color: var(--Checkbox-onDisabled-color);
        background: transparent;
        &:before {
          background: transparent;
        }
      }

      &:checked[disabled] + i {
        background: transparent;
        border-width: px2rem(7px);
        border-color: var(--Checkbox-inner-disabled-checked-bg) transparent
          transparent var(--Checkbox-inner-disabled-checked-bg);
          &:before {
            background: transparent;
            border-color: var(--Checkbox-inner-onDisabled-color) !important;
          }
      }
    }
    > i {
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 0;
      border: 0;
      border-style: solid;
      background: transparent;

      + span {
        max-width: px2rem(100px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  &--button--checked.#{$ns}Checkbox--checkbox.#{$ns}Checkbox--button {
    border-color: var(--Checkbox-onHover-color);
    color: var(--Checkbox-onHover-color);

    &:hover {
      border-color: var(--menu-active-color);
      color: var(--menu-active-color);
    }
    &:active {
      border-color: var(--Switch-checked-onHover-bgColor);
      color: var(--Switch-checked-onHover-bgColor);
    }
  }
  &--button--disabled--unchecked.#{$ns}Checkbox--checkbox {
    background: var(--Checkbox-disabled-unchecked-bg);
    border-color: var(--Checkbox-color);
    &:hover {
      border-color: var(--Checkbox-color) !important;
    }
  }
  &--button--disabled--checked.#{$ns}Checkbox--checkbox {
    background: var(--Checkbox-onDisabled-bg);
    border-color: var(--Checkbox-color) !important;
    &:hover {
      border-color: var(--Checkbox-color) !important;
    }
  }
  &--radio {
    padding-left: var(--Radio-size);

    input {
      margin-left: calc(var(--Radio-size) * -1);

      &:checked + i {
        border-color: var(--Radio-onHover-color);

        &:before {
          width: var(--Radio-inner-size);
          height: var(--Radio-inner-size);
          background: var(--Radio-onHover-color);
          border-radius: 50%;
        }
      }

      &[disabled] + i {
        border-color: var(--Radio-onDisabled-color);
        background: var(--Radio-onDisabled-bg);
        cursor: not-allowed;

        &:before {
          background: var(--Radio-onDisabled-color);
        }
      }

      &[disabled] + i + span {
        cursor: not-allowed;
        color: var(--text--muted-color);
      }

      &:focus + i {
        box-shadow: var(--Radio-onFocus-boxShadow);
      }
    }

    > i {
      cursor: pointer;
      width: var(--Radio-size);
      height: var(--Radio-size);
      border: px2rem(1px) solid var(--Radio-color);
      margin-left: calc(var(--Radio-size) * -1);
      margin-top: px2rem(-2px);
      border-radius: 50%;
    }

    &.#{$ns}Checkbox--button--disabled--unchecked.#{$ns}Checkbox--full {
      background: none;

      i {
        background: var(--FileControl-onDisabled-bg);
        border: 1px solid var(--menu-border-color);

        &:before {
          background: var(--Checkbox-inner-onDisabled-bg);
        }
      }
    }
  }

  &--sm {
    padding-left: var(--Checkbox--sm-size);

    input {
      margin-left: calc(var(--Checkbox--sm-size) * -1);

      &:checked + i {
        &:before {
          width: var(--Checkbox--sm-inner-size);
          height: var(--Checkbox--sm-inner-size);
        }
      }
    }

    > i {
      width: var(--Checkbox--sm-size);
      height: var(--Checkbox--sm-size);
      margin-left: calc(var(--Checkbox--sm-size) * -1);
      margin-top: 0;

      + span {
        margin-left: var(--gap-xs);
      }
    }
  }

  &--sm.#{$ns}Checkbox--full {
    input[type='checkbox'] {
      &:checked + i {
        &:before {
          width: var(--Checkbox--sm--full-inner-size);
          height: calc(var(--Checkbox--sm--full-inner-size) / 2);
        }
      }
    }
  }

  &-desc {
    color: var(--text--muted-color);
    margin-left: var(--Checkbox-gap);
    margin-top: var(--gap-xs);
    pointer-events: all;
  }
}

.#{$ns}CheckboxControl,
.#{$ns}RadiosControl,
.#{$ns}CheckboxesControl {
  padding-top: calc(
    (var(--Form-input-height) - var(--Checkbox-size)) / 2 - 3px
  ); // 3px 是空白的高度
}

.#{$ns}RadiosControl,
.#{$ns}CheckboxesControl {
  .#{$ns}Checkbox {
    display: block;
    margin-bottom: var(--Form-label-paddingTop);
  }

  .#{$ns}Checkbox--button {
    margin-bottom: 0;

    &--disabled--unchecked {
      background: var(--FileControl-onDisabled-bg);
    }

    &-checked {
      &:active {
        border-color: var(--Switch-checked-onHover-bgColor);
      }
    }
  }

  &.is-inline .#{$ns}Checkbox {
    display: inline-block;
    margin-right: var(--gap-md);
  }

  &.is-inline .#{$ns}Checkbox--button {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 0;
  }
}

.#{$ns}RadiosControl-group,
.#{$ns}CheckboxesControl-group {
  &:not(:first-child) {
    margin-top: px2rem(10px);
  }

  .#{$ns}RadiosControl-groupLabel,
  .#{$ns}CheckboxesControl-groupLabel {
    display: block;
    font-size: var(--fontSizeSm);
    color: #999;
  }
}

.#{$ns}RadiosControl {
  .#{$ns}Button:active,
  .#{$ns}Button.is-active {
    background: var(--ButtonGroup--primary-isActive-bg);
  }
}
