.#{$ns}ButtonGroup {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  > .#{$ns}Button {
    position: relative;
    flex: 0 1 auto;
    max-width: px2rem(140px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > .#{$ns}Button {
    @include hover {
      z-index: 1;
    }

    &:focus,
    &:active,
    &.active,
    &.is-active {
      z-index: 1;
    }
  }

  .#{$ns}Button + .#{$ns}Button,
  .#{$ns}Button + .#{$ns}ButtonGroup,
  .#{$ns}ButtonGroup + .#{$ns}Button,
  .#{$ns}ButtonGroup + .#{$ns}ButtonGroup {
    margin-left: calc(var(--Button-borderWidth) * -1);
  }

  &--block {
    display: block;
  }
}

.#{$ns}ButtonToolbar {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .#{$ns}ButtonGroup {
    margin-left: var(--gap-xs);
    margin-top: var(--gap-xs);
    width: auto;
  }
}

.#{$ns}ButtonGroup {
  > .#{$ns}Button:first-child {
    margin-left: 0;
  }

  // cxd的ButtonGroup的is-active和:active样式不一样，这里做个兼容
  .#{$ns}Button--primary.is-active {
    color: var(--ButtonGroup--primary-isActive-color);
    background: var(--ButtonGroup--primary-isActive-bg);
  }

  > .#{$ns}Button:not(:last-child),
  > .#{$ns}ButtonGroup:not(:last-child) > .#{$ns}Button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > .#{$ns}Button:not(:first-child),
  > .#{$ns}ButtonGroup:not(:first-child) > .#{$ns}Button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > .#{$ns}ButtonGroup:last-child > .#{$ns}Button:not(:last-child) {
    border-top-right-radius: 0;
  }
}

.#{$ns}ButtonGroup--vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .#{$ns}Button,
  .#{$ns}ButtonGroup {
    width: 100%;
  }

  > .#{$ns}Button + .#{$ns}Button,
  > .#{$ns}Button + .#{$ns}ButtonGroup,
  > .#{$ns}ButtonGroup + .#{$ns}Button,
  > .#{$ns}ButtonGroup + .#{$ns}ButtonGroup {
    margin-top: calc(var(--Button-borderWidth) * -1);
    margin-left: 0;
  }

  > .#{$ns}Button:not(:last-child),
  > .#{$ns}ButtonGroup:not(:last-child) > .#{$ns}Button {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  > .#{$ns}Button:not(:first-child),
  > .#{$ns}ButtonGroup:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  /* 修正头尾的圆角 */
  > .#{$ns}Button:first-child,
  > .#{$ns}ButtonGroup:first-child > .#{$ns}Button:first-child {
    border-top-right-radius: var(--Button-borderRadius);
  }

  > .#{$ns}Button:last-child,
  > .#{$ns}ButtonGroup:last-child > .#{$ns}Button:last-child {
    border-bottom-left-radius: var(--Button-borderRadius);
  }
}

.#{$ns}ButtonGroup--tiled {
  display: flex;

  .#{$ns}Button,
  .#{$ns}ButtonGroup {
    flex-grow: 1;
    text-align: center;
  }
}
