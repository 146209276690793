.#{$ns}StatusField {
  vertical-align: middle;
  white-space: nowrap;
  display: inline-flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;

  &-label {
    font-size: var(--fontSizeSm);
    margin-left: var(--gap-xs);
  }

  @keyframes animation-rolling_red {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(20px);
    }

    100% {
      transform: translateX(0);
    }
  }

  @keyframes animation-rolling_blue {
    0% {
      transform: translateX(0);
    }

    50% {
      transform: translateX(-20px);
    }

    100% {
      transform: translateX(0);
    }
  }

  svg.#{$ns}Status-icon {
    width: var(--Satus-icon-width);
    height: var(--Satus-icon-height);
    top: 0;
  }

  .#{$ns}Status-icon {
    &.rolling {
      width: 30px;
      height: 10px;
      display: inline-block;
      vertical-align: middle;
      position: relative;

      &::before {
        content: '';
        display: inline-block;
        border: 5px solid var(--danger);
        border-radius: 500px;
        position: absolute;
        top: 0;
        left: 0;
        animation: animation-rolling_red 2s;
        animation-iteration-count: infinite;
      }

      &::after {
        content: '';
        display: inline-block;
        border: 5px solid var(--primary);
        border-radius: 500px;
        position: absolute;
        top: 0;
        left: 20px;

        animation: animation-rolling_blue 2s;
        animation-iteration-count: infinite;
      }
    }

    &.icon-warning {
      color: var(--warning);
    }
  }

  .#{$ns}Status-icon.rolling + &-label {
    color: var(--warning);
  }

  .#{$ns}Status-icon.icon-success + &-label {
    color: var(--success);
  }

  .#{$ns}Status-icon.icon-fail + &-label {
    color: var(--danger);
  }

  .#{$ns}Status-icon.icon-warning + &-label {
    color: var(--warning);
  }
}
