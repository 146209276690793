@use 'sass:math';

.#{$ns}Images {
  display: flex;
  flex-wrap: wrap;
  margin: calc(var(--gap-xs) * -1);

  &-item {
    display: flex;
    margin: var(--gap-xs);
  }
}

.#{$ns}Image {
  border: var(--borderWidth) solid var(--borderColor);

  &--thumb {
    display: inline-block;
    padding: var(--gap-xs);
  }

  &-thumbWrap {
    position: relative;
    height: 100%;
  }

  &-image {
    display: block;
  }

  &--original &-image {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    margin: auto;
  }

  &-thumb {
    width: px2rem(110px);
    height: px2rem(110px);
    position: relative;
    overflow: hidden;

    > img {
      position: absolute;
      left: 50%;
      top: 50%;
      max-height: 100%;
      width: auto;
      transform: translate(-50%, -50%);
    }
  }

  &-info {
    padding: 5px 10px;
  }

  &--thumb &-info {
    width: px2rem(110px);
    padding: 0;
  }

  &-thumb--4-3 {
    height: px2rem(math.div(110px * 3, 4));
  }

  &-thumb--16-9 {
    height: px2rem(math.div(110px * 9, 16));
  }

  &-thumb--fixed-size {
    min-width: px2rem(110px);
    min-height: px2rem(110px);
    width: 100%;
    padding: 0;
    height: 100%;
  }

  &-thumb--fixed-size &-thumb {
    width: 100%;
    height: 100%;

    > img {
      width: auto;
      height: 100%;
    }
  }

  &-thumb--w-full > img {
    width: 100%;
    height: auto;
  }

  @supports (object-fit: contain) {
    &-thumb--contain > img {
      position: static;
      width: 100% !important;
      height: 100% !important;
      transform: translate(0, 0);
      top: 0;
      left: 0;
      object-fit: contain;
    }

    &-origin--contain > img {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
    }
  }

  @supports (object-fit: cover) {
    &-thumb--cover > img {
      position: static;
      width: 100% !important;
      height: 100% !important;
      transform: translate(0, 0);
      top: 0;
      left: 0;
      object-fit: cover;
    }

    &-origin--cover > img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
    }
  }

  &-caption {
    font-size: var(--fontSizeSm);
  }

  &-title {
    font-size: var(--fontSizeBase);
    color: var(--text--loud-color);
  }

  &-overlay {
    background: rgba(8, 14, 26, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    top: 0;
    left: 0;

    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    color: #fff;

    > div {
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
    }

    > a {
      cursor: pointer;
      color: #fff;
      display: inline-block;
      padding: 0 5px;
      line-height: 1;
      font-size: px2rem(16px);
    }
  }

  &-origin:hover &-overlay,
  &-thumbWrap:hover &-overlay {
    display: flex;
  }
}

.#{$ns}ImageField--thumb {
  display: inline-block;
  position: relative;
  @include clearfix();
  // margin-bottom: var(--gap-md);
}

.#{$ns}ImagesField {
  position: relative;
  @include clearfix();
}
