.#{$ns}Remark {
  display: inline-block;
  vertical-align: middle;
  margin-left: var(--Remark-marginLeft);
  > span {
    color: var(--Remark-iconColor);
    font-size: var(--fontSizeSm);
  }

  cursor: pointer;
  text-align: center;

  &-icon {
    color: var(--Remark-iconColor);
    font-size: var(--Remark-icon-fontSize);
    background: var(--Remark-bg);
    border: var(--Remark-borderWidth) solid var(--Remark-borderColor);
    width: var(--Remark-width);
    height: var(--Remark-width);
    line-height: 1;
    display: inline-flex;
    vertical-align: baseline;
    align-self: center;
    align-items: center;
    justify-content: center;
    font-size: var(--fontSizeSm);
    user-select: none;

    > svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &-icon--circle {
    border-radius: 50%;
  }

  > span + .#{$ns}Remark-icon {
    margin-left: var(--gap-xs);
  }

  &:hover > &-icon {
    color: var(--Remark-onHover-iconColor);
    background: var(--Remark-onHover-bg);
    border-color: var(--Remark-onHover-borderColor);
  }

  > svg {
    width: calc(var(--Remark-width) - #{px2rem(2px)});
    height: calc(var(--Remark-width) - #{px2rem(2px)});
    color: var(--Remark-iconColor);
  }

  &:hover > svg {
    color: var(--Remark-onHover-iconColor);
  }
}
