.#{$ns}Nav {
  position: relative;
  .#{$ns}Nav-list {
    list-style: none;
    user-select: none;
    margin: 0;
    padding: 0;
    position: relative;

    .#{$ns}Nav-itemIcon {
      margin-right: var(--gap-sm);
    }

    img.#{$ns}Nav-itemIcon {
      height: var(--Tabs-linkFontSize);
      vertical-align: middle;
    }

    &-overflow {
      min-height: var(--Nav-Item-height) !important;
      max-height: calc(var(--Nav-Item-height) * 5);
      max-width: var(--Nav-Item-maxWidth--tabs);
      font-size: var(--Nav-subItem-fontSize);
      overflow-x: hidden;
      overflow-y: auto;

      .#{$ns}Nav-subItems {
        margin-block-start: 0;
        margin-block-end: 0;
      }

      .#{$ns}Nav-item {
        max-width: var(--Nav-Item-maxWidth--tabs);
        height: var(--Nav-Item-height);

        > a {
          border: none !important;
          display: block !important;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          user-select: none;
        }
      }
    }

    &--tabs {
      border-bottom: var(--Tabs-borderWidth) solid var(--Tabs-borderColor);

      .#{$ns}Nav-item {
        margin-bottom: calc(var(--Tabs-borderWidth) * -1);
        display: inline-block;
        position: relative;
        max-width: var(--Nav-Item-maxWidth--tabs);

        > a {
          font-size: var(--Tabs-linkFontSize);
          display: block;
          outline: none;
          border: var(--Tabs-borderWidth) solid transparent;
          border-top-left-radius: var(--Tabs-borderRadius);
          border-top-right-radius: var(--Tabs-borderRadius);
          color: var(--Tabs-color);
          text-decoration: none;
          margin-right: px2rem(2px);
          padding: var(--gap-sm) var(--gap-xl);
          cursor: pointer;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &:hover > a,
        > a:focus {
          border-color: var(--Tabs-onHover-borderColor);
          border-bottom: none; // 外层已经有border-bottom了
          text-decoration: none;
        }

        &.disabled > a,
        &.is-disabled > a {
          color: var(--Tabs-onDisabled-color);
          background: transparent;
          border-color: transparent;
          pointer-events: none;
        }

        &.active > a,
        &.is-active > a {
          color: var(--Tabs-onActive-color);
          background: var(--Tabs-onActive-bg);
          border-color: var(--Tabs-onActive-borderColor);
          border-bottom-color: var(--Tabs-content-bg);
        }
      }

      .#{$ns}Nav-item-overflow {
        &:hover > a,
        > a:focus,
        &.is-overflow-opened > a {
          color: var(--primary);
          border-color: transparent;
        }
      }
    }

    &--stacked {
      min-height: px2rem(50px);

      .#{$ns}Nav-item,
      .#{$ns}Badge {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;

        .#{$ns}Nav-itemDrager {
          cursor: move;
          position: absolute;
          left: 0;
          display: none;
          line-height: 0;
          > .icon,
          > .#{$ns}Badge > .icon {
            color: var(--icon-color);

            &:hover {
              color: var(--icon-onHover-color);
            }
          }

          svg {
            width: px2rem(16px);
            height: px2rem(16px);
          }
        }

        > .#{$ns}Nav-item-badgeText {
          position: absolute;
          top: 0;
          bottom: 0;
          width: px2rem(35px);
          overflow: hidden;
          > span {
            position: absolute;
            top: px2rem(2px);
            left: px2rem(-13px);
            transform: rotate(-45deg);
            width: px2rem(50px);
            font-size: 12px;
            text-align: center;
            color: var(--white);
            background: var(--success);
          }
        }

        > .#{$ns}Nav-item-atcions {
          display: flex;
          align-items: center;
        }

        > a,
        > .#{$ns}Badge > a {
          display: flex;
          align-items: center;
          outline: none;
          color: var(--Nav-item-color);
          text-decoration: none;
          padding: var(--gap-sm) var(--gap-sm);
          cursor: pointer;
          background: var(--Nav-item-bg);
          border-radius: var(--Nav-item-borderRadius);
          text-overflow: ellipsis;
          flex: 1;

          // &::after {
          //   border-left: var(--Nav-item-onActive-borderLeft);
          //   position: absolute;
          //   left: 0;
          //   top: 0;
          //   content: '';
          //   width: 1px;
          //   height: 100%;
          //   transform: scaleY(0.0001);
          //   transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
          //     opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
          // }
        }

        > a:hover,
        > a:focus,
        > a:hover + .#{$ns}Nav-item-atcions,
        > a:focus + .#{$ns}Nav-item-atcions,
        > .#{$ns}Badge > a:hover,
        > .#{$ns}Badge > a:focus,
        > .#{$ns}Badge > a:hover + .#{$ns}Nav-item-atcions,
        > .#{$ns}Badge > a:focus + .#{$ns}Nav-item-atcions {
          border-color: var(--Nav-item-onHover-color);
          text-decoration: none;
          background: var(--Nav-item-onHover-bg);
          z-index: 1;
        }

        > a:hover > .#{$ns}Nav-itemDrager,
        > a:focus > .#{$ns}Nav-itemDrager,
        > .#{$ns}Badge > a:hover > .#{$ns}Nav-itemDrager,
        > .#{$ns}Badge > a:focus > .#{$ns}Nav-itemDrager {
          display: block;
        }

        &.disabled > a,
        &.is-disabled > a,
        &.disabled > .#{$ns}Badge > a,
        &.is-disabled > .#{$ns}Badge > a {
          color: var(--Nav-item-onDisabled-color);
          background: transparent;
          pointer-events: none;
        }

        &.active > a,
        &.is-active > .#{$ns}Nav-item-atcions,
        &.is-active > a,
        &.active > .#{$ns}Badge > a,
        &.is-active > .#{$ns}Badge > .#{$ns}Nav-item-atcions,
        &.is-active > .#{$ns}Badge > a {
          color: var(--Nav-item-onActive-color);
          position: relative;
          background: var(--Nav-item-onActive-bg) !important;

          &::after {
            transform: scaleY(1);
          }
        }

        &.is-unfolded >,
        &.is-unfolded > .#{$ns}Badge > {
          a .#{$ns}Nav-itemToggler {
            transform: rotate(180deg) scale(0.8);
          }

          .#{$ns}Nav-subItems {
            display: block;
            // margin-left: var(--gap-sm);
          }
        }

        .#{$ns}Nav-spinner {
          position: absolute;
          right: px2rem(10px);
          top: px2rem(8px);
        }

        .#{$ns}Nav-itemToggler {
          // position: absolute;
          // left: 0;
          // top: px2rem(3px);
          float: left;
          margin-right: px2rem(10px);
          height: px2rem(24px);
          text-align: center;
          line-height: px2rem(24px);
          vertical-align: middle;
          cursor: pointer;
          transform: scale(0.8);
          transition: transform var(--animation-duration);

          > svg {
            width: 10px;
            height: 10px;
            top: 0;
          }
        }

        .#{$ns}Nav-subItems {
          // background: var(--Nav-subNav-bg);
          display: none;
          padding-left: 0;
          list-style: none;
          width: 100%;
        }

        .#{$ns}Nav-item {
          font-size: var(--Nav-subItem-fontSize);

          // a:before {
          //   content: '';
          //   display: inline-block;
          //   width: px2rem(4px);
          //   height: px2rem(4px);
          //   background: #ccc;
          //   border-radius: px2rem(500px);
          //   margin-right: px2rem(8px);
          //   vertical-align: middle;
          // }

          // &.active > a:before,
          // &.is-active > a:before {
          //   background: var(--Nav-subItem-onActiveBeforeBg);
          // }
        }
      }
    }
  }
  .#{$ns}Nav-dropIndicator {
    position: absolute;
    background: var(--Nav-item-onActive-color);
    height: 2px;
  }
}
