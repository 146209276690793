/*
 * utilities
 */

// background

.bg-light {
  @include color-variant($light, 2%, 3%, 3%, 5%);
  color: $text-color;
}

.bg-dark {
  @include color-variant($dark, 5%, 10%, 5%, 10%);
  @include font-variant($dark);
}

.bg-black {
  @include color-variant($black, 5%, 10%, 5%, 10%);
  @include font-variant($black);
}

.bg-primary {
  @include color-variant($primary, 5%, 10%, 5%, 10%);
  @include font-variant($primary);
}

.bg-success {
  @include color-variant($success, 5%, 10%, 5%, 10%);
  @include font-variant($success);
}

.bg-info {
  @include color-variant($info, 5%, 10%, 5%, 10%);
  @include font-variant($info);
}

.bg-warning {
  @include color-variant($warning, 5%, 10%, 5%, 10%);
  @include font-variant($warning);
}

.bg-danger {
  @include color-variant($danger, 5%, 10%, 5%, 10%);
  @include font-variant($danger);
}

.bg-white {
  @include color-variant($white, 5%, 10%, 5%, 10%);
  color: $text-color;
}

.bg-none {
  background: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

// text colors
@include text-wariant($primary, primary);
@include text-wariant($info, info);
@include text-wariant($success, success);
@include text-wariant($warning, warning);
@include text-wariant($danger, danger);
@include text-wariant($dark, dark);
@include text-wariant($white, white);
@include text-wariant($black, black);

.text-muted {
  color: var(--text--muted-color);
}

.text-loud {
  color: var(--text--loud-color);
}

.clearfix {
  @include clearfix();
}

.pos-rlt {
  position: relative;
}

.pos-stc {
  position: static !important;
}

.pos-abt {
  position: absolute;
}

.pos-fix {
  position: fixed;
}

.show {
  visibility: visible;
}

.line {
  // width: 100%; 不要设置 100%， 否则跟 pull-in 配合使用时候，宽度为不对。
  height: 2px;
  margin: 10px 0;
  font-size: 0;
  overflow: hidden;
}

.line-xs {
  margin: 0;
}

.line-lg {
  margin-top: 15px;
  margin-bottom: 15px;
}

.line-dashed {
  border-style: dashed !important;
  background: transparent;
  border-width: 0;
}

.no-line {
  border-width: 0;
}

.no-border,
.no-borders {
  border-color: transparent;
  border-width: 0;
}

.no-b-t {
  border-top-width: 0;
}

.no-b-r {
  border-right-width: 0;
}

.no-b-b {
  border-bottom-width: 0;
}

.no-b-l {
  border-left-width: 0;
}

.no-radius {
  border-radius: 0;
}

.block {
  display: block;
}

.block.hide {
  display: none;
}

.inline {
  display: inline-block;
}

.none {
  display: none;
}

.pull-none {
  float: none;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.rounded {
  border-radius: 500px;
}

.clear {
  display: block;
  overflow: hidden;
}

.no-bg {
  background: transparent;
  color: inherit;
}

.no-select {
  user-select: none;
}

.l-h {
  line-height: var(--lineHeightBase);
}

.l-h-0x {
  line-height: 0;
}

.l-h-1x {
  line-height: 1.2;
}

.l-h-2x {
  line-height: 2em;
}

.l-s-1x {
  letter-spacing: 1;
}

.l-s-2x {
  letter-spacing: 2;
}

.l-s-3x {
  letter-spacing: 3;
}

.font-normal {
  font-weight: normal;
}

.font-thin {
  font-weight: 300;
}

.font-bold {
  font-weight: 700;
}

.text-5x {
  font-size: 5em;
}

.text-4x {
  font-size: 4em;
}

.text-3x {
  font-size: 3em;
}

.text-2x {
  font-size: 2em;
}

.text-xl {
  font-size: var(--fontSizeXl) !important;
}

.text-lg {
  font-size: var(--fontSizeLg) !important;
}

.text-md {
  font-size: var(--fontSizeMd) !important;
}

.text-base {
  font-size: var(--fontSizeBase) !important;
}

.text-sm {
  font-size: var(--fontSizeSm) !important;
}

.text-xs {
  font-size: var(--fontSizeXs) !important;
}

.text-xxs {
  text-indent: -9999px;
}

.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-u-c {
  text-transform: uppercase;
}

.text-l-t {
  text-decoration: line-through;
}

.text-u-l {
  text-decoration: underline;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.box-shadow {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}

.box-shadow-lg {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}

.text-shadow {
  font-size: 170px;
  text-shadow: 0 1px 0 var(--borderColor), 0 2px 0 var(--borderColorLight),
    0 5px 10px rgba(0, 0, 0, 0.125), 0 10px 20px rgba(0, 0, 0, 0.2);
}

.no-shadow {
  box-shadow: none !important;
}

.wrapper-xs {
  padding: 5px;
}

.wrapper-sm {
  padding: 10px;
}

.wrapper {
  padding: 15px;
}

.wrapper-md {
  padding: 20px;
}

.wrapper-lg {
  padding: 30px;
}

.wrapper-xl {
  padding: 50px;
}

.padder-lg {
  padding-left: 30px;
  padding-right: 30px;
}

.padder-md {
  padding-left: 20px;
  padding-right: 20px;
}

.padder {
  padding-left: 15px;
  padding-right: 15px;
}

.padder-v-xs {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padder-v-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padder-v {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padder-v-md {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padder-v-lg {
  padding-top: 30px;
  padding-bottom: 30px;
}

.no-padder {
  padding: 0 !important;
}

.pull-in {
  margin-left: -15px;
  margin-right: -15px;
}

.pull-in-md {
  margin-left: -20px;
  margin-right: -20px;
}

.pull-in-sm {
  margin-left: -10px;
  margin-right: -10px;
}

.pull-out {
  margin: -10px -15px;
}

.b {
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.b-a {
  border: 1px solid var(--borderColor);
}

.b-t {
  border-top: 1px solid var(--borderColor);
}

.b-t-none {
  border-top-width: 0;
}

.b-r {
  border-right: 1px solid var(--borderColor);
}

.b-r-none {
  border-right-width: 0;
}

.b-b {
  border-bottom: 1px solid var(--borderColor);
}

.b-b-none {
  border-bottom-width: 0;
}

.b-l {
  border-left: 1px solid var(--borderColor);
}

.b-l-none {
  border-left-width: 0;
}

.b-light {
  border-color: var(--light);
}

.b-dark {
  border-color: var(--dark);
}

.b-black {
  border-color: var(--dark);
}

.b-primary {
  border-color: var(--primary);
}

.b-success {
  border-color: var(--success);
}

.b-info {
  border-color: var(--info);
}

.b-warning {
  border-color: var(--warning);
}

.b-danger {
  border-color: var(--danger);
}

.b-white {
  border-color: #fff;
}

.b-dashed {
  border-style: dashed !important;
}

.b-l-light {
  border-left-color: var(--light);
}

.b-l-dark {
  border-left-color: var(--dark);
}

.b-l-black {
  border-left-color: var(--dark);
}

.b-l-primary {
  border-left-color: var(--primary);
}

.b-l-success {
  border-left-color: var(--success);
}

.b-l-info {
  border-left-color: var(--info);
}

.b-l-warning {
  border-left-color: var(--warning);
}

.b-l-danger {
  border-left-color: var(--danger);
}

.b-l-white {
  border-left-color: #fff;
}

.b-l-2x {
  border-left-width: 2px;
}

.b-l-3x {
  border-left-width: 3px;
}

.b-l-4x {
  border-left-width: 4px;
}

.b-l-5x {
  border-left-width: 5px;
}

.b-2x {
  border-width: 2px;
}

.b-3x {
  border-width: 3px;
}

.b-4x {
  border-width: 4px;
}

.b-5x {
  border-width: 5px;
}

.r {
  border-radius: var(--borderRadius) var(--borderRadius) var(--borderRadius)
    var(--borderRadius);
}

.r-2x {
  border-radius: var(--borderRadiusMd);
}

.r-3x {
  border-radius: var(--borderRadiusLg);
}

.r-l {
  border-radius: var(--borderRadius) 0 0 var(--borderRadius);
}

.r-r {
  border-radius: 0 var(--borderRadius) var(--borderRadius) 0;
}

.r-t {
  border-radius: var(--borderRadius) var(--borderRadius) 0 0;
}

.r-b {
  border-radius: 0 0 var(--borderRadius) var(--borderRadius);
}

.m-xxs {
  margin: 2px 4px;
}

.m-xs {
  margin: 5px;
}

.m-sm {
  margin: 10px;
}

.m {
  margin: 15px;
}

.m-md {
  margin: 20px;
}

.m-lg {
  margin: 30px;
}

.m-xl {
  margin: 50px;
}

.m-n,
.m-none {
  margin: 0 !important;
}

.m-l-none {
  margin-left: 0 !important;
}

.m-l-xs {
  margin-left: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-l {
  margin-left: 15px;
}

.m-l-md {
  margin-left: 20px;
}

.m-l-lg {
  margin-left: 30px;
}

.m-l-xl {
  margin-left: 40px;
}

.m-l-xxl {
  margin-left: 50px;
}

.m-l-n-xxs {
  margin-left: -1px;
}

.m-l-n-xs {
  margin-left: -5px;
}

.m-l-n-sm {
  margin-left: -10px;
}

.m-l-n {
  margin-left: -15px;
}

.m-l-n-md {
  margin-left: -20px;
}

.m-l-n-lg {
  margin-left: -30px;
}

.m-l-n-xl {
  margin-left: -40px;
}

.m-l-n-xxl {
  margin-left: -50px;
}

.m-t-none {
  margin-top: 0 !important;
}

.m-t-xxs {
  margin-top: 1px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t {
  margin-top: 15px;
}

.m-t-md {
  margin-top: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-t-xl {
  margin-top: 40px;
}

.m-t-xxl {
  margin-top: 50px;
}

.m-t-n-xxs {
  margin-top: -1px;
}

.m-t-n-xs {
  margin-top: -5px;
}

.m-t-n-sm {
  margin-top: -10px;
}

.m-t-n {
  margin-top: -15px;
}

.m-t-n-md {
  margin-top: -20px;
}

.m-t-n-lg {
  margin-top: -30px;
}

.m-t-n-xl {
  margin-top: -40px;
}

.m-t-n-xxl {
  margin-top: -50px;
}

.m-r-none {
  margin-right: 0 !important;
}

.m-r-xxs {
  margin-right: 1px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-r {
  margin-right: 15px;
}

.m-r-md {
  margin-right: 20px;
}

.m-r-lg {
  margin-right: 30px;
}

.m-r-xl {
  margin-right: 40px;
}

.m-r-xxl {
  margin-right: 50px;
}

.m-r-n-xxs {
  margin-right: -1px;
}

.m-r-n-xs {
  margin-right: -5px;
}

.m-r-n-sm {
  margin-right: -10px;
}

.m-r-n {
  margin-right: -15px;
}

.m-r-n-md {
  margin-right: -20px;
}

.m-r-n-lg {
  margin-right: -30px;
}

.m-r-n-xl {
  margin-right: -40px;
}

.m-r-n-xxl {
  margin-right: -50px;
}

.m-b-none {
  margin-bottom: 0 !important;
}

.m-b-xxs {
  margin-bottom: 1px;
}

.m-b-xs {
  margin-bottom: 5px;
}

.m-b-sm {
  margin-bottom: 10px;
}

.m-b {
  margin-bottom: 15px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-b-xl {
  margin-bottom: 40px;
}

.m-b-xxl {
  margin-bottom: 50px;
}

.m-b-n-xxs {
  margin-bottom: -1px;
}

.m-b-n-xs {
  margin-bottom: -5px;
}

.m-b-n-sm {
  margin-bottom: -10px;
}

.m-b-n {
  margin-bottom: -15px;
}

.m-b-n-md {
  margin-bottom: -20px;
}

.m-b-n-lg {
  margin-bottom: -30px;
}

.m-b-n-xl {
  margin-bottom: -40px;
}

.m-b-n-xxl {
  margin-bottom: -50px;
}

.p-xxs {
  padding: 2px 4px;
}

.p-xs {
  padding: 5px;
}

.p-sm {
  padding: 10px;
}

.p {
  padding: 15px;
}

.p-md {
  padding: 20px;
}

.p-lg {
  padding: 30px;
}

.p-xl {
  padding: 50px;
}

.p-n,
.p-none {
  padding: 0 !important;
}

.p-l-none {
  padding-left: 0 !important;
}

.p-l-xs {
  padding-left: 5px;
}

.p-l-sm {
  padding-left: 10px;
}

.p-l {
  padding-left: 15px;
}

.p-l-md {
  padding-left: 20px;
}

.p-l-lg {
  padding-left: 30px;
}

.p-l-xl {
  padding-left: 40px;
}

.p-l-xxl {
  padding-left: 50px;
}

.p-t-none {
  padding-top: 0 !important;
}

.p-t-xxs {
  padding-top: 1px;
}

.p-t-xs {
  padding-top: 5px;
}

.p-t-sm {
  padding-top: 10px;
}

.p-t {
  padding-top: 15px;
}

.p-t-md {
  padding-top: 20px;
}

.p-t-lg {
  padding-top: 30px;
}

.p-t-xl {
  padding-top: 40px;
}

.p-t-xxl {
  padding-top: 50px;
}

.p-t-n-xxs {
  padding-top: -1px;
}

.p-r-none {
  padding-right: 0 !important;
}

.p-r-xxs {
  padding-right: 1px;
}

.p-r-xs {
  padding-right: 5px;
}

.p-r-sm {
  padding-right: 10px;
}

.p-r {
  padding-right: 15px;
}

.p-r-md {
  padding-right: 20px;
}

.p-r-lg {
  padding-right: 30px;
}

.p-r-xl {
  padding-right: 40px;
}

.p-r-xxl {
  padding-right: 50px;
}

.p-b-none {
  padding-bottom: 0 !important;
}

.p-b-xxs {
  padding-bottom: 1px;
}

.p-b-xs {
  padding-bottom: 5px;
}

.p-b-sm {
  padding-bottom: 10px;
}

.p-b {
  padding-bottom: 15px;
}

.p-b-md {
  padding-bottom: 20px;
}

.p-b-lg {
  padding-bottom: 30px;
}

.p-b-xl {
  padding-bottom: 40px;
}

.p-b-xxl {
  padding-bottom: 50px;
}

.avatar {
  position: relative;
  display: block;
  border-radius: 500px;
  white-space: nowrap;

  & img {
    border-radius: 500px;
    width: 100%;
  }

  & i {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    margin: 2px;
    border-width: 2px;
    border-style: solid;
    border-radius: 100%;

    &.right {
      left: auto;
      right: 0;
    }

    &.bottom {
      left: auto;
      top: auto;
      bottom: 0;
      right: 0;
    }

    &.left {
      top: auto;
      bottom: 0;
    }

    &.on {
      background: var(--success);
    }

    &.off {
      background: var(--text--muted-color);
    }

    &.busy {
      background: var(--danger);
    }

    &.away {
      background: var(--warning);
    }
  }

  &.thumb-md i {
    width: 12px;
    height: 12px;
    margin: 3px;
  }

  &.thumb-sm i {
    margin: 1px;
  }

  &.thumb-xs i {
    margin: 0;
  }
}

.w-1x {
  width: 1em;
}

.w-2x {
  width: 2em;
}

.w-3x {
  width: 3em;
}

.w-xxs {
  width: 60px;
}

.h-xxs {
  height: 60px;
}

.w-xs {
  width: 90px;
}

.h-xs {
  height: 90px;
}

.w-ssm {
  width: 120px;
}

.w-sm {
  width: 150px;
}

.h-sm {
  height: 150px;
}

.h-ssm {
  height: 120px;
}

.w {
  width: 200px;
}

.h {
  height: 200px;
}

.w-md {
  width: 240px;
}

.h-md {
  height: 240px;
}

.w-lg {
  width: 280px;
}

.h-lg {
  height: 280px;
}

.w-xl {
  width: 320px;
}

.h-xl {
  height: 320px;
}

.w-xxl {
  width: 360px;
}

.h-xxl {
  height: 360px;
}

.w-xxxl {
  width: 420px;
}

.h-xxxl {
  height: 420px;
}

.w-full {
  width: 100%;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.no-grow {
  flex-grow: unset !important;
  flex-basis: unset !important;
}

.nowrap {
  white-space: nowrap;
  flex-wrap: nowrap;
}

@include media-breakpoint-up(sm) {
  .#{$ns}Form-col {
    &.w,
    &.w-xs,
    &.w-sm,
    &.w-md,
    &.w-lg {
      flex-basis: unset;
      flex-grow: unset;
    }
  }
}

.thumb-xl {
  width: 128px;
  display: inline-block;
}

.thumb-lg {
  width: 96px;
  display: inline-block;
}

.thumb-md {
  width: 64px;
  display: inline-block;
}

.thumb {
  width: 50px;
  display: inline-block;
}

.thumb-sm {
  width: 40px;
  display: inline-block;
}

.thumb-xs {
  width: 34px;
  display: inline-block;
}

.thumb-xxs {
  width: 30px;
  display: inline-block;
}

.thumb-wrapper {
  padding: 2px;
  border: 1px solid var(--borderColor);
}

.thumb,
.thumb-xxs,
.thumb-xs,
.thumb-sm,
.thumb-md,
.thumb-lg,
.thumb-btn,
.thumb-xl {
  & img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }
}

.img-full {
  width: 100%;

  & img {
    width: 100%;
  }
}

.v-top {
  vertical-align: top !important;
  align-self: flex-start;
}

.v-middle {
  vertical-align: middle !important;
  align-self: center;
}

.v-bottom {
  vertical-align: bottom !important;
  align-self: flex-end;
}

.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.hover {
    &,
    & > .cell-inner {
      overflow-y: hidden !important;
    }

    &:hover,
    &:focus,
    &:active {
      overflow: visible;
      overflow-y: auto;

      & > .cell-inner {
        overflow-y: auto !important;
      }
    }
  }

  .smart & {
    &,
    & > .cell-inner {
      overflow-y: auto !important;
    }
  }
}

.scroll-x,
.scroll-y {
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.scroll-y {
  overflow-y: auto;
}

.scroll-x {
  overflow-x: auto;
}

.hover-action {
  display: none;
}

.hover-rotate {
  transition: all var(--animation-duration) ease-in-out
    var(--animation-duration);
}

.hover-anchor:hover,
.hover-anchor:focus,
.hover-anchor:active {
  & > .hover-action {
    display: inherit;
  }

  & > .hover-rotate {
    transform: rotate(90deg);
  }
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;

  &.fade {
    opacity: 0;
  }

  &.in {
    opacity: 0.8;
  }
}

.col-xs-2-4,
.col-sm-2-4,
.col-md-2-4,
.col-lg-2-4,
.col-xs-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

// 5/12 = 2.4
.col-xs-2-4 {
  width: 20%;
  float: left;
}

// 8 / 12 = 1.5
.col-xs-1-5 {
  width: 12.5%;
  float: left;
}

.invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.invisible2 {
  visibility: hidden;
}

.hbox {
  display: table;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  height: 100%;

  & > .col {
    display: table-cell;
    vertical-align: top;
    height: 100%;
    float: none;
  }
}

.vbox {
  display: table;
  border-spacing: 0;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 240px;

  & .row-row {
    display: table-row;
    height: 100%;

    & .cell {
      position: relative;
      height: 100%;
      width: 100%;

      .ie & {
        display: table-cell;
        overflow: auto;

        & .cell-inner {
          overflow: visible !important;
        }
      }

      & .cell-inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        -webkit-overflow-scrolling: touch;
        overflow: auto;
      }
    }
  }
}

.word-break {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}

.white-space-pre {
  white-space: pre;
}

//
// Labels
// --------------------------------------------------

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: var(--label-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;

  // Empty labels collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }

  // Quick fix for labels in buttons
  .btn & {
    position: relative;
    top: -1px;
  }
}
a.label {
  &:hover,
  &:focus {
    color: var(--label-link--hover-color);
    text-decoration: none;
    cursor: pointer;
  }
}

// Colors
// Contextual variations (linked labels get darker on :hover)

.label-default {
  @include label-variant(var(--label--default-bg));
}

.label-primary {
  @include label-variant(var(--label--primary-bg));
}

.label-success {
  @include label-variant(var(--label--success-bg));
}

.label-info {
  @include label-variant(var(--label--info-bg));
}

.label-warning {
  @include label-variant(var(--label--warning-bg));
}

.label-danger {
  @include label-variant(var(--label--danger-bg));
}

@keyframes apearSensor {
  from {
    opacity: 0;
  }
}

@media (min-width: 768px) {
  .col-sm-2-4 {
    width: 20%;
    float: left;
  }

  .col-sm-1-5 {
    width: 12.5%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-2-4 {
    width: 20%;
    float: left;
  }

  .col-md-1-5 {
    width: 12.5%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-2-4 {
    width: 20%;
    float: left;
  }

  .col-lg-1-5 {
    width: 12.5%;
    float: left;
  }
}

// sm
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm.show {
    display: inherit !important;
  }

  .no-m-sm {
    margin: 0 !important;
  }

  .no-padder-sm {
    padding: 0 !important;
  }
}

.visible-xs {
  display: none;
}

/*phone*/
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  .hidden-xs {
    display: none !important;
  }

  .w-auto-xs {
    width: auto;
  }

  .shift {
    display: none !important;
  }

  .shift.in {
    display: block !important;
  }

  .row-2 [class*='col'] {
    width: 50%;
    float: left;
  }

  .row-2 .col-0 {
    clear: none;
  }

  .row-2 li:nth-child(odd) {
    clear: left;
    margin-left: 0;
  }

  .text-center-xs {
    text-align: center;
  }

  .text-left-xs {
    text-align: left;
  }

  .text-right-xs {
    text-align: right;
  }

  .no-border-xs {
    border-width: 0;
  }

  .pull-none-xs {
    float: none !important;
  }

  .pull-right-xs {
    float: right !important;
  }

  .pull-left-xs {
    float: left !important;
  }

  .dropdown-menu.pull-none-xs {
    left: 0;
  }

  .hidden-xs.show {
    display: inherit !important;
  }

  .wrapper-lg,
  .wrapper-md {
    padding: 15px;
  }

  .padder-lg,
  .padder-md {
    padding-left: 15px;
    padding-right: 15px;
  }

  .no-m-xs {
    margin: 0 !important;
  }

  .no-padder-xs {
    padding: 0 !important;
  }
}
