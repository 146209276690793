.#{$ns}Transfer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: px2rem(300px);
  position: relative;

  &-searchbox {
    width: 100%;
  }

  &--inline {
    display: inline-flex;
    flex-wrap: nowrap;
  }

  &-title {
    display: flex;
    align-items: center;
    background: var(--Transfer-title-bg);
    height: px2rem(30px);
    line-height: var(--Form-input-lineHeight);
    font-size: var(--Form-input-fontSize);
    padding: calc(
        (
            #{px2rem(30px)} - var(--Form-input-lineHeight) * var(--Form-input-fontSize)
          ) / 2
      )
      var(--gap-sm);
    flex-direction: row;
    border-radius: var(--borderRadius) var(--borderRadius) 0 0;

    &--light {
      background: transparent;
    }

    > span {
      flex-grow: 1;
    }
  }

  &-select {
    overflow: hidden;
  }

  &-select,
  &-result {
    width: 0;
    min-width: px2rem(200px);
    max-height: px2rem(400px);
    flex-grow: 1;
    border: var(--Form-input-borderWidth) solid var(--Form-input-borderColor);
    display: flex;
    flex-direction: column;
    border-radius: var(--borderRadius);
  }

  &-select > &-selection,
  &-result > &-value {
    flex-grow: 1;
    max-height: 100%;
    overflow: auto;
    position: relative; // 貌似不加 relative 会有 bug。
  }

  &-select > .#{$ns}ChainedSelection {
    min-height: unset;
    overflow: hidden;
    
    .#{$ns}ChainedSelection-col {
      height: 100%;
      overflow: auto;
      min-width: unset;
      flex: 1 1 0;
    }
  }

  .#{$ns}-ResultTreeList {
    border-top: 1px solid var(--borderColor);
  }

  .#{$ns}AssociatedSelection {
    overflow: hidden;

    &-left,
    &-right {
      min-height: unset;
    }
  }

  &-select {
    overflow: hidden;
  }

  &-search + &-selection {
    border-top: 1px solid var(--borderColor);
  }

  &-selection .#{$ns}ListSelection-placeholder {
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  &-search {
    padding: var(--gap-sm);
  }

  &-mid {
    min-width: px2rem(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-arrow {
    width: 40px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--borderColor);
    margin: 0 10px;
    color: var(--icon-color);

    > svg {
      top: 0;
      width: 14px;
      height: 14px;
    }
  }

  &-checkAll,
  &-clearAll {
    user-select: none;
    cursor: pointer;

    &.is-disabled {
      pointer-events: none;
      color: var(--text--muted-color);
    }
  }

  .#{$ns}Tree {
    padding: px2rem(2px) px2rem(10px);

    &-itemLabel:hover::after {
      left: 0;
    }
  }
}

.#{$ns}TabsTransfer {
  .#{$ns}Transfer-title {
    height: 40px;
  }
  &-tabs {
    .#{$ns}Tabs-linksContainer {
      padding-left: px2rem(20px);
      padding-top: px2rem(8px);
      background-color: var(--TabsTransfer-title-bg);
    }
  }

  &-search {
    margin: var(--gap-sm) var(--gap-sm);
    .#{$ns}InputBox {
      border: 1px solid var(--TabsTransfer-border-color);
    }
  }

  .#{$ns}Transfer-result {
    .#{$ns}Transfer-title {
      height: px2rem(40px);
      line-height: px2rem(40px);
    }
  }

  // .#{$ns}Transfer-result {
  //   flex-grow: unset;
  // }

  &-placeholder {
    @include checkboxes-placeholder();
  }

  &-tab {
    padding: 0;
    overflow: auto;
  }

  &-tabs {
    display: flex;
    flex-direction: column;
    height: 100%;

    > .#{$ns}Tabs-links {
      border-top: 0 none;
      padding: 5px 0 0 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      > .#{$ns}Tabs-link > a:first-child {
        font-size: 12px;
        padding: 7px 8px;
      }

      .#{$ns}TabsTransfer-tabsMid {
        flex-grow: 1;
      }

      > .#{$ns}SearchBox {
        margin: -5px 5px 0 10px;

        &.is-active {
          width: 150px;
          margin-right: 10px;
          padding-left: 10px;
        }
      }
    }

    > .#{$ns}Tabs-content {
      flex-grow: 1;
      min-height: 0;
      position: relative;
      padding: 5px 0 0;

      > .#{$ns}Tabs-pane {
        position: relative;
        height: 100%;

        &.is-active {
          display: flex;
          flex-direction: column;

          > .#{$ns}Transfer-selection {
            flex-grow: 1;
            max-height: 100%;
            overflow: auto;
          }
        }
      }
    }
  }
}

.#{$ns}TransferControl {
  position: relative;
  overflow: hidden;

  &.is-inline {
    display: inline-block;
  }
}

.#{$ns}TransferPicker {
  &-icon {
    transition: transform var(--animation-duration) ease-out;
    margin: 5px 5px 5px auto;
    display: flex;
    color: var(--Form-select-caret-iconColor);
    &:hover {
      color: var(--Form-select-caret-onHover-iconColor);
    }

    > svg {
      width: px2rem(12px);
      height: px2rem(12px);
      top: 0;
    }
  }
}

.#{$ns}TransferDropDown {
  &:hover {
    border: px2rem(1px) solid ver(--menu-active-color);
  }
  &-icon {
    transform: rotate(90deg);
    transition: transform var(--animation-duration) ease-out;
    margin: px2rem(5px) 0 px2rem(5px) auto;
    display: flex;
    color: var(--Form-select-caret-iconColor);
    &:hover {
      color: var(--Form-select-caret-onHover-iconColor);
    }

    > svg {
      width: px2rem(10px);
      height: px2rem(10px);
      top: 0;
    }
  }

  &.is-active &-icon {
    transform: rotate(-90deg);
  }
}
.#{$ns}TransferDropDown-content {
  min-height: px2rem(200px);
  min-width: px2rem(400px);
  display: flex;
  flex-direction: column;
  padding: var(--gap-xs) 0;

  &.is-mobile {
    width: 100%;
  }
  & > .#{$ns}Transfer-selection {
    flex-grow: 1;
    max-height: var(--Transfer-selection-maxHeight);
    overflow-x: hidden;
    overflow-y: auto;
    position: relative; // 貌似不加 relative 会有 bug。
  }
}
