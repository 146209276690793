.#{$ns}List {
  position: relative;

  &-items {
    border-radius: var(--List-borderRadius);
    border: var(--List-borderWidth) solid var(--List-borderColor);
    background: var(--List-bg);
    margin-bottom: var(--gap-sm);
  }

  &-toolbar {
    @include clearfix();
    display: flex;
    flex-wrap: wrap;
    margin: 0 var(--List-toolbar-marginX) var(--List-toolbar-marginY);
  }

  &-actions {
    display: inline-block;

    > * {
      margin-right: var(--Crud-toolbar-gap);
    }
  }

  &-header {
    padding: var(--List-toolbar-marginY) var(--List-toolbar-marginX);

    > * + .#{$ns}Button,
    > * + .#{$ns}ButtonGroup,
    > * + .#{$ns}ButtonToolbar {
      margin-left: var(--Crud-toolbar-gap);
    }
  }

  &-header + &-toolbar {
    padding-top: 0;
  }

  &-heading {
    padding: var(--gap-xs) 0;
  }

  &-fixedTop {
    position: absolute;
    background: var(--white);
    z-index: -1;
    opacity: 0;
    box-shadow: var(--List-fixedTop-boxShadow);
    // padding: var(--gap-sm);

    &.in {
      position: fixed;
      opacity: 1;
      z-index: $zindex-affix;
    }

    .#{$ns}Cards-toolbar {
      margin-bottom: 0;
    }

    &:empty {
      display: none;
    }
  }

  &--unsaved &-heading {
    background: var(--List--unsaved-heading-bg);
    color: var(--List--unsaved-heading-color);
    padding: var(--gap-xs) var(--gap-sm);
    margin-bottom: var(--gap-sm);
  }

  &-dragTip {
    width: 100%;
    color: var(--info);
    clear: both;
    margin-top: var(--gap-xs);
  }

  &-placeholder {
    color: var(--text--muted-color);
    text-align: center;
    min-height: var(--List-placeholder-height);
    line-height: var(--List-placeholder-height);
  }
}

.#{$ns}ListGroup {
  max-width: px2rem(400px);
  display: flex;
  flex-direction: column;

  &-item {
    position: relative;
    display: block;
    padding: var(--ListItem-paddingY) var(--ListItem-paddingX);
    margin-bottom: px2rem(-1px);
    background: var(--white);
    border: var(--ListItem-borderWidth) solid var(--List-borderColor);

    &:first-child {
      border-top-left-radius: var(--borderRadius);
      border-top-right-radius: var(--borderRadius);
    }

    &:last-child {
      border-bottom-left-radius: var(--borderRadius);
      border-bottom-right-radius: var(--borderRadius);
      margin-bottom: 0;
    }

    &:hover {
      // todo
      z-index: 1;
    }

    &.is-active {
      // todo
      z-index: 2;
    }

    &.is-disabled {
      color: var(--text--muted-color);
    }
  }

  &--expanded {
    .#{$ns}ListGroup-item {
      border-radius: var(--borderRadius);

      margin-bottom: var(--gap-sm);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.#{$ns}ListItem {
  @include clearfix();

  & + & {
    border-top: var(--ListItem-borderWidth) solid var(--ListItem-borderColor);
  }

  position: relative;
  display: block;
  padding: var(--ListItem-paddingY) var(--ListItem-paddingX);

  &:nth-child(even) {
    background: var(--ListItem--strip-bg);
  }

  &-checkBtn {
    float: left;
    margin-right: var(--gap-sm);
  }

  &-dragBtn {
    cursor: move;
    float: left;
    margin-right: var(--gap-sm);
  }

  &-actions {
    float: right;
  }

  &--actions-at-left &-actions {
    float: left;
    margin-right: var(--gap-base);
  }

  &-title {
    margin: 0;
    padding: 0;
    font-size: var(--fontSizeBase);
    color: var(--text--loud-color);
    font-weight: var(--fontWeightNormal);
  }

  &-content {
    overflow: hidden;
  }

  &-field {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
  }

  &-fieldValue {
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-fieldLabel {
    width: px2rem(150px);
    color: var(--text--muted-color);
  }

  &.is-checked {
    background: var(--ListItem-onChecked-bg);
    border-color: var(--ListItem-onChecked-borderColor);
    color: var(--ListItem-onChecked-color);

    + .#{$ns}ListItem {
      border-color: var(--ListItem-onChecked-borderColor);
    }

    .#{$ns}ListItem-fieldLabel {
      color: var(--ListItem-onChecked-fieldLabel-color);
    }
  }

  &.is-modified,
  &.is-moved {
    background: var(--ListItem-onModified-bg);
    border-color: var(--ListItem-onModified-borderColor);
    color: var(--ListItem-onModified-color);

    + .#{$ns}ListItem {
      border-color: var(--ListItem-onModified-borderColor);
    }

    .#{$ns}ListItem-fieldLabel {
      color: var(--ListItem-onModified-fieldLabel-color);
    }
  }

  &.is-dragging {
    opacity: var(--ListItem-onDragging-opacity);
  }

  &--hasItemAction {
    cursor: pointer;
  }
  &--hasItemAction:hover {
    color: var(--ListMenu-item--onHover-color);
    background: var(--ListMenu-item--onHover-bg);
  }
}
