.#{$ns}Selection {
  > .#{$ns}Checkbox {
    display: block;
    // height: var(--Form-input-height);
    line-height: var(--Form-input-lineHeight);
    font-size: var(--Form-input-fontSize);
    padding: calc(
        (
            var(--Form-input-height) - var(--Form-input-lineHeight) *
              var(--Form-input-fontSize)
          ) / 2
      )
      var(--gap-sm)
      calc(
        (
            var(--Form-input-height) - var(--Form-input-lineHeight) *
              var(--Form-input-fontSize)
          ) / 2
      )
      calc(var(--gap-sm) + var(--Checkbox-size));
  }

  &--inline > .#{$ns}Checkbox {
    display: inline-block;
  }

  &-addBtn {
    display: block;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    > svg {
      width: px2rem(14px);
      height: px2rem(14px);
      margin-right: var(--Checkbox-gap);
    }
  }
}

.#{$ns}GroupedSelection {
  max-height: px2rem(300px);
  overflow: auto;
  user-select: none;
  padding: var(--gap-xs) 0;

  &-group > &-itemLabel {
    padding: var(--gap-xs) var(--gap-base);
    color: var(--FileControl-icon-color);
  }

  &-item {
    display: flex;
    // height: var(--Form-input-height);
    line-height: var(--Form-input-lineHeight);
    font-size: var(--Form-input-fontSize);
    padding: calc(
        (
            var(--Form-input-height) - var(--Form-input-lineHeight) *
              var(--Form-input-fontSize)
          ) / 2
      )
      var(--gap-sm);
    flex-direction: row;

    > .#{$ns}Checkbox {
      margin-right: px2rem(10px);
    }
    cursor: pointer;
    user-select: none;

    &.is-active {
      background: var(--Form-select-menu-onActive-bg);
    }

    &:hover {
      background: var(--Tree-item-onHover-bg);

      i {
        border: 1px solid var(--menu-active-color);
      }
    }

    &.is-disabled {
      pointer-events: none;
      color: var(--text--muted-color);
    }
  }

  &-group > &-items > &-item {
    padding-left: calc(var(--gap-base) * 2);
  }

  &-itemLabel {
    flex-grow: 1;

    span {
      vertical-align: middle;
      white-space: nowrap; // 避免在条件组合中折行
    }
  }

  &-placeholder {
    @include checkboxes-placeholder();
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.#{$ns}TableSelection {
  .#{$ns}Table-content {
    border-top: var(--Table-borderWidth) solid var(--Table-borderColor);
  }

  .#{$ns}Table-table > tbody > tr {
    cursor: pointer;
  }

  .#{$ns}Table-table > thead > tr > th,
  .#{$ns}Table-table > tbody > tr > td {
    font-size: var(--fontSizeSm);
    padding-top: var(--gap-xs);
    padding-bottom: px2rem(6px);
    vertical-align: middle;
  }

  .#{$ns}Table-table > thead > tr > th {
    padding-top: px2rem(6px);
  }

  .#{$ns}Table-table > thead > tr > th:first-child,
  .#{$ns}Table-table > tbody > tr > td:first-child {
    padding-left: px2rem(10px);
    padding-right: 0;
  }

  .#{$ns}Table-table > thead > tr > th:last-child,
  .#{$ns}Table-table > tbody > tr > td:last-child {
    padding-right: var(--gap-md);
  }

  .#{$ns}Table-table > tbody > tr.is-active {
    color: var(--Form-select-menu-onActive-color);
    background: var(--Form-select-menu-onActive-bg);
  }
}

.#{$ns}TreeSelection {
  .#{$ns}Table-expandBtn {
    color: var(--icon-color);
    margin-right: var(--gap-xs);
  }

  &-sublist {
    position: relative;
    margin: 0 0 0 px2rem(35px);
    display: none;
  }

  &-item {
    position: relative;
  }
  &-item.is-expanded > &-sublist {
    display: block;
  }

  &-item:not(:last-child) > &-sublist:before {
    bottom: 0;
  }

  &-item.is-disabled {
    color: var(--text--muted-color);
  }

  &-itemInner {
    display: flex;
    align-items: center;
    line-height: var(--Tree-itemHeight);
    position: relative;
    font-size: var(--Form-input-fontSize);
    padding: 0 var(--gap-sm);
    flex-direction: row;

    > .#{$ns}Checkbox {
      margin-right: var(--gap-sm);
    }
    cursor: pointer;
    user-select: none;

    &:hover {
      background: var(--Tree-item-onHover-bg);
    }

    &.is-active {
      color: var(--Form-select-menu-onActive-color);
    }

    &.is-disabled {
      pointer-events: none;
      color: var(--text--muted-color);
    }

    .#{$ns}TreeSelection-itemLabel {
      margin-left: var(--gap-xs);
    }
  }

  &-itemLabel {
    flex-grow: 1;

    span {
      vertical-align: middle;
    }
  }

  &-placeholder {
    @include checkboxes-placeholder();
  }
}

.#{$ns}ChainedSelection {
  display: flex;
  flex-direction: row;
  min-height: 100%;

  &-col {
    flex-grow: 1;
    min-width: 150px;
  }

  &-col:not(:last-child) {
    border-right: 1px solid var(--borderColor);
  }

  &-subTitle {
    font-size: var(--fontSizeSm);
    padding: var(--gap-xs) var(--gap-xs);
    color: var(--text--muted-color);
  }

  &-item {
    display: flex;
    // height: var(--Form-input-height);
    line-height: var(--Form-input-lineHeight);
    font-size: var(--Form-input-fontSize);
    padding: calc(
        (
            var(--Form-input-height) - var(--Form-input-lineHeight) *
              var(--Form-input-fontSize)
          ) / 2
      )
      var(--gap-sm);
    flex-direction: row;

    > .#{$ns}Checkbox {
      margin-right: 0;
    }
    cursor: pointer;
    user-select: none;

    &.is-active {
      color: var(--Form-select-menu-onActive-color);
      background: var(--Form-select-menu-onActive-bg);
    }

    &:hover {
      background: var(--Tree-item-onHover-bg);
    }

    &.is-disabled {
      pointer-events: none;
      color: var(--text--muted-color);
    }
  }

  &-itemLabel {
    flex-grow: 1;

    span {
      margin-left: px2rem(10px);
      vertical-align: middle;
    }
  }

  &-placeholder {
    @include checkboxes-placeholder();
  }
}

.#{$ns}AssociatedSelection {
  display: flex;
  flex-direction: row;

  &-left,
  &-right {
    flex-grow: 1;
    width: 0;
    min-height: px2rem(200px);
    max-height: px2rem(400px);
    overflow: auto;
  }

  &-left {
    border-right: 1px solid var(--borderColor);
  }

  &-reload {
    text-align: center;
    color: var(--info);
    margin: 20px 0 0;

    &.is-clickable {
      cursor: pointer;
    }
  }

  &-box {
    line-height: var(--Form-input-lineHeight);
    font-size: var(--fontSizeSm);
    color: var(--text--muted-color);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    > p {
      text-align: center;
      margin: 10px 0 20px;
      color: var(--text--muted-color);
    }
  }
}

.#{$ns}ResultTreeList {
  height: 100%;
  width: 100%;
}

.#{$ns}ResultTableList {
  height: 100%;
  width: 100%;

  &-close-btn {
    float: right;
    cursor: pointer;
  }
}
