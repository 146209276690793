.#{$ns}FileControl {
  &-templateInfo {
    display: block;
    margin-bottom: var(--gap-base);
    padding: var(--Button-paddingY) var(--Button-paddingX);
    cursor: pointer;

    > svg {
      margin-right: var(--gap-xs);
    }
  }

  &-dropzone {
    outline: none;
  }

  &-selectBtn {
    display: inline-flex;
    margin-right: 10px;

    > svg {
      margin-right: 4px !important;
      width: px2rem(16px);
      height: px2rem(16px);
    }

    > span {
      font-size: var(--fontSizeSm);
      line-height: 20px;
    }
  }

  // &-dropzone:focus {
  //     .#{$ns}FileControl-selectBtn {
  //         background: var(--Button--default-onHover-bg);
  //         border-color: var(--Button--default-onHover-border);
  //         color: var(--Button--default-onHover-color);
  //     }

  //     &:after {
  //         content: '当前状态接受从剪切板中粘贴文件。';
  //         color: var(--text--muted-color);
  //         font-size: 11px;
  //         margin-top: 10px;
  //     }
  // }

  &-description {
    color: #999;
    font-size: 12px;
  }

  &-sizeTip {
    margin-top: 4px;
    line-height: 20px;
    font-size: var(--fontSizeSm);
    color: var(--FileControl-icon-color);
  }

  &-list {
    list-style: none;
    margin: 8px 0;
    padding: 0;
    max-height: 144px;
    overflow-x: hidden;
    overflow-y: auto;

    > li {
      margin-bottom: 4px;
      font-size: 12px;

      &:hover {
        border-radius: 2px;
        background: var(--FileControl-onHover-bg);
      }
    }

    &-tooltip span {
      font-size: var(--fontSizeSm);
      color: var(--FileControl-danger-color);
    }
  }

  &-itemInfo {
    padding: 2px 4px;
    display: flex;
    line-height: 24px;
    height: auto;

    span {
      word-break: break-all;
    }

    &.is-invalid {
      color: var(--FileControl-danger-color);
    }

    > svg:not(:first-child) {
      margin-left: 10px;
      width: px2rem(16px);
      height: px2rem(16px);
      top: var(--gap-xs);
    }
  }

  &-itemInfoIcon {
    margin-right: 4px;
  }

  &-itemInfoText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    vertical-align: middle;
  }

  &-clear {
    color: var(--FileControl-icon-color);
    display: none;
    cursor: pointer;
    margin-left: auto;
    margin-right: var(--gap-xs);
    &:hover {
      color: var(--FileControl-icon-onHover-color);
    }
  }

  &-list:empty {
    display: none;
  }

  &-list > li:hover &-clear {
    display: block;
  }

  &-progressInfo {
    display: inline-flex;
    height: 20px;
    padding: 0 6px;
    transform: translateY(-3px);
    width: 100%;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;

    > span {
      display: inline-block;
      padding: 0 4px 0 10px;
      font-size: 12px;
    }

    > svg {
      display: inline-block;
      margin: 0 4px 0 10px;
      width: 14px;
      height: 14px;
      top: 0;
    }
  }

  &-progress {
    height: 5px;
    flex: 1;
    background: #ebebeb;

    > span {
      display: block;
      background: var(--info);
      border-radius: var(--FileControl-progress-borderRadius);
      height: 100%;
      transition: ease-out width var(--animation-duration);
    }
  }

  &-dropzone.disabled > &-acceptTip {
    filter: grayscale(100%);
    box-shadow: none;
    cursor: not-allowed;
    color: var(--FileControl-onDisabled-color);
    pointer-events: auto;
    background: var(--FileControl-onDisabled-bg);

    &:hover {
      border-color: var(--FileControl-border-color);
    }

    > div,
    > span {
      color: var(--FileControl-onDisabled-color);
    }
  }

  &-acceptTip {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    height: 180px;
    text-align: center;
    border: 1px dashed var(--FileControl-border-color);
    border-radius: var(--borderRadius);
    background: var(--white);
    cursor: pointer;

    &-click {
      color: var(--Spinner-color);
    }

    &-help {
      a {
        color: var(--FileControl-icon-color);
        font-size: var(--fontSizeSm);
      }
    }

    &:hover {
      border-color: var(--info);
    }

    > span {
      margin-top: 8px;
      line-height: 20px;
      font-size: var(--fontSizeSm);
      color: var(--FileControl-drag-color);
    }

    > svg {
      width: 48px;
      height: 48px;
      top: 0;
    }
  }

  &-dropzone.is-active &-acceptTip {
    border-color: var(--info);
  }

  &-sum {
    font-size: var(--fontSizeSm);

    > a {
      cursor: pointer;
    }
  }
}
