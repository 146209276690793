@keyframes PopUpIn {
  from {
    height: 0;
  }
}

@keyframes PopUpOut {
  to {
    height: 0;
  }
}

@keyframes PopUpOpacityIn {
  from {
    opacity: 0;
  }
}

@keyframes PopUpOpacityOut {
  to {
    opacity: 0;
  }
}
.#{$ns}PopUp {
  width: 100%;
  height: px2rem(400px);
  position: fixed;
  background: var(--PopOver-bg);
  left: 0;
  bottom: 0;
  z-index: $zindex-top;
  padding: 0;
  margin: 0;
  font-weight: var(--fontWeightNormal);
  letter-spacing: normal;
  line-height: var(--lineHeightBase);
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: var(--fontSizeBase);
  box-shadow: var(--boxShadow);
  border: var(--borderWidth) solid var(--borderColor);
  border-radius: var(--borderRadius);
  overflow: hidden;
  &.in,
  &.out {
    animation-duration: var(--animation-duration);
    animation-fill-mode: both;
  }

  &.in {
    animation-name: PopUpIn;
    .#{$ns}PopUp-overlay {
      animation-name: PopUpOpacityIn;
    }
  }

  &.out {
    animation-name: PopUpOut;
    .#{$ns}PopUp-overlay {
      animation-name: PopUpOpacityOut;
    }
  }

  &-inner {
    position: relative;
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;
    background: $white;
    display: flex;
    flex-direction: column;
  }

  &-closeBox {
    width: px2rem(48px);
    height: px2rem(48px);
    position: absolute;
    z-index: 1;
    cursor: pointer;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-closeWrap {
    position: relative;
    text-align: center;
    height: px2rem(48px);
    line-height: px2rem(48px);
  }

  &-closeWrap &-close {
    z-index: 1;
    color: var(--icon-color);
    cursor: pointer;
  }

  &-toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: px2rem(60px);
  }
  &-title {
    font-size: var(--fontSizeMd);
  }

  &-cancel {
    color: var(--PopUp-cancelAction-color);
    margin-left: var(--gap-sm);
  }

  &-confirm {
    margin-right: var(--gap-sm);
  }

  &-content {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex: 1;
  }

  & > * {
    position: relative;
    z-index: 2;
  }

  &-overlay {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    animation-duration: var(--animation-duration);
    animation-fill-mode: both;
  }

  &--leftBottomLeftTop {
    margin-top: px2rem(4px);
  }
  &--leftTopLeftBottom {
    margin-top: px2rem(-4px);
  }
  &-safearea {
    height: px2rem(16px);
  }
}
