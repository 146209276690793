.#{$ns}ListControl {
  &-items {
    display: block;
    margin: calc(var(--ListControl-gutterWidth) / -2);

    &:empty {
      display: none;
    }
  }

  &-item {
    position: relative;
    user-select: none;
    font-size: var(--ListControl-fontSize);
    display: inline-block;
    vertical-align: middle;
    margin: calc(var(--ListControl-gutterWidth) / 2);
    border: var(--ListControl-item-borderWidth) solid
      var(--ListControl-item-borderColor);
    background: var(--ListControl-item-bg);
    padding: var(--ListControl-item-paddingY) var(--ListControl-item-paddingX);
    color: var(--ListControl-item-color);
    transition: var(--ListControl-item-transition);
    max-width: calc(#{px2rem(200px)} + 2 * var(--ListControl-item-paddingX));
    border-radius: var(--ListControl-item-borderRadius);

    &:not(.is-disabled) {
      cursor: pointer;
    }

    .b-inherit {
      border-color: var(--ListControl-item-color);
    }

    @include hover {
      background: var(--ListControl-item-onHover-bg);
      border-color: var(--ListControl-item-onHover-borderColor);
      color: var(--ListControl-item-onHover-color);

      .b-inherit {
        border-color: var(--ListControl-item-onHover-borderColor);
      }
    }

    &:hover:active,
    &.is-active {
      background: var(--ListControl-item-onActive-bg);
      border-color: var(--ListControl-item-onActive-borderColor);
      color: var(--ListControl-item-onActive-color);

      &:hover {
        background: var(--ListControl-item-onActive-onHover-bg);
      }

      .b-inherit {
        border-color: var(--ListControl-item-onActive-color);
      }

      &:before {
        content: '';
        position: absolute;
        width: px2rem(14px);
        height: px2rem(14px);
        background: var(--ListControl-item-onActive-before-bg);
        right: 0;
        bottom: 0;
      }

      &:after {
        content: '';
        position: absolute;
        width: px2rem(10px);
        height: var(--gap-xs);
        border-color: var(--ListControl-item-onActive-after-borderColor);
        border-style: solid;
        border-width: 0 0 px2rem(2px) px2rem(2px);
        right: px2rem(1px);
        bottom: var(--gap-xs);
        transform: rotate(-40deg);
      }
    }

    &.is-disabled {
      pointer-events: none;
      opacity: var(--ListControl-item-onDisabled-opacity);
      border-color: var(--ListControl-item-onDisabled-borderColor);

      &:before {
        background: var(--ListControl-item-onDisabled-color);
      }

      background: var(--ListControl-item-onDisabled-bg);
      color: var(--ListControl-item-onDisabled-color);

      .b-inherit {
        border-color: var(--ListControl-item-onDisabled-borderColor);
      }
    }
  }

  &-itemImage {
    margin: calc(var(--ListControl-item-paddingY) * -1)
      calc(var(--ListControl-item-paddingX) * -1);

    img {
      display: block;
      max-width: 100%;
    }
  }

  &-itemLabel {
    text-align: center;
  }

  &-itemImage + &-itemLabel {
    margin-top: var(--ListControl-item-paddingY);
  }

  &-placeholder {
    color: var(--Form-input-placeholderColor);
  }
}
