.#{$ns}Switch {
  cursor: pointer;
  position: relative;
  display: inline-block;
  height: var(--Switch-height);
  min-width: var(--Switch-width);
  overflow: hidden;
  border-radius: px2rem(30px);
  background: var(--Switch-bgColor);
  margin: 0;
  vertical-align: middle;
  text-align: left;
  transition: all 0.5s ease;

  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .text {
    margin: 0 px2rem(7px) 0 px2rem(25px);
    color: var(--Switch-valueColor);
    text-indent: var(--gap-xs);
    text-transform: uppercase;
    font-size: var(--fontSizeSm);
    line-height: var(--Switch-height);
    font-style: normal;
    font-weight: bold;
    transition: all var(--animation-duration);

    > svg {
      width: var(--fontSizeSm);
      height: var(--fontSizeSm);
      margin-top: calc((var(--Switch-height) - var(--fontSizeSm)) / 2);
    }
  }

  .slider {
    &:before {
      content: '';
      position: absolute;
      background: var(--white);
      width: calc(var(--Switch-height) - #{px2rem(2px)});
      top: px2rem(1px);
      bottom: px2rem(1px);
      left: px2rem(1px);
      border-radius: 50%;
      transition: margin-left var(--animation-duration);
    }
  }

  &.is-checked {
    background: var(--Switch-onActive-bgColor);

    .slider::before {
      left: auto;
      right: px2rem(1px);
      border-width: 0;
    }

    .text {
      color: var(--white);
      margin: 0 px2rem(25px) 0 px2rem(7px);
    }
  }

  &.is-checked.is-disabled {
    .slider:before {
      color: var(--Switch-onDisabled-color);
    }

    .text {
      color: var(--Switch-onDisabled-color);
    }
  }

  input {
    position: absolute;
    opacity: 0;
  }
}

.#{$ns}Switch-option {
  vertical-align: middle;
  margin-left: var(--Switch-gap);

  &:first-child {
    margin-left: 0;
    margin-right: var(--Switch-gap);
  }

  &:empty {
    display: none;
  }
}

.#{$ns}SwitchControl {
  padding-top: calc((var(--Form-input-height) - var(--Switch-height)) / 2);

  &.is-inline {
    display: inline-block;
  }
}
