html,
body {
  width: 100%;
  height: 100%;
}

body {
  overflow-x: hidden;
}

.#{$ns}Layout {
  min-height: 100%;
  width: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: inherit;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: var(--body-bg);
    border: inherit;
    display: block;
  }

  &--boxed {
    margin-right: auto;
    margin-left: auto;
    // box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  }

  .#{$ns}Layout-header {
    background: var(--Layout-header-bg);
    box-shadow: var(--Layout-header-boxShadow);
    height: var(--Layout-header-height);
  }

  &--headerFixed {
    padding-top: var(--Layout-header-height);

    .#{$ns}Layout-header {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: $zindex-fixed;
    }
  }

  &-brandBar {
    background: var(--Layout-brand-bg);
    color: var(--Layout-brandBar-color);

    > button {
      padding: px2rem(10px) px2rem(17px);
      font-size: px2rem(16px);
      line-height: calc(var(--Layout-header-height) - var(--gap-md));
      text-decoration: none;
      background: transparent;
      border: none;
    }
  }

  &-brand {
    color: var(--Layout-brand-color);
    text-align: left;
    font-size: var(--fontSizeMd);
    font-weight: var(--fontWeightNormal);
    max-width: 100%;
    height: auto;
    line-height: var(--Layout-header-height);
    display: inline-block;
    vertical-align: middle;
    padding: 0 var(--gap-base);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: none;
    }

    img {
      max-height: calc(var(--Layout-header-height) / 2);
      // margin-top: px2rem(-4px);
      vertical-align: middle;
      display: inline;
    }
  }

  &-headerBar {
    border-bottom: var(--Layout-headerBar-borderBottom);
    min-height: var(--Layout-header-height);
    padding: 0 var(--gap-sm);
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-offScreenBtn {
    display: none;
    float: right;
    padding: 13px var(--gap-md);
  }

  &-aside {
    position: relative;
    background: var(--Layout-aside-bg);
    color: var(--Layout-aside-color);

    &:before {
      content: '';
      position: absolute;
      width: inherit;
      top: 0;
      bottom: 0;
      z-index: -1;
      background: inherit;
      border: inherit;
    }
  }

  &-asideFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: $zindex-fixed;
    max-width: var(--Layout-aside-width);

    // .app-aside-folded & {
    //     max-width: @app-aside-folded-width;
    // }
    ~ div {
      padding-bottom: px2rem(50px);
    }
  }

  // &-content--full {
  //     position: absolute;
  //     top: var(--Layout-header-height);
  //     bottom: var(--Layout-header-height);
  //     height: auto;
  //     width: auto !important;
  //     padding: 0 !important;
  //     overflow-y: auto;
  //     -webkit-overflow-scrolling: touch;

  //     &.h-full {
  //         bottom: 0;
  //         height: auto;
  //     }
  // }

  &-body {
    padding-bottom: var(--Layout-footer-height);
    width: 100%;
    height: 100%;
  }

  &--noFooter &-body {
    padding-bottom: 0;
  }

  &-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // z-index: 1005; 为啥要这么高
    z-index: 0;
  }

  &--sm {
    .#{$ns}Layout-asideFooter {
      max-width: var(--Layout-aside--sm-width);
    }

    .#{$ns}AsideNav-item a {
      font-size: var(--fontSizeSm);
    }
  }

  &--md .#{$ns}Layout-asideFooter {
    max-width: var(--Layout-aside--md-width);
  }

  &--lg .#{$ns}Layout-asideFooter {
    max-width: var(--Layout-aside--lg-width);
  }
}

@include media-breakpoint-down(sm) {
  // .#{$ns}Layout {
  // Layout 层没有出现滚动条件，导致移动端无法响应固定顶部/底部效果
  // overflow-x: hidden;
  // }

  .#{$ns}Layout-content {
    transition: transform var(--animation-duration) ease;
  }

  .#{$ns}Layout-aside {
    display: none;

    .#{$ns}Layout--offScreen & {
      position: fixed;
      top: px2rem(50px);
      bottom: 0;
      width: var(--Layout--offscreen-width);
      display: block !important;
      visibility: visible;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      z-index: 1010;
    }
  }

  .#{$ns}Layout-headerBar {
    display: none;
  }

  .#{$ns}Layout--offScreen {
    .#{$ns}Layout-body,
    .#{$ns}Layout-footer {
      background: var(--body-bg);
      transition: transform var(--animation-duration) ease;
      backface-visibility: hidden;
      transform: translate3d(var(--Layout--offscreen-width), 0px, 0px);
      overflow: hidden;
      position: fixed;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1015;
      padding-top: px2rem(50px);
    }
  }

  .#{$ns}Layout-offScreenBtn {
    display: block;
  }
}

@include media-breakpoint-up(md) {
  .#{$ns}Layout {
    display: flex;
    flex-direction: column;

    &-header {
      width: 100%;
    }

    > .#{$ns}Layout-body {
      flex-grow: 1;
      position: relative;
      // height: 0;
    }

    &--boxed {
      width: px2rem(760px);

      &.#{$ns}Layout--headerFixed .#{$ns}Layout-header {
        width: px2rem(760px);
      }
    }

    &-brand,
    &-brandBar,
    &-aside {
      width: var(--Layout-aside-width);
      transition: width var(--animation-duration) ease;
    }

    &-brandBar {
      float: left;
    }

    &--withAside {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: stretch;

      > .#{$ns}Layout-content {
        flex-grow: 1;
        width: 0;
        position: relative;
      }

      .#{$ns}Layout-headerBar,
      .#{$ns}Layout-footer {
        margin-left: var(--Layout-aside-width);
        transition: margin var(--animation-duration) ease;
      }
    }

    .visible-folded {
      display: none;
    }

    &--folded {
      .visible-folded {
        display: inherit;
      }

      .hidden-folded {
        display: none !important;
      }

      .text-center-folded {
        text-align: center;
      }

      .pull-none-folded {
        float: none !important;
      }

      .w-auto-folded {
        width: auto;
      }

      .#{$ns}Layout-aside,
      .#{$ns}Layout-brandBar {
        width: var(--Layout-aside--folded-width);
      }

      &.#{$ns}Layout--withAside {
        .#{$ns}Layout-headerBar,
        .#{$ns}Layout-footer {
          margin-left: var(--Layout-aside--folded-width);
        }
      }

      .#{$ns}Layout-brand {
        display: block;
        padding: 0;
        font-size: var(--fontSizeLg);
        text-align: center;
      }
    }

    &--asideFixed {
      .#{$ns}Layout-aside {
        height: 100%;

        &:before {
          position: fixed;
          z-index: 15;
        }
      }

      .#{$ns}Layout-brandBar {
        position: fixed;
      }

      .#{$ns}Layout-asideWrap {
        position: fixed;
        overflow: hidden;
        top: var(--Layout-header-height);
        bottom: 0;
        left: 0;
        width: inherit;
        z-index: $zindex-fixed;
      }

      .#{$ns}Layout-asideInner {
        width: calc(100% + 17px);

        position: relative;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
        }

        &::-webkit-scrollbar:vertical {
          width: 17px;
        }
      }
    }

    &--asideFixed.#{$ns}Layout--folded {
      .#{$ns}Layout-aside {
        position: static;
      }

      .#{$ns}Layout-brandBar {
        position: static;
      }

      .#{$ns}Layout-asideWrap {
        overflow: visible;
        // position: relative;
        // top: 0;
        z-index: 15;
      }

      .#{$ns}Layout-asideInner {
        overflow: visible;
        padding-right: 17px;
      }
    }

    &--sm:not(.#{$ns}Layout--folded) {
      .#{$ns}Layout-brand,
      .#{$ns}Layout-brandBar,
      .#{$ns}Layout-aside {
        width: var(--Layout-aside--sm-width);
      }
    }

    &--md:not(.#{$ns}Layout--folded) {
      .#{$ns}Layout-brand,
      .#{$ns}Layout-brandBar,
      .#{$ns}Layout-aside {
        width: var(--Layout-aside--md-width);
      }
    }

    &--lg:not(.#{$ns}Layout--folded) {
      .#{$ns}Layout-brand,
      .#{$ns}Layout-brandBar,
      .#{$ns}Layout-aside {
        width: var(--Layout-aside--lg-width);
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .#{$ns}Layout {
    &--boxed {
      width: px2rem(980px);

      &.#{$ns}Layout--headerFixed .#{$ns}Layout-header {
        width: px2rem(980px);
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .#{$ns}Layout {
    &--boxed {
      width: px2rem(1180px);

      &.#{$ns}Layout--headerFixed .#{$ns}Layout-header {
        width: px2rem(1180px);
      }
    }
  }
}
