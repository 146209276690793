
.#{$ns}ScheduleCalendar {
  &-icon {
    position: absolute;
    bottom: var(--Calendar-icon-bottom);
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: var(--Calendar-icon-width);
    height: var(--Calendar-icon-height);
    border-radius: 50%;
    z-index: 10;
  }
  &-action {
    display: block;
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    color: inherit;
    &:not(:disabled):not(.is-disabled):hover {
      color: inherit;
      background: transparent;
      border-color: transparent;
    }
  }
  .rdtDay {
    position: relative;
  }

  &-text-overflow {
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    position: absolute;
    width: 100%;
  }

  .rdt .rdtPicker {
    td.rdtActive {
      background: transparent;
      color: var(--Calendar-color);
      > span {
        background: transparent !important;
      }
    }
    td.rdtActive:hover{
      background: var(--Calendar-cell-onHover-bg);
    }
  }
}

.#{$ns}ScheduleCalendar-large {
  width: 100%;

  .rdtPicker {
    width: 100%;
    table {
      border-collapse: collapse;
      border-spacing: 0;
      td {
        border: var(--Calendar-borderWidth) solid var(--borderColor);
      }
    }
  }

  .rdtDay {
    height: var(--Calendar-rdt-day);
    vertical-align: top;
  }

  .#{$ns}ScheduleCalendar-large-day-wrap {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    .#{$ns}ScheduleCalendar-large-schedule-content {
      position: relative;
      z-index: 10;
      border-radius: var(--borderRadius);
      text-align: left;
      padding: var(--Calendar-schedule-content-padding);
      height: var(--Calendar-schedule-content-height);
      color: var(--Calendar-schedule-content-color);
    }
  }

  .#{$ns}ScheduleCalendar-action {
    z-index: 20;
    position: relative;
  }
}

.#{$ns}CalendarMobile {
  height: 100%;
  width: 100%;
  overflow: scroll;

  &-pop {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 90vh;
    background: #fff;
    border-radius: px2rem(16px) px2rem(16px) 0 0;
    overflow: hidden;
    border-width: 0;
  }

  &-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-header {
    flex-shrink: 0;
    box-shadow: 0 2px 10px rgb(125 126 128 / 16%);
    position: relative;

    .subtitle-text {
      display: inline-block;
      width: px2rem(110px);
      position: relative;
    }

    .rdtPrev {
      width: px2rem(20px);
      height: px2rem(44px);
      display: block;
      position: absolute;
      top: 0;
      left: px2rem(-20px);
    }
    .rdtNext {
      width: px2rem(20px);
      height: px2rem(44px);
      display: block;
      position: absolute;
      top: 0;
      right: px2rem(-20px);
    }
  }

  &-title,
  &-subtitle {
    height: px2rem(44px);
    font-weight: 500;
    line-height: px2rem(44px);
    text-align: center;
  }

  &-title {
    font-size: var(--fontSizeLg);
  }

  &-weekdays {
    display: flex;

    .weekday {
      flex: 1;
      line-height: px2rem(30px);
      text-align: center;
    }
  }

  &-close {
    position: absolute;
    z-index: 1;
    color: #c8c9cc;
    font-size: px2rem(16px);
    cursor: pointer;
    top: px2rem(11px);
    right: px2rem(16px);
  }

  &-body::-webkit-scrollbar {
    width: 0;
  }

  &-body {
    flex: 1;
    overflow-y: scroll;

    table {
      border-spacing: 0 px2rem(4px);
    }

    .rdt .rdtPicker td.rdtActive, .rdt .rdtPicker td.rdtActive:hover {
      background: transparent;
      color: var(--Calendar-color);
      text-shadow: none;
    }

    .rdt .rdtPicker tr td.rdtDisabled, .rdt .rdtPicker tr td.rdtDisabled:hover {
      color: #999;
    }

    .rdtOldNone td.rdtOld {
      display: none;
    }

    .rdtPicker td {
      height: px2rem(56px);
      position: relative;
    }

    .rdt .rdtPicker {
      td.rdtDay,
      td.rdtDay:hover,
      td.rdtDisabled,
      td.rdtDisabled:hover {
        background: transparent;
      }
    }

    .calendar-wrap {
      position: relative;
      width: px2rem(50px);
      height: 100%;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: px2rem(4px);
    }
  }

  &-embed &-body {
    .calendar-wrap {
      width: auto;
      max-width: px2rem(50px);
    }
  }

  &-footer {
    flex-shrink: 0;

    .date-range-confirm {
      height: px2rem(36px);
      margin: px2rem(7px) 0;
      border-radius: var(--borderRadiusMd);
      display: block;
    }
    .is-disabled {
      opacity: 0.5;
      color: var(--Button--primary-color);
      background: var(--Button--primary-bg);
      border-color: var(--Button--primary-bg);
      filter: none;
    }
    &-toolbar {
      padding: 0 px2rem(16px);
    }
    &-ranges {
      background: #fff;
      box-shadow: 0 0 2px 2px rgba(0,0,0,0.02);
      border-radius: 24px;
      overflow-x: auto;
      position: relative;
      height: px2rem(48px);
      line-height: px2rem(48px);
    }
    .#{$ns}DateRangePicker-rangers {
      position: absolute;
      white-space: nowrap;
      .#{$ns}DateRangePicker-ranger {
        margin: 0 px2rem(25px);
      }
    }
    .#{$ns}DatePicker-shortcuts {
      width: auto;
    }
  }

  &-calendar-wrap {
    padding: var(--gap-sm) 0;
  }

  & &-calendar-wrap &-calendar .rdtPicker {
      width: 100%;
      padding: 0;
    .rdtOld {
      visibility: hidden;
    }
    .rdtNew {
      display: none;
    }
    .rdtBetween {
      background: var(--Calendar-cell-onBetween-bg) !important;
      color: var(--Button--primary-bg);
      &::after {
        width: 0;
      }
    }
    .rdtRangeStart,
    .rdtRangeStart:hover,
    .rdtRangeEnd,
    .rdtRangeEnd:hover {
      .calendar-wrap {
        background: var(--Calendar-cell-onActive-bg) !important;
        color: #fff;
        text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
      }
    }

    .rdtRangeHasEnd,
    .rdtRangeHasEnd:hover {
      background: linear-gradient(to right, transparent 0%, transparent 50%, var(--Calendar-cell-onBetween-bg) 51%, var(--Calendar-cell-onBetween-bg) 100%) !important;
    }

    .rdtRangeEnd,
    .rdtRangeEnd:hover {
      background: linear-gradient(to right, var(--Calendar-cell-onBetween-bg) 0%, var(--Calendar-cell-onBetween-bg) 50%, transparent 51%, transparent 100%) !important;
    }

    .rdtToday {
      border: none;
      .calendar-wrap {
        border: 1px solid #2468F2;
      }
    }
  }

  &-calendar-header {
    height: px2rem(30px);
    line-height: px2rem(30px);
    text-align: center;
  }

  &-range-text {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    color: #fff;
    font-size: var(--fontSizeSm);
    white-space: nowrap;
  }

  &-calendar-wrap {
    position: relative;
  }

  &-calendar-mark {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    color: rgba(242, 243, 245, 0.8);
    font-size: px2rem(160px);
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  &-toast {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: px2rem(136px);
    height: px2rem(36px);
    background: rgba(0, 0, 0, .9);
    border-radius: 4px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-time {
    height: px2rem(180px);
    &-title {
      border: var(--Calendar-borderWidth) solid var(--borderColorDarken);
      border-left: none;
      border-right: none;
      text-align: center;
      height: px2rem(30px);
      line-height: px2rem(30px);
    }
    .rdtPicker {
      margin: 0 auto;
    }
  }
  .#{$ns}CalendarTime {
    height: px2rem(130px);
    overflow: hidden;
  }
  .#{$ns}PickerColumns-header {
    display: none;
  }
}
