.#{$ns}LocationPicker {
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
  border: var(--DatePicker-borderWidth) solid var(--DatePicker-borderColor);
  font-size: var(--DatePicker-fontSize);
  padding: var(--DatePicker-paddingY) var(--DatePicker-paddingX);
  height: var(--DatePicker-height);
  outline: none;
  white-space: nowrap;
  color: var(--DatePicker-color);
  background: var(--DatePicker-bg);
  border-radius: var(--LocationPicker-borderRadius);

  &:not(.is-disabled) {
    cursor: pointer;

    &:hover {
      background: var(--DatePicker-onHover-bg);
      border-color: var(--DatePicker-onHover-borderColor);

      .#{$ns}DatePicker-toggler:before {
        color: var(--DatePicker-onHover-iconColor);
      }
    }
  }

  &.is-focused,
  &.is-active {
    border-color: var(--DatePicker-onFocused-borderColor);
    box-shadow: var(--Form-input-boxShadow);
  }

  &.is-disabled {
    background: $gray200;

    > #{$ns}LocationPicker-input {
      color: var(--text--muted-color);
    }
  }

  &-placeholder {
    color: var(--DatePicker-placeholderColor);
    user-select: none;
    margin-right: var(--gap-base);
    flex-basis: 0;
    flex-grow: 1;
  }

  &-value {
    margin-right: var(--gap-base);
    flex-basis: 0;
    flex-grow: 1;
  }

  &-toggler {
    cursor: pointer;
    color: var(--DatePicker-iconColor);

    &:hover {
      color: var(--DatePicker-onHover-iconColor);
    }
  }

  &-clear {
    @include input-clear();
    line-height: 1;
    margin-right: var(--gap-xs);
  }
}

.#{$ns}LocationControl {
  position: relative;
  width: 100%;
}

.#{$ns}LocationControl:not(.is-inline) > .#{$ns}LocationPicker {
  display: flex;
}

.#{$ns}LocationPicker-popover {
  min-width: 320px;
  width: 100%;
  max-width: 100%;
  margin-top: -1px;
  border-color: var(--Form-input-onFocused-borderColor);
}

.#{$ns}MapPicker {
  &-search {
    padding: 5px 10px;
  }

  &-map {
    display: block;
    width: 100%;
    height: 200px;
  }

  &-item {
    padding: 10px 10px;
    position: relative;
    border-top: 1px solid var(--borderColor);

    > svg {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &-itemTitle {
    font-size: var(--fontSizeBase);
  }

  &-itemDesc {
    font-size: var(--fontSizeSm);
  }

  &-sugItem {
    cursor: pointer;
    padding: calc(
        (
            var(--Form-select-menu-height) - var(--Form-input-lineHeight) *
              var(--Form-input-fontSize)
          ) / 2
      )
      var(--Form-select-paddingX);

    &:hover {
      color: var(--Form-select-menu-onActive-color);
      background: var(--Form-select-menu-onActive-bg);
    }
  }
}

.tangram-suggestion-main {
  display: none !important;
}
