@mixin arrow-control {
  width: 10%;
  min-width: var(--Carousel-arrowControl-width);
  height: 100%;
  cursor: pointer;
  top: 0;
  bottom: 0;
  position: absolute;
  transition-duration: var(--Carousel-transitionDuration);
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    width: var(--Carousel-svg-width);
    height: var(--Carousel-svg-height);
  }
}

.#{$ns}Carousel {
  min-width: var(--Carousel-minWidth);
  height: var(--Carousel-height);
  position: relative;
  display: block;
  background: var(--Carousel-bg);

  &.#{$ns}Carousel--light {
    .#{$ns}Carousel-dot {
      background: var(--Carousel--light-control);
    }

    svg {
      fill: var(--Carousel--light-control);
    }

    .#{$ns}Carousel-item {
      .title,
      .description {
        color: var(--Carousel--light-control);
      }
    }
  }

  &.#{$ns}Carousel--dark {
    .#{$ns}Carousel-dot {
      background: var(--Carousel--dark-control);
    }

    svg {
      fill: var(--Carousel--dark-control);
    }

    .#{$ns}Carousel-item {
      .title,
      .description {
        color: var(--Carousel--dark-control);
      }
    }
  }

  &-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .#{$ns}Carousel-item {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transition-duration: var(--Carousel-transitionDuration);
      transition-timing-function: ease-out;
      transition-property: transform, opacity;

      &.fade {
        opacity: 0;
      }

      &.fade.in {
        opacity: 1;
      }

      &.slide {
        transform: translateX(100%);
      }

      &.slide.in {
        transform: translateX(0);
      }

      &.slide.out {
        transform: translateX(-100%);
      }

      &.slideRight {
        transform: translateX(-100%);
      }

      &.slideRight.in {
        transform: translateX(0);
      }

      &.slideRight.out {
        transform: translateX(100%);
      }

      .title {
        position: absolute;
        bottom: var(--Carousel-imageTitle-bottom);
        text-align: center;
        width: 100%;
        opacity: 0.8;
      }

      .description {
        position: absolute;
        bottom: var(--Carousel-imageDescription-bottom);
        text-align: center;
        width: 100%;
        opacity: 0.8;
      }

      .image {
        width: 100%;
        height: 100%;
        background-size: cover;
      }
    }
  }

  &-image {
    width: 100%;
    height: 100%;
  }

  &-image .#{$ns}Image-origin {
    height: 100%;
    width: 100%;
  }

  &-dotsControl {
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 100;
    text-align: center;

    .#{$ns}Carousel-dot {
      cursor: pointer;
      display: inline-block;
      height: var(--Carousel-dot-width);
      width: var(--Carousel-dot-height);
      border-radius: var(--Carousel-dot-borderRadius);
      margin: var(--Carousel-dot-margin);
      transition-duration: var(--Carousel-transitionDuration);
      opacity: 0.3;

      &.is-active {
        opacity: 1;
      }
    }
  }

  .#{$ns}Carousel-leftArrow {
    @include arrow-control;
    display: none;
    left: 0;
  }

  .#{$ns}Carousel-rightArrow {
    @include arrow-control;
    display: none;
    right: 0;
  }

  &:hover {
    .#{$ns}Carousel-leftArrow {
      display: block;
    }
    .#{$ns}Carousel-rightArrow {
      display: block;
    }
  }

  &-arrowsControl {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    text-align: center;
  }
}
