.#{$ns}AppBcn {
  height: px2rem(30px);
  line-height: px2rem(30px);
  font-size: px2rem(12px);
  list-style: none;
  margin: 0;
  padding: 0 0 0 var(--gap-md);
  border-bottom: var(--borderWidth) solid var(--borderColor);

  &-item {
    display: inline-block;
  }

  &-item + &-item:before {
    padding: 0 5px;
    color: #ccc;
    content: '/\00a0';
  }
}

.#{$ns}AppFoldBtn {
  @include icon-color();
  padding: px2rem(10px);
  margin-left: px2rem(-10px);
  cursor: pointer;
}

.#{$ns}AppLogo-html {
  display: inline-flex;
  vertical-align: middle;
}
