.#{$ns}Field-quickEditBtn {
  color: var(--QuickEdit-iconColor);
  margin-left: var(--gap-xs);
  display: inline-block;
  vertical-align: text-bottom;
  cursor: pointer;
  opacity: 0.6;
  font-size: 0;

  &:hover {
    color: var(--QuickEdit-onHover-iconColor);
    opacity: 1;
  }

  > svg {
    width: var(--QuickEdit-iconSize);
    height: var(--QuickEdit-iconSize);
    top: 0;
  }
}

.#{$ns}Field--quickEditable {
  outline: none;
  position: relative;

  &:focus {
    position: relative;

    &:after {
      content: '';
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      pointer-events: none;
      z-index: 1;
      border: var(--QuickEdit-onFocus-borderWidth) dashed
        var(--QuickEdit-onFocus-borderColor);
    }
  }

  &:hover .#{$ns}Field-quickEditBtn {
    visibility: visible;
  }
}

.#{$ns}QuickEdit-popover {
  min-width: px2rem(320px);
  max-width: px2rem(640px);

  .#{$ns}Panel {
    margin-bottom: 0;
    border: none;
  }
}
