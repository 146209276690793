.#{$ns}SchemaEditor {
  &Props {
    width: 100%;

    > button {
      margin-top: 7px;
    }

    &-placeholder {
      color: var(--text--muted-color);
    }
  }

  &Props--depth {
    position: relative;
    margin-left: px2rem(40px);

    &:before {
      width: 1px;
      content: '';
      display: block;
      position: absolute;
      top: -10px;
      bottom: 10px;
      left: -35px;
      border-left: dashed 1px var(--icon-color);
    }

    & .#{$ns}SchemaEditorItem:before {
      height: 1px;
      content: '';
      display: block;
      position: absolute;
      top: 17px;
      width: 25px;
      left: -35px;
      border-top: dashed 1px var(--icon-color);
    }

    & > button {
      position: relative;

      &:before {
        height: 1px;
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        width: 25px;
        left: -35px;
        border-top: dashed 1px var(--icon-color);
      }
    }
  }

  &ArrayProps:before {
    bottom: 15px;
  }

  &Item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-sm);

    align-items: flex-start;
    position: relative;
  }

  &Item + &Item {
    margin-top: var(--gap-sm);
  }

  &Object,
  &Array {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--gap-sm);
    align-items: flex-start;
    width: 100%;
  }

  &-itemsLabel {
    margin-top: calc((var(--Form-input-height) - var(--Switch-height)) / 2);
  }

  &-caret {
    display: inline-block;
    height: px2rem(24px);
    text-align: center;
    line-height: px2rem(24px);
    vertical-align: middle;
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform var(--animation-duration);
    color: var(--icon-color);
    margin-top: calc((var(--Form-input-height) - var(--Switch-height)) / 2);

    > svg {
      width: 10px;
      height: 10px;
      top: 0;
    }
  }

  &-caret.is-collapsed {
    transform: rotate(-90deg);
  }

  &-required {
    padding-top: 4px;
  }

  &-key,
  &-title,
  &-description {
    flex: 1;
    max-width: 200px;
    min-width: 80px;

    > input {
      flex-basis: 0;
    }
  }
}
