.#{$ns}SubForm {
  &-values {
    margin: 2px -3px;

    &:empty {
      display: none;
    }
  }

  &-value {
    background: var(--ResultBox-value-bg);
    color: var(--ResultBox-value-color);
    font-size: var(--Form-input-fontSize);
    padding: 0 var(--gap-xs);
    min-height: px2rem(24px);
    flex-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    margin: 2px 3px;
    user-select: none;

    &Label {
      color: inherit;
    }

    &Del,
    &Edit,
    &DragBar {
      cursor: pointer;
      margin-left: var(--gap-xs);
      color: var(--ResultBox-icon-color);
      &:hover {
        color: var(--ResultBox-icon--onHover-color);
      }

      > svg {
        width: px2rem(12px);
        height: px2rem(12px);
      }
    }

    &DragBar {
      margin-left: 0;
      margin-right: var(--gap-xs);
      cursor: move;
    }

    &:hover {
      background: var(--ResultBox-value--onHover-bg);
    }

    &.is-disabled {
      pointer-events: none;
      color: var(--ResultBox-value--onDisabled-color);

      > a {
        color: var(--ResultBox-icon--onDisabled-color);
      }
    }

    &--dragging {
      position: relative;

      &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 5;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  &-addBtn {
    width: auto;
    min-width: auto;
    font-weight: var(--fontWeightNormal);
    color: var(--Button--link-color);
    text-decoration: var(--link-decoration);
    border: none;
    height: auto;
    padding: 0 4px;
    display: inline-flex;
    align-items: center;

    @include hover-focus {
      color: var(--Button--link-onHover-color);
      text-decoration: var(--link-onHover-decoration);
      box-shadow: none;
    }

    > svg {
      width: 12px;
      height: 12px;
      margin-right: 4px !important;
      top: 0;
    }

    &:disabled,
    &.is-disabled {
      color: var(--text--muted-color);
      pointer-events: none;
      background: transparent;
    }
  }

  &-placeholder {
    color: var(--Form-input-placeholderColor);
    user-select: none;
    padding: 4px;
  }
}
