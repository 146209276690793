.#{$ns}TagControl {
  position: relative;

  &-input.is-focused {
    border-radius: 0;
  }

  &-input.is-disabled {
    border-style: dashed;
    background: transparent;
  }

  &.is-inline {
    display: inline-block;
  }

  > .#{$ns}TagControl-popover {
    // box-shadow: none;
    padding: 0;
    border: none;
    width: 100%;
    margin-top: calc(
      var(--Form-select-popoverGap) - var(--Form-select-outer-borderWidth)
    );

    &.#{$ns}PopOver--leftBottomLeftTop {
      top: 100% !important;
    }

    &.#{$ns}PopOver--leftTopLeftBottom {
      top: auto !important;
      bottom: 100% !important;
    }
  }

  &-sug {
    margin-top: var(--Form-input-marginBottom);

    &Tip {
      color: var(--TagControl-sugTip-color);
      margin-bottom: var(--Form-input-marginBottom);
    }

    &Item {
      margin-right: var(--gap-sm);
      margin-bottom: var(--gap-sm);
      display: inline-block;
      font-size: var(--TagControl-sugBtn-fontSize);
      cursor: pointer;
      user-select: none;
      border: var(--TagControl-sugBtn-borderWidth) solid transparent;

      @include button-size(
        var(--TagControl-sugBtn-paddingY),
        var(--TagControl-sugBtn-paddingX),
        var(--TagControl-sugBtn-fontSize),
        var(--TagControl-sugBtn-lineHeight),
        var(--TagControl-sugBtn-borderRadius),
        var(--TagControl-sugBtn-height)
      );

      @include button-variant(
        var(--TagControl-sugBtn-bg),
        var(--TagControl-sugBtn-border),
        var(--TagControl-sugBtn-color),
        var(--TagControl-sugBtn-onHover-bg),
        var(--TagControl-sugBtn-onHover-border),
        var(--TagControl-sugBtn-onHover-color),
        var(--TagControl-sugBtn-onActive-bg),
        var(--TagControl-sugBtn-onActive-border),
        var(--TagControl-sugBtn-onActive-color)
      );

      &.is-disabled {
        pointer-events: none;
        opacity: var(--Button-onDisabled-opacity);
      }
    }
  }
}

.#{$ns}TagControl-popover {
  &.#{$ns}PopOver--leftBottomLeftTop > .#{$ns}ListMenu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.#{$ns}PopOver--leftTopLeftBottom > .#{$ns}ListMenu {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
