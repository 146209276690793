.#{$ns}Wrapper {
  &--xs {
    padding: var(--gap-xs);
  }

  &--sm {
    padding: var(--gap-sm);
  }

  &--md {
    padding: var(--gap-md);
  }

  &--lg {
    padding: var(--gap-lg);
  }

  &--xl {
    padding: var(--gap-xl);
  }
}
