.#{$ns}CBGroup {
  font-size: var(--fontSizeSm);
  position: relative;
  border: 1px solid #e8e9eb;
  border-radius: 4px;
  border-left: px2rem(3px) solid #e6f0ff;
  padding: px2rem(30px) px2rem(27px) px2rem(17px);
  margin-top: px2rem(30px);
  &-toolbarCondition {
    text-align: center;
    margin-top: px2rem(-44px);
    margin-bottom: px2rem(16px);
    .#{$ns}Select {
      font-size: 12px;
      height: px2rem(28px);
      width: px2rem(62px);
      background: #d4e5ff;
      border: none;
      color: #0832a6;
      font-weight: 500;
      padding: 0;
      padding-left: px2rem(8px);
      min-height: px2rem(28px);
      &:hover {
        background: #d4e5ff;
      }
    }
  }

  &-toolbar {
    display: flex;
    flex-direction: row;
    margin-top: px2rem(8px);
    .#{$ns}Button {
      transition: padding var(--animation-duration);
      min-width: unset;

      svg {
        width: 10px;
        height: 10px;
        top: 0;
        margin-right: 5px;
      }
    }

    .#{$ns}CBGroup-toolbarConditionAdd {
      display: flex;
      align-items: center;
      .#{$ns}ButtonGroup {
        & > .cxd-Button:first-child {
          margin-right: px2rem(24px);
        }
      }

      .#{$ns}CBDelete {
        margin-left: var(--gap-xs);
      }
    }
  }
  .#{$ns}ResultBox {
    padding-right: px2rem(3px);
  }

  &-field,
  &-operator {
    position: relative;
    display: inline-block;
    margin: px2rem(3px);
    vertical-align: middle;
  }

  &-fieldCaret,
  &-operatorCaret {
    transition: transform var(--animation-duration) ease-out;
    margin: 5px;
    display: flex;
    color: var(--Form-select-caret-iconColor);
    &:hover {
      color: var(--Form-select-caret-onHover-iconColor);
    }

    > svg {
      width: px2rem(10px);
      height: px2rem(10px);
      top: 0;
    }
  }

  &-fieldInput.is-active &-fieldCaret,
  &-operatorInput.is-active &-operatorCaret {
    transform: rotate(180deg);
  }

  &-placeholder {
    color: var(--text--muted-color);
    position: relative;
    padding: 10px;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 5px;
    &.simple {
      margin-left: 0;
    }
  }
}

.#{$ns}CBDelete {
  cursor: pointer;
  margin-left: auto;
}

.#{$ns}CBGroupOrItem-body-group--hover {
  & > .#{$ns}CBGroupOrItem-dragbar {
    opacity: 1 !important;
  }
  & > .#{$ns}CBGroup {
    border-left-color: #2468f1;
  }
}

.#{$ns}CBGroupOrItem {
  position: relative;

  & + & {
    margin-top: px2rem(10px);
  }
  &-dragbar {
    cursor: move;
    width: 20px;
    margin-left: -5px;
    opacity: 0.6;
    text-align: center;
    transition: opacity var(--animation-duration) ease-out;
    @include icon-color();
  }

  &-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    transition: all var(--animation-duration) ease-out;
    &-group {
      width: 100%;
      flex-direction: row;
      display: flex;
      align-items: center;
      margin-top: px2rem(16px);
      > .#{$ns}CBGroupOrItem-dragbar {
        left: px2rem(-16px);
        position: absolute;
      }
      > .#{$ns}CBGroup {
        margin: 0px;
      }
    }

    &-item {
      background-color: #f7f7f9;
      width: 100%;
      padding: px2rem(12px);
      padding-left: px2rem(28px);
      display: flex;
      flex-direction: row;
      align-items: center;
      > .#{$ns}CBGroupOrItem-dragbar {
        left: px2rem(10px);
        position: absolute;
      }
    }
  }

  &.is-dragging {
    display: none;
  }

  &.is-ghost > &-body:before {
    position: absolute;
    z-index: 2;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($info, 0.2);
  }

  .#{$ns}CBGroup {
    flex-grow: 1;
  }

  & > &-body > &-body-group > &-dragbar,
  & > &-body > &-body-item > &-dragbar {
    opacity: 0;
  }

  &:hover > &-body > &-body-item > &-dragbar {
    opacity: 1;
  }

  &-simple {
    margin-bottom: var(--gap-sm);
  }
}

.#{$ns}CBInputSwitch {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  // margin: px2rem(3px);
  cursor: pointer;
  > a {
    @include icon-color();
  }

  svg {
    width: px2rem(10px);
    height: px2rem(10px);
  }
}

.#{$ns}CBFunc {
  display: inline-block;
  vertical-align: middle;
  margin: px2rem(3px);

  &-select {
    display: inline-block;
    position: relative;
  }

  &-error {
    color: var(--danger);
  }

  &-args {
    display: inline-block;
    > span {
      display: inline-block;
      padding: 0 5px;
      color: var(--info);
    }

    > div {
      display: inline-block;
    }
  }
}

.#{$ns}CBValue {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: px2rem(3px);
}

.#{$ns}CBFormula {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: px2rem(3px);

  &-label {
    background: var(--ResultBox-value-bg);
    color: var(--text--muted-color);
    display: block;
    font-size: var(--fontSizeSm);
    align-self: center;
    margin: -5px 5px -5px -8px;
    padding: 5px;
    border-radius: 5px;
    user-select: none;
  }
}

.#{$ns}CBSeprator {
  width: 20px;
  text-align: center;
  display: inline-block;
  user-select: none;
}

.#{$ns}CBPicker-trigger {
  cursor: pointer;
  transition: transform var(--animation-duration) ease-out;
  display: flex;
  color: var(--Form-select-caret-iconColor);

  &:hover {
    color: var(--primary);
  }
}
