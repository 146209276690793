.#{$ns}Log {
  position: relative;

  &-body {
    background: var(--Log-bg);
    color: var(--Log-color);
    padding: var(--Log-padding);
    overflow-y: auto;
    font-family: var(--fontFamilyMonospace);
  }

  &-line {
    padding: var(--Log-line-padding);
    white-space: pre-wrap;
    word-wrap: break-word;
    // bootstrap 会修改这些，覆盖掉
    code {
      color: inherit;
      background: inherit;
    }
    &:hover {
      background: var(--Log-line--onHover-bg);
    }
    &-number {
      color: #999;
      font-style: italic;
      margin-right: 5px;
    }
  }

  &-operation {
    position: absolute;
    top: 0;
    left: -16px;
    .#{$ns}Button {
      margin-right: var(--gap-xs);
    }
    &-hidden {
      background: #f0f2f5;
      width: 24px;
      height: 48px;
      box-shadow: 0 4px 6px 0 rgb(8 14 26 / 4%), 0 1px 10px 0 rgb(8 14 26 / 5%),
        0 2px 4px -1px rgb(8 14 26 / 6%);
      border-radius: 0 2px 2px 0;
      padding-top: 14px;
      padding-left: 6px;
      cursor: pointer;
      color: #000;
      transition: all 0.3s;
    }
  }

  .ansi-bold {
    font-weight: 700;
  }

  .ansi-italic {
    font-style: italic;
  }

  .ansi-underline {
    text-decoration: underline;
  }

  .ansi-black-fg,
  .ansi-bright-black-fg {
    color: #4e4e4e;
  }

  .ansi-red-fg,
  .ansi-bright-red-fg {
    color: #ff6c60;
  }

  .ansi-green-fg,
  .ansi-bright-green-fg {
    color: #0a0;
  }

  .ansi-yellow-fg,
  .ansi-bright-yellow-fg {
    color: #ffffb6;
  }

  .ansi-blue-fg,
  .ansi-bright-blue-fg {
    color: #96cbfe;
  }

  .ansi-magenta-fg,
  .ansi-bright-magenta-fg {
    color: #ff73fd;
  }

  .ansi-cyan-fg,
  .ansi-bright-cyan-fg {
    color: #5ff;
  }

  .ansi-white-fg,
  .ansi-bright-white-fg {
    color: #eee;
  }

  .ansi-grey-fg,
  .ansi-bright-grey-fg {
    color: #969696;
  }

  .ansi-black-bg {
    background-color: #4e4e4e;
  }

  .ansi-red-bg {
    background-color: #ff6c60;
  }

  .ansi-green-bg {
    background-color: #0a0;
  }

  .ansi-yellow-bg {
    background-color: #ffffb6;
  }

  .ansi-blue-bg {
    background-color: #96cbfe;
  }

  .ansi-magenta-bg {
    background-color: #ff73fd;
  }

  .ansi-cyan-bg {
    background-color: #0aa;
  }

  .ansi-white-bg {
    background-color: #eee;
  }
}
