.#{$ns}Select {
  display: inline-flex;

  /* select 标签样式 */
  @mixin select-value {
    .#{$ns}Select-value {
      position: static;
      white-space: normal;
      user-select: none;
      line-height: calc(
        var(--Form-input-lineHeight) * var(--Form-input-fontSize) - #{px2rem(
            2px
          )}
      );
      display: inline-block;
      vertical-align: middle;
      font-size: var(--Form-selectValue-fontSize);
      background: var(--Form-select-value-bgColor);
      border: px2rem(1px) solid var(--Form-select-value-borderColor);
      border-radius: px2rem(2px);
      margin-right: var(--gap-xs);
      margin-bottom: var(--gap-xs);

      &:hover {
        background-color: var(--Form-selectValue-onHover-bgColor);
      }
    }

    .#{$ns}Select-valueLabel {
      display: inline-block;
      max-width: var(--Form-valueLabel-maxWidth);
      padding: 0 var(--gap-xs);
      overflow: hidden;
      vertical-align: top;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &--block {
    display: flex;
  }
  vertical-align: middle;
  text-align: left;
  align-items: center;
  outline: none;
  position: relative;
  font-size: var(--Form-input-fontSize);
  border: var(--Form-select-borderWidth) solid var(--Form-select-borderColor);
  background: var(--Form-select-bg);
  border-radius: var(--Form-select-borderRadius);
  min-height: var(--Form-selectOption-height);
  $paddingY: calc(
    (
        var(--Form-selectOption-height) - var(--Form-input-lineHeight) *
          var(--Form-input-fontSize) - var(--Form-select-borderWidth) * 2
      ) / 2
  );
  padding: $paddingY 0 $paddingY var(--Form-select-paddingX);
  cursor: pointer;
  color: var(--Form-select-color);

  &:hover {
    background: var(--Form-select-onHover-bg);
    border-color: var(--Form-select-onHover-borderColor);

    .#{$ns}Select-arrow:before {
      color: var(--Form-select-caret-onHover-iconColor);
    }
  }

  &.is-disabled {
    color: var(--text--muted-color);
    background: var(--Form-input-onDisabled-bg);
    border-color: var(--Form-input-onDisabled-borderColor);
  }

  &:focus {
    box-shadow: var(--Form-select-onFocus-boxShadow);
  }

  .#{$ns}PopOver.#{$ns}Select-popover {
    .#{$ns}Select-menu {
      overflow-x: hidden;

      .#{$ns}Select-option {
        height: px2rem(32px);
        line-height: px2rem(22px);
      }

      .#{$ns}Select-addBtn {
        height: px2rem(32px);
        line-height: px2rem(30px);
      }
    }
  }

  @include input-border();

  &-valueWrap {
    user-select: none;
    position: relative;
    flex-grow: 1;
    line-height: 1;
    // width: 0;
    max-width: 100%;
  }
  &-valuesNoWrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: px2rem(20px);
  }
  &-placeholder {
    color: var(--Form-select-placeholderColor);
    line-height: var(--Form-input-lineHeight);
    user-select: none;
    white-space: nowrap;
    color: var(--Form-select-placeholderColor);
  }

  &-value {
    line-height: calc(
      var(--Form-input-lineHeight) * var(--Form-input-fontSize)
    );
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    &.is-invalid {
      color: var(--Form-selectValue-onInvalid-color);
    }

    &.is-disabled {
      color: var(--Form-selectValue-onDisabled-color);
    }
  }
  &--multi {
    height: auto;
    min-height: var(--Form-selectOption-height);
    padding: var(--gap-xs) 0 var(--gap-xs) var(--gap-xs);

    .#{$ns}Select-valueWrap {
      > input {
        display: inline-block;
        width: px2rem(100px);
        margin-bottom: var(--gap-xs);
      }

      .#{$ns}Select-placeholder {
        margin-left: var(--gap-sm);
      }
    }
    .#{$ns}Select-values + .#{$ns}Select-input {
      transform: translateY(0);
    }

    @include select-value();
  }
  &-valueIcon {
    cursor: pointer;
    padding: 1px 5px;
    color: var(--Form-select-valueIcon-color);

    &:hover {
      color: var(--Form-select-valueIcon-onHover-color);
    }

    &.is-disabled {
      pointer-events: none;
      display: none;
      cursor: not-allowed;
      background: var(--Form-selectValue-onDisable-bg);
    }

    svg {
      width: px2rem(10px);
      height: px2rem(10px);
    }
  }

  &-arrow {
    margin-right: px2rem(12px);
    // margin-right: var(--gap-xs);
    // margin-left: var(--gap-xs);
    width: var(--gap-md);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    transform: rotate(90deg);

    > svg {
      transition: transform var(--animation-duration);
      display: inline-block;
      color: var(--Form-select-caret-iconColor);
      width: 10px;
      height: 10px;
      top: 0;
    }
  }

  &.is-opened:not(.is-mobile) &-arrow > svg {
    transform: rotate(180deg);
  }

  &.is-mobile {
    min-height: calc(var(--Form-input-lineHeight) * var(--fontSizeLg));
    border: none;
    padding: 0;
    font-size: var(--fontSizeLg);

    .#{$ns}Select-valueWrap {
      text-align: right;
      padding-right: 4px;
    }
    .#{$ns}Select-arrow {
      > svg {
        transform: rotate(-90deg);
      }
    }
  }

  &-menu {
    max-height: px2rem(300px);
    padding-top: px2rem(4px);
    padding-bottom: px2rem(4px);
    overflow: auto;
    user-select: none;
    .#{$ns}Checkbox--sm > i {
      margin-top: px2rem(-3px);
      margin-right: px2rem(4px);
    }
    &.is-mobile {
      width: 100%;
      text-align: center;
      .#{$ns}Select-option {
        line-height: px2rem(36px);
      }
    }
  }
  &--longlist {
    overflow: hidden;
    max-height: max-content;
  }

  &-input {
    cursor: pointer;
    outline: none;
    border: none;
    margin: 0 var(--Form-select-paddingX);
    height: var(--Form-select-search-height);
    font-size: var(--Form-select-input-fontSize);
    border-bottom: 1px solid var(--borderColor);
    display: flex;
    align-items: center;

    // &.is-focused {
    //   border-color: var(--Form-input-onFocused-borderColor);
    // }

    > svg {
      fill: #999;
      width: px2rem(14px);
      min-width: px2rem(14px);
      height: px2rem(14px);
      margin-right: var(--gap-xs);
    }

    > input {
      width: 100%;
      outline: none;
      border: none;
      flex-grow: 1;
      background: transparent;
      position: relative;
      top: 0.125em;
    }
  }

  &-option {
    cursor: pointer;
    // min-width: px2rem(120px);
    padding: calc(
        (
            var(--Form-select-menu-height) - var(--Form-input-lineHeight) *
              var(--Form-input-fontSize)
          ) / 2
      )
      var(--Form-select-paddingX);

    &.is-active {
      color: var(--Form-select-menu-onActive-color);
      background: var(--Form-select-menu-onActive-bg);
    }

    &.is-highlight {
      color: var(--Form-select-menu-onHover-color);
      background: var(--Form-select-menu-onHover-bg);
    }

    &.is-disabled {
      color: var(--Form-select-menu-onDisabled-color);
      background: var(--Form-select-menu-onDisabled-bg);
    }

    > label {
      display: block;
    }

    > a {
      float: right;
      margin-left: var(--gap-xs);
      display: none;
    }

    &.is-highlight > a {
      display: block;
    }

    .#{$ns}Select-option-content,
    .#{$ns}Select-option-content > span > div {
      flex: auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: inline-block;
      max-width: 100%;
    }
  }

  &-noResult {
    color: var(--Form-select-placeholderColor);
    line-height: var(--Form-input-lineHeight);
    font-size: var(--Form-select-input-fontSize);
    user-select: none;
    padding: calc(
        (
            var(--Form-select-menu-height) - var(--Form-input-lineHeight) *
              var(--Form-input-fontSize)
          ) / 2
      )
      var(--Form-select-paddingX);
  }

  &-option-hl {
    color: var(--info);
  }

  &-addBtn {
    display: block;
    cursor: pointer;
    padding: calc(
        (
            var(--Form-select-menu-height) - var(--Form-input-lineHeight) *
              var(--Form-input-fontSize)
          ) / 2
      )
      var(--Form-select-paddingX);

    &:hover {
      text-decoration: none;
    }

    > svg {
      width: px2rem(14px);
      height: px2rem(14px);
      margin-right: var(--Checkbox-gap);
    }
  }

  &.is-focused:not(.is-mobile),
  &.is-opened:not(.is-mobile) {
    border-color: var(--Form-input-onFocused-borderColor);
    color: var(--Form-select-onFocused-color);
  }

  &-spinner {
    line-height: calc(
      var(--Form-input-lineHeight) * var(--Form-input-fontSize)
    );
  }

  &-clear {
    padding: px2rem(3px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;

    svg {
      fill: var(--Form-input-iconColor);
      width: px2rem(14px);
      height: px2rem(14px);
      top: 0;

      &:hover > path {
        &:first-of-type {
          fill: var(--Form-select-valueIcon-color);
        }
      }
    }
  }

  &-popup {
    height: px2rem(320px);
  }

  &-overflow {
    &-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(
        (
            var(--Form-input-lineHeight) * var(--Form-input-fontSize) - #{px2rem(
                2px
              )} + var(--gap-xs)
          ) * 5
      );

      @include select-value();
    }

    &.#{$ns}Tooltip--dark {
      .#{$ns}Select-overflow-wrapper {
        .#{$ns}Select-value {
          background-color: var(--Form-select-value-bgColor--dark);
          border: none;

          &:hover {
            background-color: var(--ResultBox-value--onHover-bg--dark);
          }

          .#{$ns}Select-valueIcon {
            color: var(--Form-select-valueIcon-color--dark);
          }
        }
      }
    }
  }
}

.#{$ns}Select-popover {
  margin-top: calc(var(--Form-select-outer-borderWidth) * -1);

  background: var(--Form-select-menu-bg);
  color: var(--Form-select-menu-color);
  border: var(--Form-select-outer-borderWidth) solid
    var(--Form-input-onFocused-borderColor);
  box-shadow: var(--Form-select-outer-boxShadow);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  // min-width: px2rem(100px);

  // PopOver 上已经配置了，这个要是配置就会覆盖，所以先干掉好了
  // z-index: 10;

  &.#{$ns}PopOver--leftTopLeftBottom {
    margin-top: calc(
      (var(--Form-select-popoverGap) - var(--Form-select-outer-borderWidth)) *
        -1
    );
  }
}

.#{$ns}SelectControl {
  &:not(.is-inline) > .#{$ns}Select {
    display: flex;
  }

  &.is-error > .#{$ns}Select {
    border-color: var(--Form-input-onError-borderColor);
    background: var(--Form-input-onError-bg);
  }
}

.#{$ns}Select.is-error {
  border-color: var(--Form-input-onError-borderColor);
  background: var(--Form-input-onError-bg);
}

// 需要能撑开
@include media-breakpoint-up(sm) {
  .#{$ns}Form-control--sizeXs > .#{$ns}Select,
  .#{$ns}Form-control--sizeSm > .#{$ns}Select,
  .#{$ns}Form-control--sizeMd > .#{$ns}Select,
  .#{$ns}Form-control--sizeLg > .#{$ns}Select {
    min-width: 100%;
    max-width: 100%;
    display: inline-flex !important;

    .#{$ns}Select-valueWrap {
      width: 0; // ! 没有这个会撑开。
    }
  }
}
