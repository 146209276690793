/**
 * Debug 模块的 UI，由于没法使用任何主题，所以这里使用独立配色风格
 */

.AMISDebug {
  position: fixed;
  z-index: $zindex-debug;
  top: 0;
  right: 0;
  height: 100vh;
  width: 24px;
  pointer-events: none;

  h3 {
    color: inherit;
  }

  .primary {
    color: #009fff;
  }

  &-header {
    padding: var(--Drawer-header-padding);
    background: var(--Drawer-header-bg);
    border-bottom: var(--Drawer-content-borderWidth) solid
      var(--Drawer-header-borderColor);
  }

  &-hoverBox {
    pointer-events: none;
    position: absolute;
    outline: 1px dashed #1c76c4;
  }

  &-activeBox {
    pointer-events: none;
    position: absolute;
    outline: 1px #1c76c4;
  }

  &-tab {
    overflow: hidden;
  }

  &-tab > button {
    color: inherit;
    background: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: var(--gap-sm) var(--gap-md);
    transition: 0.3s;
    border-bottom: 1px solid transparent;
  }

  &-tab > button:hover {
    color: #e7e7e7;
  }

  &-tab > button.active {
    color: #e7e7e7;
    border-bottom-color: #e7e7e7;
  }

  &-toggle {
    pointer-events: all;
    background: var(--body-bg);
    position: fixed;
    top: 50%;
    right: 0;
    width: 24px;
    height: 48px;
    box-shadow: 0 4px 6px 0 rgb(8 14 26 / 4%), 0 1px 10px 0 rgb(8 14 26 / 5%),
      0 2px 4px -1px rgb(8 14 26 / 6%);
    border-radius: var(--borderRadius) 0 0 var(--borderRadius);
    padding-top: 14px;
    padding-left: 6px;
    cursor: pointer;
    i {
      color: var(--text-color);
    }
    &:hover {
      i {
        color: var(--primary);
      }
    }
  }

  &-content {
    pointer-events: all;
    display: none;
  }

  &-resize {
    position: absolute;
    width: 4px;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: col-resize;
    &:hover {
      background: #75715e;
    }
  }

  &-changePosition {
    position: absolute;
    font-size: 18px;
    right: 40px;
    top: var(--gap-sm);
    cursor: pointer;
  }

  &-close {
    position: absolute;
    font-size: 18px;
    right: var(--gap-sm);
    top: var(--gap-sm);
    cursor: pointer;
  }

  &.is-expanded {
    width: 420px;
    overflow: auto;
    background: #272821;
    color: #cccccc;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .AMISDebug-toggle {
      display: none;
    }
    .AMISDebug-content {
      display: block;
    }
  }

  &.is-left {
    left: 0;
    .AMISDebug-resize {
      left: unset;
      right: 0;
    }
  }

  &-log {
    padding: var(--gap-sm);
    button {
      cursor: pointer;
      background: #0e639c;
      flex-grow: 1;
      box-sizing: border-box;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 6px 11px;
      outline: none;
      text-decoration: none;
      color: inherit;
      max-width: 300px;
      border: none;
    }
    button:hover {
      background: #1177bb;
    }
  }

  &-inspect {
    padding: var(--gap-sm);
  }
}
