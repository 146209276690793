.#{$ns}Combo {
  &-placeholder {
    color: var(--text--muted-color);
    padding-top: var(--Form-label-paddingTop);
  }

  &-toolbarBtn {
    line-height: var(--Combo-toolbarBtn-lineHeight);
    height: var(--Combo-toolbarBtn-height);
    color: var(--Combo-toolbarBtn-color);
    padding: var(--Combo-toolbarBtn-paddingY) var(--Combo-toolbarBtn-paddingX);
    cursor: pointer;

    &:hover {
      color: var(--Combo-toolbarBtn-onHover-color);
    }
  }

  &-setNullBtn {
    margin-top: var(--gap-xs);
    display: inline-block;
  }

  &-addBtn {
    > svg {
      width: var(--Combo-addBtn-fontSize);
      height: var(--Combo-addBtn-fontSize);
    }

    font-size: var(--Combo-addBtn-fontSize);

    @include button-size(
      var(--Combo-addBtn-paddingY),
      var(--Combo-addBtn-paddingX),
      var(--Combo-addBtn-fontSize),
      var(--Combo-addBtn-lineHeight),
      var(--Combo-addBtn-borderRadius),
      var(--Combo-addBtn-height)
    );

    @include button-variant(
      var(--Combo-addBtn-bg),
      var(--Combo-addBtn-border),
      var(--Combo-addBtn-color),
      var(--Combo-addBtn-onHover-bg),
      var(--Combo-addBtn-onHover-border),
      var(--Combo-addBtn-onHover-color),
      var(--Combo-addBtn-onActive-bg),
      var(--Combo-addBtn-onActive-border),
      var(--Combo-addBtn-onActive-color)
    );

    &.is-disabled {
      pointer-events: none;
      opacity: var(--Button-onDisabled-opacity);
    }
  }

  &-delBtn {
    color: var(--icon-color);
    cursor: pointer;

    &:hover {
      color: var(--icon-onHover-color);
    }
  }

  &-tab-delBtn {
    color: var(--icon-color);
    cursor: pointer;
    margin-left: var(--gap-sm);
    display: inline-block;

    > svg {
      width: px2rem(11px);
      height: px2rem(11px);
    }

    &:hover {
      color: var(--icon-onHover-color);
    }
  }

  &-items {
    &:empty {
      display: none;

      + .#{$ns}Combo-toolbar {
        padding-top: calc(
          (var(--Form-input-height) - var(--Combo-addBtn-height)) / 2
        );
      }
    }

    margin-bottom: var(--Combo-items-marginBottom);
  }

  &-item {
    background: var(--Page-main-bg);
  }

  &-itemDrager {
    cursor: move;

    > a {
      color: var(--icon-color);

      &:hover {
        color: var(--icon-onHover-color);
      }
    }

    svg {
      width: px2rem(16px);
      height: px2rem(16px);
    }
  }

  &--hor {
    .#{$ns}Combo-item {
      display: flex;
      flex-wrap: nowrap;
    }

    .#{$ns}Combo-item + .#{$ns}Combo-item {
      margin-top: var(--Combo--horizontal-item-gap);
    }

    .#{$ns}Combo-itemInner {
      flex-basis: 0;
      flex-grow: 1;
    }

    .#{$ns}Combo-itemTag {
      margin-right: var(--gap-sm);

      label {
        color: var(--info);
        margin-right: var(--gap-sm);
      }
    }

    .#{$ns}Combo-delBtn {
      margin-left: var(--gap-xs);
      display: flex;
      white-space: nowrap;
      align-items: flex-start;
      padding-top: var(--Form-label-paddingTop);

      &.is-disabled {
        pointer-events: none;
        opacity: var(--Button-onDisabled-opacity);
      }
    }

    .#{$ns}Combo-delController {
      margin-left: var(--gap-xs);
    }

    &.is-draggable .#{$ns}Combo-toolbar {
      padding-left: px2rem(24px);
    }

    .#{$ns}Combo-itemDrager {
      padding: var(--Combo--horizontal-dragger-top) px2rem(6px) 0 0;
      display: flex;
      align-items: center;
    }
  }

  // &--ver {
  //   .#{$ns}Combo-item {
  //     background: var(--white);
  //   }
  // }

  &--ver:not(&--noBorder) {
    @include clearfix();
    > .#{$ns}Combo-items {
      margin: calc(var(--Combo--vertical-item-gap) * -2)
        calc(var(--Combo--vertical-item-gap) * -2) 0
        calc(var(--Combo--vertical-item-gap) * -2);

      > .#{$ns}Combo-placeholder {
        margin: calc(var(--Combo--vertical-item-gap) * 2);
      }
    }

    .#{$ns}Combo-itemTag {
      text-align: right;

      label {
        color: var(--info);
        margin-right: var(--gap-sm);
      }
    }

    // 不严格点会命中 combo 里面嵌套 combo 的情况，so sad.
    > .#{$ns}Combo-item,
    > .#{$ns}Combo-items > .#{$ns}Combo-item {
      border: var(--Combo--vertical-item-borderWidth) dashed
        var(--Combo--vertical-item-borderColor);
      padding: var(--Combo--vertical-item-paddingY)
        var(--Combo--vertical-item-paddingX);
      position: relative;
    }

    > .#{$ns}Combo-items > .#{$ns}Combo-item {
      margin: calc(var(--Combo--vertical-item-gap) * 2);
    }

    > .#{$ns}Combo-items > .#{$ns}Combo-item > .#{$ns}Combo-delBtn {
      position: absolute;
      opacity: 0;
      top: var(--gap-xs);
      right: var(--gap-xs);
    }

    &:not(.is-disabled) > .#{$ns}Combo-item:hover,
    &:not(.is-disabled) > .#{$ns}Combo-items > .#{$ns}Combo-item:hover {
      border-color: var(--Combo--vertical-item-onHover-borderColor);
      // border-style: solid;

      > .#{$ns}Combo-delBtn,
      > .#{$ns}Combo-delController {
        opacity: 1;
      }
    }

    .#{$ns}Combo-delController {
      position: absolute;
      top: var(--gap-xs);
      right: var(--gap-xs);
      opacity: 0;
    }

    > .#{$ns}Combo-items > .#{$ns}Combo-item > .#{$ns}Combo-itemDrager {
      position: absolute;
      top: var(--Combo--horizontal-dragger-top);
      left: px2rem(-30px);
      display: flex;
      align-items: center;
    }

    &.is-draggable {
      > .#{$ns}Combo-items > .#{$ns}Combo-item {
        margin-left: px2rem(35px);
      }
      > .#{$ns}Combo-toolbar {
        padding-left: px2rem(25px);
      }
    }
  }

  &-item--dragging {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 5;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.#{$ns}ComboTabs > .#{$ns}Tabs-links {
  @include clearfix();
  .#{$ns}Tabs-link {
    > a {
      word-break: break-all;
    }
  }
}

.#{$ns}ComboTabs-addLink {
  float: right;
  > a svg {
    margin-right: var(--gap-xs);
  }
}

.#{$ns}Combo-dragableTip {
  color: var(--text--muted-color);
  font-size: var(--fontSizeSm);
  margin-left: var(--gap-xs);

  &:empty {
    display: none;
  }
}

.#{$ns}ComboControl.is-inline {
  .#{$ns}Combo--hor .#{$ns}Combo-itemInner {
    flex-grow: unset;
    flex-basis: unset;
  }

  .#{$ns}Combo--ver .#{$ns}Combo-item {
    display: inline-block;
    min-width: var(--Form-control-widthBase);
  }
}

@include media-breakpoint-up(sm) {
  .#{$ns}Combo-form .#{$ns}Form-item:last-child {
    margin-bottom: 0;
  }
}
