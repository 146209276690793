.#{$ns}Field-popOverBtn {
  color: var(--PopOverAble-iconColor);
  margin-left: var(--gap-xs);
  display: inline-block;
  cursor: pointer;
  opacity: 0.6;
  vertical-align: middle;

  &:hover {
    color: var(--PopOverAble-onHover-iconColor);
    opacity: 1;
  }
}

.#{$ns}Field--popOverAble {
  outline: none;
  position: relative;
}

.#{$ns}PopOverAble-popover {
  min-width: px2rem(320px);
  max-width: px2rem(640px);

  .#{$ns}Panel {
    margin-bottom: 0;
    border: none;
  }
}

.#{$ns}PopOverAble--fixed {
  position: fixed;

  > * {
    margin-bottom: 0;
  }
}

.#{$ns}PopOverAble--fixed-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.#{$ns}PopOverAble--fixed-left-top {
  top: 0;
  left: 0;
}

.#{$ns}PopOverAble--fixed-right-top {
  top: 0;
  right: 0;
}

.#{$ns}PopOverAble--fixed-left-bottom {
  bottom: 0;
  left: 0;
}

.#{$ns}PopOverAble--fixed-right-bottom {
  bottom: 0;
  right: 0;
}
