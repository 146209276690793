.#{$ns}Crud2 {
  position: relative;

  &-selection,
  &-toolbar,
  &-body {
    margin-bottom: var(--gap-base);
  }

  &-selectionLabel {
    display: inline-block;
    vertical-align: top;
    margin-top: var(--gap-xs);
  }

  &-value {
    cursor: pointer;
    vertical-align: middle;
    user-select: none;
    line-height: calc(
      var(--Form-input-lineHeight) * var(--Form-input-fontSize) - #{px2rem(2px)}
    );
    display: inline-block;
    font-size: var(--Form-selectValue-fontSize);
    color: var(--Form-selectValue-color);
    background: var(--Form-selectValue-bg);
    border: px2rem(1px) solid var(--Form-selectValue-borderColor);
    border-radius: 2px;
    margin-right: var(--gap-xs);
    margin-top: var(--gap-xs);

    &:hover {
      background: var(--Form-selectValue-onHover-bg);
    }

    &.is-disabled {
      pointer-events: none;
      opacity: var(--Button-onDisabled-opacity);
    }
  }

  &-valueIcon {
    cursor: pointer;
    border-right: px2rem(1px) solid var(--Form-selectValue-borderColor);
    padding: 1px 5px;

    &:hover {
      background: var(--Form-selectValue-onHover-bg);
    }
  }

  &-valueLabel {
    padding: 0 var(--gap-xs);
  }

  &-selectionClear {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    margin-left: var(--gap-xs);
    margin-top: var(--gap-xs);
    vertical-align: middle;
  }

  &-toolbar-item {
    margin-top: var(--Crud-toolbar-gap);
    line-height: var(--Crud-toolbar-lineHeight);
    height: var(--Crud-toolbar-height);
    vertical-align: middle;
    display: inline-flex;
    align-items: center;

    &:not(:first-child) {
      margin-left: var(--Crud-toolbar-gap);
    }

    &--left {
      float: left;
    }

    &--right {
      float: right;
    }
  }

  &-actions {
    > * + .#{$ns}Button,
    > * + .#{$ns}ButtonGroup,
    > * + .#{$ns}ButtonToolbar {
      margin-left: var(--Crud-toolbar-gap);
    }
  }

  &-statistics {
    line-height: var(--Crud-toolbar-height);
    vertical-align: middle;
  }

  &-pageSwitch {
    .#{$ns}Select {
      margin-left: var(--Crud-toolbar-gap);
    }
  }

  &-pager {
    align-self: flex-start;
  }

  &-filter {
    margin-bottom: var(--gap-base);
  }
}

@include media-breakpoint-up(sm) {
  .#{$ns}Crud {
    &-toolbar {
      margin-top: calc(var(--Crud-toolbar-gap) * -1);
      flex-basis: 0;
      flex-grow: 1;
      @include clearfix();
    }

    &-toolbar-item {
      line-height: var(--Crud-toolbar-lineHeight);
      height: var(--Crud-toolbar-height);
      vertical-align: middle;

      &:not(:first-child) {
        margin-left: var(--Crud-toolbar-gap);
      }

      &--left {
        float: left;
      }

      &--right {
        float: right;
      }
    }

    &-actions {
      > * + .#{$ns}Button,
      > * + .#{$ns}Button--disabled-wrap {
        margin-left: var(--Crud-toolbar-gap);
      }
    }
  }
}
