// Excel 上传的配置，基于 https://react-dropzone.js.org/

.#{$ns}ExcelControl {
  &-container {
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    cursor: pointer;
  }

  &-container > p {
    font-size: 1rem;
  }

  &-container > em {
    font-size: 0.8rem;
  }

  &-dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--gap-md);
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  }

  &-dropzone:focus {
    border-color: var(--primary);
  }

  &-dropzone.disabled {
    opacity: 0.6;
  }
}
