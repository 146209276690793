.#{$ns}Panel {
  margin-bottom: var(--Panel-marginBottom);
  background: var(--Panel-bg);
  border: var(--Panel-border);
  border-radius: var(--Panel-borderRadius);
  -webkit-box-shadow: var(--Panel-boxShadow);
  box-shadow: var(--Panel-boxShadow);

  &-fixedBottom {
    position: absolute;
    background: var(--white);
    z-index: -1;
    opacity: 0;
    bottom: 999999px;
    box-shadow: var(--Panel-fixedBottom-boxShadow);
    border-top: var(--Panel-fixedBottom-borderTop);

    &.in {
      position: fixed;
      bottom: 0;
      z-index: $zindex-affix;
      opacity: 1;
    }
  }

  /* 主题 */
  &--default {
    border-color: var(--borderColor);

    > .#{$ns}Panel-heading {
      background: var(--Panel--default-bg);
      color: var(--Panel--default-color);

      .badge {
        color: var(--Panel--default-badgeColor);
        background: var(--Panel--default-badgeBg);
      }
    }

    > .#{$ns}Panel-heading {
      border-color: var(--Panel--default-headingBorderColor);
    }
  }

  &--primary {
    border-color: var(--primary);

    > .#{$ns}Panel-heading {
      background: var(--primary);
      color: var(--white);

      .badge {
        color: var(--primary);
        background: var(--white);
      }
    }

    > .#{$ns}Panel-heading,
    > .#{$ns}Panel-footer {
      border-color: var(--primary);
    }
  }

  &--success {
    border-color: var(--success);

    > .#{$ns}Panel-heading {
      background: var(--success);
      color: var(--white);

      .badge {
        color: var(--success);
        background: var(--white);
      }
    }

    > .#{$ns}Panel-heading,
    > .#{$ns}Panel-footer {
      border-color: var(--success);
    }
  }

  &--info {
    border-color: var(--info);

    > .#{$ns}Panel-heading {
      background: var(--info);
      color: var(--white);

      .badge {
        color: var(--info);
        background: var(--white);
      }
    }

    > .#{$ns}Panel-heading,
    > .#{$ns}Panel-footer {
      border-color: var(--info);
    }
  }

  &--warning {
    border-color: var(--warning);

    > .#{$ns}Panel-heading {
      background: var(--warning);
      color: var(--white);

      .badge {
        color: var(--warning);
        background: var(--white);
      }
    }

    > .#{$ns}Panel-heading,
    > .#{$ns}Panel-footer {
      border-color: var(--warning);
    }
  }

  &--danger {
    border-color: var(--danger);

    > .#{$ns}Panel-heading {
      background: var(--danger);
      color: var(--white);

      .badge {
        color: var(--danger);
        background: var(--white);
      }
    }

    > .#{$ns}Panel-heading,
    > .#{$ns}Panel-footer {
      border-color: var(--danger);
    }
  }

  /* 子组件 */
  &-heading {
    padding: var(--Panel-headingPadding);
    border-bottom: var(--Panel-headingBorderBottom);
    border-radius: var(--Panel-headingBorderRadius);
  }

  &-title {
    margin-top: var(--Panel-titleMarginTop);
    margin-bottom: var(--Panel-titleMarginBottom);
    font-size: var(--Panel-titleFontSize);
    color: var(--Panel-titleColor);
  }

  &-body {
    padding: var(--Panel-bodyPadding);
  }

  &-footer {
    border-color: var(--Panel-footerBorderColor);
    border-radius: var(--Panel-footerBorderRadius);
    background: var(--Panel-footerBg);
    padding: var(--Panel-footerPadding);
    border-style: solid;
    border-width: var(--Panel-borderWidth) 0 0 0;
    clear: both;

    .#{$ns}Button + .#{$ns}Button {
      margin-left: var(--Panel-footerButtonMarginLeft);
    }
  }

  &-btnToolbar {
    @include clearfix();
    text-align: var(--Panel-btnToolbarTextAlign);

    .#{$ns}Button {
      margin-left: var(--Panel-footerButtonMarginLeft);
    }

    &:empty {
      display: none;
    }
  }
}

/* 移动端样式调整 */
@include media-breakpoint-down(sm) {
  .#{$ns}Panel--form {
    border: none;
    box-shadow: none;

    margin: 0 calc(var(--Panel-bodyPadding) * -1)
      calc(var(--Panel-marginBottom) / 2);

    .#{$ns}Panel-body {
      padding: 0 var(--gap-md) var(--gap-md);
    }

    > .#{$ns}Panel-heading {
      background: none;
      border: none;
      border-radius: 0;

      .#{$ns}Panel-title {
        padding-left: var(--Panel-bodyPadding);
        border-left: px2rem(3px) solid var(--primary);
        font-size: var(--fontSizeLg);
      }
    }

    .#{$ns}Panel-footerWrap {
      padding-bottom: var(--Panel-bodyPadding);
    }

    .#{$ns}Panel-footer {
      border-top: none;
      display: flex;
      padding: 0 var(--Panel-bodyPadding);

      > .#{$ns}Button {
        flex: 1;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
