.#{$ns}DatePicker {
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
  border: var(--DatePicker-borderWidth) solid var(--DatePicker-borderColor);
  font-size: var(--DatePicker-fontSize);
  padding: var(--DatePicker-paddingY) var(--DatePicker-paddingX);
  height: var(--DatePicker-height);
  outline: none;
  white-space: nowrap;
  color: var(--DatePicker-color);
  background: var(--DatePicker-bg);
  border-radius: var(--DatePicker-borderRadius);
  min-width: var(--DatePicker-minWidth);

  &-input {
    display: inline-block;
    width: 100%;
    background: none;
    padding: 0;
    border: 0;
    &:focus {
      border: none;
      outline: none;
      box-sizing: none;
    }
  }

  .#{$ns}DatePicker-clear {
    display: none;
  }

  @include input-border();

  &:not(.is-disabled) {
    cursor: pointer;

    &:hover {
      background: var(--DatePicker-onHover-bg);
      border-color: var(--DatePicker-onHover-borderColor);

      .#{$ns}DatePicker-clear {
        display: flex;
      }

      .#{$ns}DatePicker-clear + .#{$ns}DatePicker-toggler {
        display: none;
      }
    }
  }

  &.is-focused {
    border-color: var(--DatePicker-onFocused-borderColor);
    box-shadow: var(--Form-input-boxShadow);
  }

  &.is-disabled {
    background: $gray200;

    > .#{$ns}DatePicker-input {
      color: var(--text--muted-color);
    }
  }

  &-placeholder {
    color: var(--DatePicker-placeholderColor);
    user-select: none;
    margin-right: var(--gap-base);
    min-width: px2rem(50px);
    flex-basis: 0;
    flex-grow: 1;
  }

  &-value {
    margin-right: var(--gap-base);
    flex-basis: 0;
    flex-grow: 1;
  }

  &-toggler {
    @include input-clear();
    line-height: 1;
  }

  &-clear {
    @include input-clear();
    line-height: 1;
  }
}

.#{$ns}DateControl:not(.is-inline) > .#{$ns}DatePicker {
  display: flex;
}

// .#{$ns}DateControl {
//   width: auto !important; // 因为这里要撑开父容器
//   &.is-date > .#{$ns}DatePicker {
//     min-width: calc(var(--DatePicker-minWidth) + var(--fontSizeLg) * 2);
//   }

//   &.is-datetime > .#{$ns}DatePicker {
//     min-width: calc(var(--DatePicker-minWidth) + var(--fontSizeLg) * 6);
//   }
// }

.#{$ns}DatePicker-shortcuts {
  margin: 0;
  padding-left: px2rem(10px);
  list-style: none;
  background: #f7f7f9;
  line-height: px2rem(32px);
}

.#{$ns}DatePicker-shortcut {
  display: inline-block;
  margin-right: px2rem(32px);
  font-size: var(--fontSizeSm);

  a {
    font-size: var(--Calendar-fontSize);
    cursor: pointer;
    color: var(--Calendar-shortcut-color);
    text-decoration: var(--Calendar-shortcut-decoration);

    &:hover {
      color: var(--Calendar-shortcut-onHover-color);
      text-decoration: var(--Calendar-shortcut-onHover-decoration);
    }
  }
}

.#{$ns}DatePicker-popover {
  margin: px2rem(2px) 0 0;

  &.#{$ns}PopOver--leftTopLeftBottom,
  &.#{$ns}PopOver--rightTopRightBottom {
    margin: px2rem(-2px) 0 0;
  }
}

.#{$ns}DatePicker-popup {
  height: px2rem(300px);
}

// 移动端输入框样式
.#{$ns}DatePicker.is-mobile {
  border: 0;
  justify-content: flex-end;

  span,
  a {
    &:focus {
      outline: unset;
    }
  }

  .#{$ns}DatePicker-value,
  .#{$ns}DatePicker-clear {
    display: inline-flex;
    justify-content: flex-end;
    padding: 0 0;
  }

  .#{$ns}DatePicker-value {
    margin-right: var(--gap-xs);
  }

  .#{$ns}DatePicker-placeholder {
    flex-grow: unset;
    flex-basis: unset;
  }

  .#{$ns}DatePicker-toggler {
    margin-top: -3px;
  }
}

.#{$ns}DatePicker-popup.#{$ns}DatePicker-mobile {
  color: red;
  .rdt {
    width: 100%;
    .rdtPicker {
      width: 100%;
      padding: unset;
    }
  }
}

.rdt {
  user-select: none;
  font-size: var(--Calendar-fontSize);
  color: var(--Calendar-color);

  .rdtDays {
    display: flex;
    // height: #{px2rem(280px)};

    .rdtDaysPart {
      width: #{px2rem(264px)};
      border-right: 1px solid var(--Calendar-input-borderColor);
    }

    .rdtTimePart {
      width: #{px2rem(128px)};
      text-align: center;
    }

    .rdtTimePartWithS {
      width: #{px2rem(192px)};
      text-align: center;
    }

    .rdtShowTime {
      height: #{px2rem(43px)};
      padding-top: #{px2rem(11px)};
      margin-left: #{px2rem(2px)};
      border-bottom: 1px solid var(--Calendar-input-borderColor);
    }
  }

  .rdtPickerTime {
    width: 100%;
    height: 100%;
  }

  .rdtPickerDate {
    height: 100%;
  }

  .rdtPickerTimeWithoutD {
    width: 100%;
    // height: #{px2rem(240px)};
    .TimeContentWrapper {
      display: flex;
    }
  }

  .rdtPicker {
    margin-top: 0;
    // padding: var(--gap-sm);
    padding: 0;
    background: transparent;
    border: none;

    .dow {
      // color: var(--Calendar-wLabel-color);
      font-weight: normal;
    }

    td.rdtDay,
    td.rdtHour,
    td.rdtMinute,
    td.rdtSecond,
    .rdtTimeToggle {
      background: var(--Calendar-cell-bg);

      &:hover {
        background: var(--Calendar-cell-onHover-bg);
      }
    }

    td.rdtBetween {
      background: var(--Calendar-cell-onBetween-bg);
    }

    td.rdtToday:before {
      border-bottom-color: var(--Calendar-cell-onActive-bg);
    }

    td.rdtActive.rdtToday:before {
      border-bottom-color: var(--Calendar-cell-bg);
    }

    .rdtDisabled.rdtToday,
    .rdtOld.rdtToday,
    .rdtNew.rdtToday {
      > span {
        border-color: #e8e9eb;
      }
    }

    td.rdtActive,
    td.rdtActive:hover {
      > span {
        background: var(--Calendar-cell-onActive-bg);
      }
    }

    .rdtActive.rdtToday {
      color: #151b26;
      > span {
        background: transparent;
        border-color: #144bcc;
      }
    }

    td.rdtDisabled,
    td.rdtDisabled:hover {
      > span {
        background: var(--Calendar-cell-onDisabled-bg);
      }
    }
  }

  thead tr:first-child th {
    cursor: default;
    font-weight: normal;
    border-bottom: none;
  }

  thead tr:first-child th:hover {
    background: transparent;
  }

  tfoot {
    border-top: 0;

    td {
      padding-top: var(--gap-xs);
      text-align: left;

      span {
        width: 10px;
        display: inline-block;
        text-align: center;
      }

      .rdtActions {
        text-align: right;
        padding: var(--gap-sm);
        border-top: 1px solid var(--Calendar-input-borderColor);
      }
    }
  }
}

.#{$ns}CalendarInputWrapper {
  display: inline-block;
  position: relative;
  // padding-top: #{px2rem(4px)};
  width: #{px2rem(64px)};
  height: #{px2rem(224px)};
  overflow: auto;
  .#{$ns}CalendarInput-sugs {
    list-style: none;
    background: var(--Form-select-menu-bg);
    color: var(--Form-select-menu-color);
    left: px2rem(-1px);
    right: px2rem(-1px);
    top: 100%;
    z-index: 10;
    &Hours {
      height: #{px2rem(868px)};
    }
    &Times {
      height: #{px2rem(1876px)};
      border-left: 1px solid var(--Calendar-input-borderColor);
    }
    &Item {
      text-align: center;
      height: 28px;
      line-height: 28px;
      &.is-highlight,
      &:hover {
        color: var(--Form-select-menu-onHover-color);
        background: var(--Form-select-menu-onHover-bg);
      }
    }
  }
}

.#{$ns}TimeRangeHeaderWrapper {
  height: 40px;
  padding-top: 10px;
  text-align: center;
  border-bottom: 1px solid var(--Calendar-input-borderColor);
}

.#{$ns}TimeFooterWrapper {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;

  .#{$ns}Button {
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 2px;
  }
}

.#{$ns}CalendarInput {
  outline: none;
  width: #{px2rem(56px)};
  font-size: var(--Calendar-input-fontSize);
  color: var(--Calendar-input-color);
  border: 1px solid var(--Calendar-input-borderColor);
  border-radius: var(--Calendar-input-borderRadius);
  height: var(--Calendar-input-height);
  line-height: var(--Calendar-input-lineHeight);
  padding: var(--Calendar-input-paddingY) var(--Calendar-input-paddingX);
  box-shadow: none;

  &:focus {
    border-color: var(--Calendar-input-onFocused-borderColor);
    box-shadow: none;
  }
}

.#{$ns}CalendarTime {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.#{$ns}CalendarCounter {
  &-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    text-align: center;
    color: var(--Button--primary-bg);

    &:hover {
      color: var(--Button--primary-onActive-bg);
    }

    > svg {
      width: 16px;
      height: 16px;
    }

    &--up > svg {
      transform: rotate(-90deg);
    }

    &--down > svg {
      transform: rotate(90deg);
    }
  }

  &-sep {
    width: var(--gap-md);
    text-align: center;
  }

  &--daypart {
    margin-left: 10px;
  }

  &--daypart &-value {
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
}

.rdtBtn {
  line-height: var(--Calendar-btn-lineHeight);
  padding: var(--Calendar-btn-paddingY) var(--Calendar-btn-paddingX);
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
  font-size: var(--Calendar-btn-fontSize);
  border: 1px solid var(--Calendar-btn-border);

  &:hover {
    text-decoration: none;
  }

  &.is-disabled {
    border-color: var(--Button-onDisabled-borderColor);
    opacity: 0.6;
    pointer-events: none;
  }

  .fa,
  .iconfont {
    font-size: var(--fontSizeSm);
  }

  @include button-variant(
    var(--Calendar-btn-bg),
    var(--Calendar-btn-border),
    var(--Calendar-btn-color),
    var(--Calendar-btn-onHover-bg),
    var(--Calendar-btn-onHover-border),
    var(--Calendar-btn-onHover-color),
    var(--Calendar-btn-onActive-bg),
    var(--Calendar-btn-onActive-border),
    var(--Calendar-btn-onActive-color)
  );

  border-radius: var(--Calendar-btn-borderRadius);

  & + .rdtBtn {
    margin-left: var(--gap-xs);
  }

  &Cancel {
    @include button-variant(
      var(--Calendar-btnCancel-bg),
      var(--Calendar-btnCancel-border),
      var(--Calendar-btnCancel-color),
      var(--Calendar-btnCancel-onHover-bg),
      var(--Calendar-btnCancel-onHover-border),
      var(--Calendar-btnCancel-onHover-color),
      var(--Calendar-btnCancel-onActive-bg),
      var(--Calendar-btnCancel-onActive-border),
      var(--Calendar-btnCancel-onActive-color)
    );
  }
}

.rdtBtnPrev:before,
.rdtBtnNext:before {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 1;
}

.rdtPrev,
.rdtNext {
  cursor: pointer !important;
  width: var(--gap-md);
  padding: 0;
  color: var(--DatePicker-arrow-color);
  font-size: var(--fontSizeXl);
  text-decoration: none;
  font-family: auto;
  font-weight: normal;

  &:hover {
    text-decoration: none;
    color: var(--DatePicker-color);
  }
}

.rdtCenter {
  color: var(--DatePicker-color);
}

.rdtSwitch {
  text-align: center;
  cursor: pointer;
  font-weight: normal;
  color: var(--DatePicker-color);

  &:hover {
    color: var(--DatePicker-header-onHover-color);
    text-decoration: none;
  }
}

.rdtHeader {
  display: table;
  table-layout: fixed;
  border-collapse: separate;
  border-bottom: 1px solid var(--Calendar-input-borderColor);
  height: 40px;
  width: 100%;
  margin-top: -8px;

  > * {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .rdtSwitch + .rdtSwitch {
    margin-left: var(--gap-xs);
  }
}

td.rdtMonth,
td.rdtYear,
td.rdtQuarter {
  > span {
    display: inline-block;
  }

  &.rdtBetween {
    background: var(--Calendar-cell-onBetween-bg);
  }

  &:hover,
  &.rdtActive,
  &.rdtActive:hover {
    background: transparent;

    > span {
      background: var(--info) !important;
      color: var(--white);
    }
  }

  &.rdtDisabled,
  &.rdtDisabled:hover {
    background: var(--Calendar-cell-onDisabled-bg);
    color: #b8babf;
  }
}

.#{$ns}DateRangePicker-start {
  .rdtPicker .rdtActive.rdtBetween {
    background: linear-gradient(
      to right,
      transparent 0%,
      transparent 50%,
      var(--Calendar-cell-onBetween-bg) 51%,
      var(--Calendar-cell-onBetween-bg) 100%
    );
  }
}

.#{$ns}DateRangePicker-end {
  .rdtPicker .rdtActive.rdtBetween {
    background: linear-gradient(
      to right,
      var(--Calendar-cell-onBetween-bg) 0%,
      var(--Calendar-cell-onBetween-bg) 50%,
      transparent 51%,
      transparent 100%
    );
  }
}

.#{$ns}DateCalendar {
  display: inline-block;
  border: var(--DatePicker-borderWidth) solid var(--DatePicker-borderColor);
  background: var(--DatePicker-bg);
  border-radius: var(--DatePicker-borderRadius);
}
