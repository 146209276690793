.#{$ns}ResultBox {
  @include input-input();
  @include input-border();
  flex-wrap: nowrap;
  padding: 0 px2rem(5px);
  min-height: var(--Form-input-height);
  align-items: center;
  border-radius: 3px;
  position: relative;

  &.is-error {
    border-color: var(--Form-input-onError-borderColor);
    background: var(--Form-input-onError-bg);
  }

  &.is-focused,
  &.is-active,
  &:focus {
    outline: none;
    border-color: var(--Form-input-onFocused-borderColor);
    box-shadow: var(--Form-input-boxShadow);
    background: var(--Form-input-onFocused-bg);
  }

  &.is-clickable:not(.is-disabled) {
    cursor: pointer;

    &:hover {
      border-color: var(--Form-input-onFocused-borderColor);
      box-shadow: var(--Form-input-boxShadow);
      background: var(--Form-input-onFocused-bg);
    }
  }

  &.is-error.is-focused {
    border-color: var(--Form-input-onError-borderColor);
  }

  &.is-disabled {
    color: var(--text--muted-color);
    background: var(--Form-input-onDisabled-bg);
    border-color: var(--Form-input-onDisabled-borderColor);
    pointer-events: none;
    background: var(--TabsTransfer-title-bg);

    div.#{$ns}ResultBox-value {
      background: var(--TabsTransfer-border-color);

      .#{$ns}ResultBox-valueLabel {
        color: var(--Pagination-light-color);
      }
    }
  }

  &.#{$ns}NestedSelect {
    padding-left: var(--gap-base);
  }

  &-value-wrap {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &-actions {
    display: flex;
    align-items: center;
    flex-shrink: 0;

    > * {
      &:first-child {
        margin-left: px2rem(5px);
      }

      &:not(:last-child) {
        margin-right: px2rem(5px);
      }
    }
  }

  &-singleValue {
    padding: 0 calc(var(--Form-input-paddingX) - #{px2rem(3px)});
    flex-grow: 1;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-pc-arrow {
    height: 100%;
    margin: auto 0;
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    transition: transform var(--animation-duration) ease;
    transform: rotate(90deg);
    > svg {
      width: px2rem(10px);
      height: px2rem(10px);
      top: 0.125em;
      color: var(--default-icon-color);
    }
  }

  &.is-opened &-pc-arrow {
    transform: rotate(270deg);
  }

  &-clear {
    @include input-clear();
    height: 100%;
    margin: auto 0;
    background-color: unset;

    &-wrap {
      width: px2rem(16px);
      height: px2rem(16px);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-with-arrow {
      right: 33px;
    }
  }

  > svg {
    display: inline-block;
    width: px2rem(14px);
    color: var(--icon-color);
  }

  > a {
    cursor: pointer;
  }

  &-value {
    background: var(--ResultBox-value-bg);
    color: var(--ResultBox-value-color);
    font-size: var(--Form-input-fontSize);
    padding: 0 var(--gap-xs);
    min-height: var(--ResultBox-tag-height);
    flex-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    margin-right: var(--gap-xs);
    margin-bottom: var(--ResultBox-tag-marginBottom);
    user-select: none;
    border-radius: #{px2rem(2px)};

    > a {
      cursor: pointer;
      margin-left: px2rem(10px);
      color: var(--ResultBox-icon-color);
      &:hover {
        color: var(--ResultBox-icon--onHover-color);
      }

      > svg {
        width: px2rem(10px);
        height: px2rem(10px);
        &.icon {
          top: 0;
        }
      }
    }

    &:hover {
      background: var(--ResultBox-value--onHover-bg);
    }

    &.is-disabled {
      pointer-events: none;
      color: var(--ResultBox-value--onDisabled-color);

      > a {
        color: var(--ResultBox-icon--onDisabled-color);
      }
    }
  }

  &-placeholder {
    color: var(--Form-input-placeholderColor);
    flex-grow: 1;
    user-select: none;
  }

  > input {
    padding-left: 8px;
    min-height: 24px;
  }

  &.#{$ns}TransferDropDown {
    padding: 0 var(--gap-base);

    &.is-group {
      padding: var(--gap-xs);

      > .#{$ns}TransferDropDown-icon {
        margin-right: var(--gap-sm);
      }

      > .#{$ns}ResultBox-placeholder {
        padding-left: var(--gap-sm);
      }
    }

    > .#{$ns}ResultBox-value {
      background: var(--Form-select-value-bgColor);

      &:hover {
        background: var(--Form-selectValue-onHover-bgColor);
      }
    }
  }

  &-overflow {
    &-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(
        (var(--ResultBox-tag-height) + var(--ResultBox-tag-marginBottom)) * 5
      );
    }

    &.#{$ns}Tooltip--dark {
      .#{$ns}ResultBox-overflow-wrapper {
        .#{$ns}ResultBox-value {
          color: var(--white);
          background-color: var(--Form-select-value-bgColor--dark);

          &:hover {
            background-color: var(--ResultBox-value--onHover-bg--dark);
          }

          & > a {
            color: var(--Form-select-valueIcon-color--dark);
          }
        }
      }
    }
  }

  &.is-mobile {
    min-height: calc(var(--Form-input-lineHeight) * var(--fontSizeLg));
    border: none;
    padding: 0;
    font-size: var(--fontSizeLg);
    border: none;
    justify-content: flex-end;

    .#{$ns}ResultBox-clear {
      @include input-clear();
      width: px2rem(26px);
      height: px2rem(26px);
      margin: 0 px2rem(-2px);
      margin-left: auto;
      padding: px2rem(4px);
      position: unset;
      right: 0;
      display: flex;
      align-items: center;
      background-color: unset;
    }

    .#{$ns}ResultBox-arrow {
      margin-right: var(--gap-xs);
      // margin-left: var(--gap-xs);
      width: var(--gap-md);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      margin-left: 4px;

      > svg {
        transition: transform var(--animation-duration);
        display: inline-block;
        color: var(--Form-select-caret-iconColor);
        width: 10px;
        height: 10px;
        top: 0;
        transform: rotate(-90deg);
      }
    }
  }
}
